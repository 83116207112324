
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const stylesheet = (theme, media, heights) => {
    return StyleSheet.create({
        container: {
            flexGrow: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: theme.spacing.large / 2,
            paddingBottom: getCreateButtonOverflow(theme, media) + theme.spacing.large / 2,
        },
        itemContainer: {
            padding: theme.spacing.large / 2,
            flexBasis: heights.socialNavButton + theme.spacing.large,
            width: "100%",
        },
        settingsContainer: {
            padding: theme.spacing.large / 2,
            flexBasis: heights.defButton + theme.spacing.large,
            width: 200,
            maxWidth: "100%",
        },
    });
}

//Exports.
export default stylesheet;
