
const translations = {
    denmark: "Denmark",
    norway: "Norway",
    sweden: "Sweden",
    finland: "Finland",
    uk: "United Kingdom",
    us: "United States of America",
    germany: "Germany",
    poland: "Poland",
    france: "France",
};

export default translations;
