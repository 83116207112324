
//Framework.
import React from "react";
import { View, Text, TextInput } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/search";

/*
    value: (string),
    onChange: (method),
*/

const Component = ({ value:valueIn, onChange:onChangeIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Methods.
    const onChange = React.useCallback((e) => onChangeIn(e.nativeEvent.text), [ onChangeIn ]);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <View style={ styles.container }>

            {/* Icon. */}
            <View style={ styles.iconContainer }>
                <Icon
                    source="FontAwesome5"
                    name="search"
                    size={ theme.fonts.text.lineHeights.small - (theme.spacing.small - theme.spacing.tiny) }
                    colour={ theme.colour.text }
                />
            </View>

            {/* Input */}
            <View style={ styles.inputContainer }>
                <TextInput
                    style={ styles.input }
                    value={ valueIn }
                    onChange={ onChange }
                    placeholder={ localisation.t("components.inputs.text.search.placeholder") }
                    inputMode="search"
                    returnKeyType="done"
                />
            </View>


        </View>
    );
}

const getHeight = (theme) => {
    return theme.fonts.text.lineHeights.small + 2 * theme.spacing.tiny + 2 * theme.border.width.medium;
};

//Exports.
export default Component;
export { getHeight };
