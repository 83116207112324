
import { StyleSheet, Platform } from "react-native";

const stylesheet = function(theme) {
    return StyleSheet.create({
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
        },
    });
}

//Exports.
export default stylesheet;
