
import serverError from "./serverError";
import connectionError from "./connectionError";

const translations = {
    serverError: serverError,
    connectionError: connectionError,
};

export default translations;
