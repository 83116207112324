
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, backgroundColour, textColour) => {
    return StyleSheet.create({
        container: {
            backgroundColor: backgroundColour,
        },
        text: {
            fontSize: theme.fonts.title.sizes.small,
            fontFamily: theme.fonts.title.families.bold,
            color: textColour,
            padding: theme.spacing.medium,
            textAlign: "center",
            textTransform: "uppercase",
        },
    });
}

//Exports.
export default stylesheet;
