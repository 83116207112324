
import { StyleSheet } from "react-native";

const stylesheet = () => {
    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
    });
}

//Exports.
export default stylesheet;
