
import { StyleSheet } from "react-native";

const stylesheet = (theme, media) => {

    const padding = media.break === media.breaks.mobile ? 20 : media.break === media.breaks.tablet ? 50 : media.break === media.breaks.desktop ? 100 : 0;

    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
            alignItems: "center",
            paddingLeft: padding,
            paddingRight: padding,
        },
        subContainer: {
            width: "100%",
            height: "100%",
            maxWidth: media.breaks.tablet,
        },
    });
}

//Exports.
export default stylesheet;
