
const translations = {
    phoneNumberPrefix: {
        label: "Phone number prefix",
        validation: "The phone number prefix",
    },
    phoneNumber: {
        label: "Phone number",
        validation: "The phone number",
    },
    username: {
        label: "Username",
        validation: "The username",
    },
    email: {
        label: "E-mail",
        validation: "The e-mail",
    },
    password: {
        label: "Password",
        validation: "The password",
        validationTarget: "the password",
    },
    passwordConfirmation: {
        label: "Password confirmation",
        validation: "The password confirmation",
    },
    firstName: {
        label: "First name",
        validation: "The first name",
    },
    lastName: {
        label: "Surname",
        validation: "The surname",
    },
    birthdate: {
        label: "Birthdate",
        validation: "The birthdate",
    },

    activationCode: {
        label: "Activation code",
        validation: "The activation code",
    },
};

export default translations;
