
//Framework.
import React, { useImperativeHandle, forwardRef } from "react";
import { Platform, Text, View, Pressable } from "react-native";
import * as Location from "expo-location";

//Constants.
import EVENT_TYPES from "@constants/eventTypes";

//Openlayers.
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlTileLayer from "ol/layer/Tile";
import OlVectorLayer from "ol/layer/Vector";
import OlVectorSource from "ol/source/Vector";
import OlXYZ from "ol/source/XYZ";
import OlOverlay from "ol/Overlay";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";
import { transform, transformExtent } from "ol/proj";
import {
    Circle as olCircle,
    Fill as olFill,
    Stroke as olStroke,
    Style as olStyle,
    Icon as olIcon,
    Text as olText,
} from "ol/style";

//Icons.
import AntDesignIcon from "react-native-vector-icons/AntDesign";
import EntypoIcon from "react-native-vector-icons/Entypo";
import EvilIconsIcon from "react-native-vector-icons/EvilIcons";
import FeatherIcon from "react-native-vector-icons/Feather";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
import FontistoIcon from "react-native-vector-icons/Fontisto";
import FoundationIcon from "react-native-vector-icons/Foundation";
import IoniconsIcon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIconsIcon from "react-native-vector-icons/MaterialCommunityIcons";
import MaterialIconsIcon from "react-native-vector-icons/MaterialIcons";
import OcticonsIcon from "react-native-vector-icons/Octicons";
import SimpleLineIconsIcon from "react-native-vector-icons/SimpleLineIcons";

//Contexts.
import ThemeContext from "@contexts/theme";

//Stylesheet.
import stylesheet from "./stylesheets/web";

var mapNum = 0;

const ZOOM_Z_TIME = 250;
const ZOOM_XY_TIME = 1000;

const ROAD_MAP_LAYER_NUM = 0;
const SATELLITE_MAP_LAYER_NUM = 1;
const EVENTS_LAYER_NUM = 2;

var markerStyleCache = {};

const MapView = ({ homeCoords:homeCoordsIn, markers:markersIn, interactable:interactableIn, onPress:onPressIn, onMove:onMoveIn, }, ref) => {
    const theme = React.useContext(ThemeContext);

    //Bind ref.
    useImperativeHandle(ref, () => ({
        zoomIn: () => {
            let newZoom = mapRef.current.getView().getZoom() + 2;
            if (newZoom > 20) newZoom = 20;
            mapRef.current.getView().animate({
                zoom: newZoom,
                duration: 500,
            });
        },
        zoomOut: () => {
            let newZoom = mapRef.current.getView().getZoom() - 2;
            if (newZoom < 0) newZoom = 0;
            mapRef.current.getView().animate({
                zoom: newZoom,
                duration: 500,
            });
        },
        zoomHome: () => {
            mapRef.current.getView().animate({
                center: serverToOlCoords(homeCoordsIn.latitude, homeCoordsIn.longitude),
                duration: 500,
            });
        },
    }));
    
    //Draw map on render.
    const mapRef = React.useRef(null);
    const mapElIdRef = React.useRef(`map-${ mapNum++ }`);
    React.useEffect(() => {
        //Create map.
        let map = new OlMap({
            target: mapElIdRef.current,
            controls: [],
            layers: [
                new OlTileLayer({ //Road map layer.
                    source: new OlXYZ({
                        url: `http://mt${ Math.round(Math.random() * 4) }.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&apistyle=${encodeURIComponent("s.t:2|s.e:l|p.v:off")}`,
                    }),
                    visible: true,
                }),
                new OlTileLayer({ //Satellite map layer.
                    source: new OlXYZ({
                        url: `http://mt${ Math.round(Math.random() * 4) }.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&apistyle=${encodeURIComponent("s.t:2|s.e:l|p.v:off")}`,
                    }),
                    visible: false,
                }),
                new OlVectorLayer({ //Markers layer.
                    source: new OlVectorSource({
                        features: [],
                    }),
                    style: (feature, resolution) => {
                        //If cached, get that instead.
                        if (markerStyleCache.hasOwnProperty(feature.get("data").descId)) {
                            return markerStyleCache[feature.get("data").descId];
                        }
                        //Create style.
                        let iconPackage;
                        const eventType = EVENT_TYPES.find(type => type.descId === feature.get("data").descId);
                        switch(eventType.icon.source) {
                            case("AntDesign"):
                                iconPackage = AntDesignIcon;
                                break;
                            case("Entypo"):
                                iconPackage = EntypoIcon;
                                break;
                            case("EvilIcons"):
                                iconPackage = EvilIconsIcon;
                                break;
                            case("Feather"):
                                iconPackage = FeatherIcon;
                                break;
                            case("FontAwesome"):
                                iconPackage = FontAwesomeIcon;
                                break;
                            case("FontAwesome5"):
                                iconPackage = FontAwesome5Icon;
                                break;
                            case("Fontisto"):
                                iconPackage = FontistoIcon;
                                break;
                            case("Foundation"):
                                iconPackage = FoundationIcon;
                                break;
                            case("Ionicons"):
                                iconPackage = IoniconsIcon;
                                break;
                            case("MaterialCommunityIcons"):
                                iconPackage = MaterialCommunityIconsIcon;
                                break;
                            case("MaterialIcons"):
                                iconPackage = MaterialIconsIcon;
                                break;
                            case("Octicons"):
                                iconPackage = OcticonsIcon;
                                break;
                            case("SimpleLineIcons"):
                                iconPackage = SimpleLineIconsIcon;
                                break;
                        }
                        const fontFamily = Object.getOwnPropertyNames(iconPackage.font).map(font => `'${ font }'`).join(", ");
                        const text = String.fromCharCode(iconPackage.getRawGlyphMap()[eventType.icon.name]);
                        //console.log({ text, fontFamily, icon:eventType.icon, });
                        const base = new olStyle({
                            image: new olIcon({
                                src: "data:image/svg+xml;utf8," + escape(getBaseMarker(theme.colour.primary, theme.colour.primaryText)),
                                anchor: [0.5, 1],
                                scale: 1,
                                opacity: 1,
                            }),
                            /*
                            text: new olText({
                                text: eventType.icon.name,
                                textAlign: "center",
                                offsetY: -32,
                                fill: new olFill({
                                    color: theme.colour.primaryText,
                                }),
                            }),
                            */
                            text: new olText({
                                text: text,
                                font: `35px ${ fontFamily }`,//`30px ${ iconPackage.getFontFamily() }`,
                                textAlign: "center",
                                offsetY: -32,
                                fill: new olFill({
                                    color: theme.colour.primaryText,
                                }),
                            }),
                            /*
                            text: new olText({
                                text: String.fromCharCode(56441),
                                font: `35px 'material-community'`,//`30px ${ iconPackage.getFontFamily() }`,
                                textAlign: "center",
                                offsetY: -32,
                                fill: new olFill({
                                    color: theme.colour.primaryText,
                                }),
                            }),
                            */
                            /*
                            text: new olText({
                                text: String.fromCharCode(iconPackage.getRawGlyphMap()[eventType.icon.name]),
                                font: "bold 35px 'FontAwesome5Free-Solid', 'FontAwesome5Free-Regular'",//`30px ${ iconPackage.getFontFamily() }`,
                                textAlign: "center",
                                offsetY: -32,
                                fill: new olFill({
                                    color: theme.colour.primaryText,
                                }),
                            }),
                            */
                        });
                        /*
                        const icon = new olStyle({
                            image: new olIcon({
                                src: "data:image/svg+xml;utf8," + escape(FontAwesome5Icon.getImageSourceSync("user", 20, theme.colour.primaryColour)),
                                anchor: [0.5, 1],
                                scale: 1,
                                opacity: 1,
                            }),
                        });
                        */
                        //Cache.
                        markerStyleCache[feature.get("data").descId] = [ base ];
                        //Return.
                        return markerStyleCache[feature.get("data").descId];
                    },
                }),
            ],
            view: new OlView({
                center: serverToOlCoords(homeCoordsIn.latitude, homeCoordsIn.longitude),
                zoom: homeCoordsIn.zoom,
            })
        });

        //If interactions have been disabled, remove them from map.
        if (!interactableIn) {
            map.getInteractions().forEach((interaction) => {
                map.removeInteraction(interaction);
            });
        }

        //Set event listeners on map.
        if (onPressIn !== undefined) {
            map.on("click", (e) => {
                const olCoords = map.getCoordinateFromPixel(e.pixel);
                const coords = olToServerCoords(olCoords[0], olCoords[1]);
                const features = map.getFeaturesAtPixel(e.pixel);
                const event = features.length === 0 ? null : features[features.length - 1].get("data");
                onPressIn(coords[0], coords[1], event);
            });
        }
        if (onMoveIn !== undefined) {
            let isDragging = false;
            const onRegionChange = () => {
                const extent = map.getView().calculateExtent();
                const [ lonMin, latMin, lonMax, latMax ] = olToServerExtent(extent);
                onMoveIn(lonMin, latMin, lonMax, latMax);
            };
            map.on("pointerdrag", () => isDragging ? onRegionChange() : null);
            map.on("movestart", () => isDragging = true);
            map.on("moveend", () => {
                isDragging = false;
                onRegionChange();
            });
            map.on("zoomend", () => onRegionChange());
        }

        //Store map.
        mapRef.current = map;
        
        //Destroy map.
        return () => {
            const layers = map.getLayers().getArray();
            layers.forEach((layer, index) => {
                layer.getSource().clear();
                layer.dispose();
                layer.setSource(undefined);
                map.removeLayer(layer);
            });
            map.setTarget(null);
            map = null;
        };
    }, []);

    //Update markers.
    React.useEffect(() => {
        //console.log({ where:"Changing markers", markers:props.markers });
        mapRef.current.getLayers().getArray()[EVENTS_LAYER_NUM].getSource().clear();
        mapRef.current.getLayers().getArray()[EVENTS_LAYER_NUM].getSource().addFeatures(markersIn.filter(marker => marker.type === "event").map(marker => new OlFeature({
            geometry: new OlPoint(serverToOlCoords(marker.latitude, marker.longitude)),
            data: {
                id: marker.id,
                descId: marker.descId,
            },
        })));
    }, [ markersIn ]);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <View
            style={ styles.container }
            nativeID={ mapElIdRef.current }
        />
    );
}

const serverToOlCoords = (lat, lon) => transform([lon, lat], "EPSG:4326", "EPSG:3857");
const olToServerCoords = (lon, lat) => transform([lon, lat], "EPSG:3857", "EPSG:4326").reverse();
const olToServerExtent = (extent) => transformExtent(extent, "EPSG:3857", "EPSG:4326");

const getBaseMarker = (colourPrimary, colourSecondary) => 
    `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="57" viewBox="0 0 13.229166 15.08125">
        <path
            d="m 2.1267353,2.1267353 c 2.4862678,-2.48195 6.4894309,-2.48195 8.9756967,0 2.486264,2.48194 2.486267,6.47816 -1e-6,8.9601 -1.2431338,1.24098 -3.4915808,3.73343 -4.4878431,3.73343 -0.9962623,0 -3.2447201,-2.49245 -4.4878528,-3.73343 -2.48626549,-2.48194 -2.48626774,-6.47815 2e-7,-8.9601 z"
            style="fill:${colourPrimary};stroke:${colourSecondary};stroke-width:0.52844119;"
        />
    </svg>`;

//Exports.
export default forwardRef(MapView);
