
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        prefixText: {
            color: theme.colour.unauthenticatedCardText,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            padding: theme.spacing.small,
            paddingLeft: theme.spacing.medium,
            paddingRight: theme.spacing.medium,
        },
        prefixButton: {
        },
    });
}

//Exports.
export default stylesheet;
