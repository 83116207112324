
import { StyleSheet } from "react-native";

const stylesheet = (theme, counterBubbleSize) => {
    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.foreground,
            padding: theme.spacing.large / 2,
            margin: theme.spacing.large / 2,
            borderWidth: theme.border.width.medium,
            borderColor: theme.colour.primary,
            borderRadius: theme.border.radius.medium,
            flexDirection: "row",
        },

        //Labels.
        textContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        title: {
            padding: theme.spacing.small / 2,
            color: theme.colour.text,
            textAlign: "left",
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
        },
        text: {
            padding: theme.spacing.small / 2,
            color: theme.colour.text,
            textAlign: "left",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },

        //Display.
        displayContainer: {
            height: "100%",
        },

        //Counter.
        counterContainer: {
            position: "absolute",
            top: -counterBubbleSize / 2 + theme.spacing.small,
            right: -counterBubbleSize / 2 + theme.spacing.small,
        },
    });
}

//Exports.
export default stylesheet;
