
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Image } from "react-native";

//Helpers.
import {
    getTimezoneOffset,
    unixToDatetime,
    unixToDate,
    unixToTime,
} from "@helpers/time/";

//Context.
import ThemeContext from "@contexts/theme";
import ModalContext from "@contexts/modal";

//Components.
import Template from "@components/inputs/templates/unauthenticated";
import Modal from "./modal";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    value: {
        date: (string),
        time: (string),
    },
    label: (string),
    (Optional) disabled: (bool: false),
    (Optional) error: (string),

    value: (integer, unix),
    min: (integer, unix),
    max: (integer, unix),
    useDate: (bool),
    useTime: (bool),
    onChange: (method),
    onOpen={ (open) => open() }
    onClose={ (close) => close() }
*/

const Component = ({
    value: valueIn,
    label: labelIn,
    disabled: disabledIn = false,
    onChange: onChangeIn,
    error: errorIn,

    useDate: useDateIn,
    useTime: useTimeIn,
    max: maxIn,
    min: minIn,
    onOpen: onOpenIn,
    onClose: onCloseIn,
}) => {
    const theme = React.useContext(ThemeContext);
    const modal = React.useContext(ModalContext);

    //Methods.
    const showModal = React.useCallback((method) => {
        modal.fire({
            onRequestClose: onClose,
            content: (
                <Modal
                    initialValue={ valueIn }
                    useDate={ useDateIn }
                    useTime={ useTimeIn }
                    max={ maxIn }
                    min={ minIn }
                    onAccept={ onAccept }
                    onCancel={ onClose }
                />
            ),
        });
    }, [ onOpenIn ]);
    const onAccept = React.useCallback((value) => {
        onChangeIn(value);
        modal.close(onCloseIn);
    }, [ onCloseIn ]);
    const onClose = React.useCallback(() => {
        modal.close(onCloseIn);
    }, [ onCloseIn ]);
    const onPress = React.useCallback((method) => {
        onOpenIn(showModal);
    }, [ onOpenIn ]);

    //Format value.
    const value = React.useMemo(() => {
        if (useDateIn && useTimeIn) {
            return unixToDatetime(valueIn);
        } else if (useDateIn) {
            return unixToDate(valueIn);
        } else if (useTimeIn) {
            return unixToTime(valueIn);
        }
    }, [ useDateIn, useTimeIn, valueIn ]);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <Pressable
            style={ styles.container }
            disabled={ disabledIn }
            onPress={ onPress }
        >
            <Template
                label={ labelIn }
                value={ value }
                disabled={ disabledIn }
                editable={ false }
                error={ errorIn }
            />
        </Pressable>
    );
}

//Exports.
export default Component;
