
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";
import AuthenticationContext from "@contexts/authentication";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import DefButton from "@components/buttons/def";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);
    const authentication = React.useContext(AuthenticationContext);

    //Methods.
    const onSignOutPress = React.useCallback(authentication.signOut, []);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <ScreenWrapper>
            <View style={ styles.container }>
                <View style={ styles.itemContainer }>
                    <DefButton
                        label={ localisation.t("screens.home.personalSettings.signOut") }
                        icon={{
                            source: "FontAwesome5",
                            name: "sign-out-alt",
                        }}
                        onPress={ onSignOutPress }
                    />
                </View>
            </View>
        </ScreenWrapper>
    );
}

//Exports.
export default Component;
