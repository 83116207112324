
//Framework.
import React from "react";
import { ScrollView, View, Text, Pressable, Image, Animated } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Svg, Defs, Rect, Mask, Circle } from "react-native-svg";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";

//Constants.
import { API_URL } from "@constants/server";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";
import SessionContext from "@contexts/session";

//Components.
import ModalWrapper from "@components/containers/wrappers/modal";
import BodyWrapper from "@components/containers/wrappers/body";
import DialogueCard from "@components/containers/cards/def/dialogue";
import UserImage from "@components/images/user";
import Icon from "@components/misc/icon";

//Styling.
import stylesheetComponent from "./stylesheets/imageModal/component";
import stylesheetButton from "./stylesheets/imageModal/button";

//Constants.
const ZOOM_STEP_SIZE = 5;
const BUTTON_ICON_SIZE_MOBILE = 16;
const BUTTON_ICON_SIZE_TABLET = 26;
const BUTTON_ICON_SIZE_DESKTOP = 32;
const BUTTON_HOVER_DURATION_MS = 300;

const Component = ({ onChange, onClose, editable, uri, initialWidth, initialHeight }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);
    const session = React.useContext(SessionContext);

    //Handle image state.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        switch(action.type) {
            case("setImage"): return {
                ...oldState,
                editing: true,
                image: {
                    ...oldState.image,
                    uri: action.uri,
                    originalWidth: action.width,
                    originalHeight: action.height,
                },
                cropper: {
                    ...oldState.cropper,
                    x: oldState.image.width / 2,
                    y: oldState.image.height / 2,
                    r: oldState.image.width / 4,
                },
            };
            case("setSize"): return correctCropper({
                ...oldState,
                image: {
                    ...oldState.image,
                    width: action.width,
                    height: action.height,
                },
            });
            case("setSubmitting"): return {
                ...oldState,
                submitting: action.value,
            };
            case("startDrag"): return {
                ...oldState,
                cropper: {
                    ...oldState.cropper,
                    dragging: true,
                    dragStartPos: {
                        x: oldState.cropper.x,
                        y: oldState.cropper.y,
                    },
                    pressStartPos: {
                        x: action.x,
                        y: action.y,
                    },
                },
            };
            case("stopDrag"): return {
                ...oldState,
                cropper: {
                    ...oldState.cropper,
                    dragging: false,
                },
            };
            case("drag"): return correctCropper({
                ...oldState,
                cropper: {
                    ...oldState.cropper,
                    x: oldState.cropper.dragStartPos.x + (action.x - oldState.cropper.pressStartPos.x),
                    y: oldState.cropper.dragStartPos.y + (action.y - oldState.cropper.pressStartPos.y),
                },
            });
            case("zoom"): return correctCropper({
                ...oldState,
                cropper: {
                    ...oldState.cropper,
                    r: oldState.cropper.r + (action.delta ? -ZOOM_STEP_SIZE : ZOOM_STEP_SIZE),
                },
            });
        }
    }, {
        editing: false,
        submitting: false,
        image: {
            uri: uri,
            width: 0,
            height: 0,
            originalWidth: initialWidth,
            originalHeight: initialHeight,
        },
        cropper: {
            dragging: false,
            x: 0,
            y: 0,
            r: 0,
            dragStartPos: {
                x: 0,
                y: 0,
            },
            pressStartPos: {
                x: 0,
                y: 0,
            },
        },
    });

    //Methods.
    const onClosePress = React.useCallback(onClose, [ onClose ]);
    const onAcceptPress = React.useCallback(() => {
        dispatch({ type:"setSubmitting", value:true });
        (async () => {
            const crop = (await manipulateAsync(
                state.image.uri,
                [{ crop: {
                    originX: state.cropper.x - state.cropper.r,
                    originY: state.cropper.y - state.cropper.r,
                    width: state.cropper.r * 2,
                    height: state.cropper.r * 2
                }}],
                {
                    compress: 1,
                    format: SaveFormat.JPEG
                }
            )).uri;
            console.log(crop);
            dispatch({ type:"setSubmitting", value:false });
            onChange(crop);
        })()
    }, [ state, onChange ]);
    const onUploadPress = React.useCallback(async () => {
        //Wait for user to upload image.
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
        });
        //Get dimensions.
        Image.getSize(result.assets[0].uri, (width, height) => {
            //Update image.
            if (!result.canceled) {
                dispatch({
                    type: "setImage",
                    uri: result.assets[0].uri,
                    width: width,
                    height: height,
                });
            }
        });
    }, []);
    const onImageLayout = React.useCallback((e) => {
        dispatch({
            type: "setSize",
            width: e.nativeEvent.layout.width,
            height: e.nativeEvent.layout.height,
        });
    }, []);
    const onImagePressIn = React.useCallback((state.editing || null) && ((e) => dispatch({
        type: "startDrag",
        x: e.nativeEvent.locationX,
        y: e.nativeEvent.locationY,
    })), [ state.editing ]);
    const onImagePressOut = React.useCallback((state.editing || null) && ((e) => dispatch({ type:"stopDrag" })), [ state.editing ]);
    const onImageMove = React.useCallback((state.cropper.dragging || null) && ((e) => dispatch({
        type: "drag",
        x: e.nativeEvent.layerX,
        y: e.nativeEvent.layerY,
    })), [ state.cropper.dragging ]);
    const onImageZoom = React.useCallback((state.editing || null) && ((e) => dispatch({
        type: "zoom",
        delta: e.deltaY >= 0,
    })), [ state.editing ]);

    //Get image size.
    const imageSize = React.useMemo(() => {
        return {
            width: state.image.originalWidth,
            height: state.image.originalHeight,
        };
    }, [ state.image.uri ]);

    //Get button icon size.
    const buttonIconSize = React.useMemo(() => {
        return media.break === media.breaks.mobile ? BUTTON_ICON_SIZE_MOBILE : media.break === media.breaks.tablet ? BUTTON_ICON_SIZE_TABLET : BUTTON_ICON_SIZE_DESKTOP;
    }, [ media.break ]);

    //Return.
    const styles = stylesheetComponent(theme, imageSize, buttonIconSize, state.submitting);
    return (
        <View style={ styles.container }>

            {/* Image. */}
            <View style={ styles.imageContainer }>
                <View
                    style={ styles.imageWrapper }
                    onLayout={ onImageLayout }
                >
                    <Image
                        style={ styles.image }
                        source={{ uri:state.image.uri }}
                    />
                    { state.editing && (
                        <View style={{ position:"absolute", top:0, bottom:0, left:0, right:0 }}>
                            <Svg height="100%" width="100%" viewBox={ `0 0 ${ state.image.width } ${ state.image.height }` }>
                                <Defs>
                                    <Mask id="mask" x="0" y="0" width="100%" height="100%">
                                        <Rect height="100%" width="100%" fill="#fff" />
                                        <Circle
                                            r={ state.cropper.r }
                                            cx={ state.cropper.x }
                                            cy={ state.cropper.y }
                                        />
                                    </Mask>
                                </Defs>
                                <Rect x="0" y="0" width="100%" height="100%" fill="rgba(0,0,0,0.5)" mask="url(#mask)"/>
                            </Svg>
                        </View>
                    )}
                </View>

                { state.editing && (
                    <Pressable
                        style={ styles.cropper }
                        onPressIn={ onImagePressIn }
                        onPressOut={ onImagePressOut }
                        onMouseMove={ onImageMove }
                        onWheel={ onImageZoom }
                        disabled={ state.submitting }
                    />
                )}
            </View>

            {/* Buttons. */}
            <View style={ styles.buttonsContainer }>
                <View style={ styles.buttonContainer }>
                    <ControlButton
                        icon={{
                            source: "FontAwesome5",
                            name: "times",
                        }}
                        size={ buttonIconSize }
                        disabled={ state.submitting }
                        onPress={ onClosePress }
                    />
                </View>

                { editable && (
                    <>
                        <View style={ styles.buttonSplitter }/>
                        <View style={ styles.buttonContainer }>
                            <ControlButton
                                icon={{
                                    source: "FontAwesome5",
                                    name: state.editing ? "check" : "upload",
                                }}
                                size={ buttonIconSize }
                                disabled={ state.submitting }
                                onPress={ state.editing ? onAcceptPress : onUploadPress }
                            />
                        </View>
                    </>
                )}
            </View>

        </View>
    );
}

const ControlButton = ({ icon, size, disabled, onPress }) => {
    const theme = React.useContext(ThemeContext);

    //Animations.
    const animations = {
        hover: React.useRef(new Animated.Value(0)).current,
    };

    //Methods.
    const onHoverIn = React.useCallback(() => {
        Animated.timing(animations.hover, {
            toValue: 1,
            duration: BUTTON_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    });
    const onHoverOut = React.useCallback(() => {
        Animated.timing(animations.hover, {
            toValue: 0,
            duration: BUTTON_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    });

    //Return.
    const styles = stylesheetButton(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
            onHoverIn={ onHoverIn }
            onHoverOut={ onHoverOut }
            disabled={ disabled }
        >
            <Animated.Text style={ styles.iconWrapper }>
                <Icon
                    source={ icon.source }
                    name={ icon.name }
                    size={ size }
                />
            </Animated.Text>
        </Pressable>
    );
};

const correctCropper = (state) => {
    const newR =
        state.cropper.r < ZOOM_STEP_SIZE ? ZOOM_STEP_SIZE :
        Math.min(
            Math.min(state.image.height / 2, state.cropper.r),
            Math.min(state.image.width / 2, state.cropper.r)
        );
    const newX =
        state.cropper.x - newR < 0 ? newR :
        state.cropper.x + newR > state.image.width ? state.image.width - newR :
        state.cropper.x;
    const newY =
        state.cropper.y - newR < 0 ? newR :
        state.cropper.y + newR > state.image.height ? state.image.height - newR :
        state.cropper.y;
    //Return.
    return {
        ...state,
        cropper: {
            ...state.cropper,
            r: newR,
            x: newX,
            y: newY,
        },
    };
};

//Exports.
export default Component;
