
//Framework.
import React from "react";
import { Platform, View, Pressable, Switch, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/toggle";

/*
    options: {
        on | off: {
            value: (any),
            content: (component),
            colours: {
                background,
                track,
                thumb,
            },
        },
    }
    value: (any),
    onChange: (method),
    (Optional) disabled: (bool),
*/

//Constants.
const HEIGHT = 30;
const ANIMATION_TOGGLE_DURATION_MS = 150;

const Component = ({ options:optionsIn, value:valueIn, onChange:onChangeIn, disabled:disabledIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onPress = React.useCallback((e) => {
        onChangeIn(valueIn === optionsIn.on.value ? optionsIn.off.value : optionsIn.on.value);
    }, [ valueIn, onChangeIn ]);

    //Animations.
    const animations = {
        toggle: React.useRef(new Animated.Value(valueIn === optionsIn.on.value ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (valueIn === optionsIn.on.value) {
                Animated.timing(animations.toggle, {
                    toValue: 1,
                    duration: ANIMATION_TOGGLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.toggle, {
                    toValue: 0,
                    duration: ANIMATION_TOGGLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ valueIn ]);

    //Mark as isInitialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Calculate height.
    const height = React.useMemo(() => {
        return HEIGHT + 2 * theme.spacing.small
    }, [ theme ]);
    
    //Return.
    const styles = stylesheet(theme, animations, optionsIn, height);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
            disabled={ disabledIn }
        >
            {/* Off. */}
            <Animated.View style={ styles.offContainer }>
                { optionsIn.off.content }
            </Animated.View>

            {/* Switch. */}
            <View style={ styles.switchContainer }>
                <Animated.View style={ styles.switchOffContainer }/>
                <Animated.View style={ styles.switchOnContainer }/>
                <Switch
                    value={ valueIn === optionsIn.on.value }
                    off={{
                        thumbColour: optionsIn.off.colours.thumb,
                        trackColour: optionsIn.off.colours.track,
                    }}
                    on={{
                        thumbColour: optionsIn.on.colours.thumb,
                        trackColour: optionsIn.on.colours.track,
                    }}

                    {
                        ...Platform.select({
                            web: {
                                thumbColor: optionsIn.off.colours.thumb,
                                activeThumbColor: optionsIn.on.colours.thumb,
                            },
                            ios: {
                                thumbColor: valueIn === optionsIn.on.value ? optionsIn.on.colours.thumb : optionsIn.off.colours.thumb,
                                ios_backgroundColor: optionsIn.off.colours.track,
                            },
                            android: {
                                thumbColor: valueIn === optionsIn.on.value ? optionsIn.on.colours.thumb : optionsIn.off.colours.thumb,
                            },
                        })
                    }
                    trackColor={{ true:optionsIn.on.colours.track, false:optionsIn.off.colours.track, }}
                />
            </View>

            {/* On. */}
            <Animated.View style={ styles.onContainer }>
                { optionsIn.on.content }
            </Animated.View>
        </Pressable>
    );
}

//Exports.
export default Component;
