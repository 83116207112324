
const user = {
    phoneNumberPrefix: {
        minLength: 2,
        maxLength: 15,
    },
    phoneNumber: {
        minLength: 4,
        maxLength: 15,
    },
    username: {
        minLength: 3,
        maxLength: 50,
    },
    email: {
        maxLength: 100,
    },
    password: {
        minLength: 8,
        maxLength: 1000,
    },
    firstName: {
        maxLength: 100,
    },
    lastName: {
        maxLength: 100,
    },
    birthdate: {
        getEarliest: () => {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 100);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            return date.getTime();
        },
        getLatest: () => {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            date.setMilliseconds(999);
            return date.getTime();
        },
    },
    gender: {
        choices: [ "m", "f" ],
    },
    activationCode: {
        exactLength: 6,
    },
};

export default user;
