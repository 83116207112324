
//Width.
const width = {
    small: 1,
    medium: 2,
    large: 4,
};

//Radius.
const radius = {
    small: 10,
    medium: 20,
    large: 40,
};

//Exports.
export default {
    width: width,
    radius: radius,
};
