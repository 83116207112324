
const EVENT_TYPES = [{
    descId: 1,
    labelKey: "eventTypes.unlisted",
    icon: {
        source: "FontAwesome5",
        name: "circle",
    },
}, {
    descId: 2,
    labelKey: "eventTypes.football",
    icon: {
        source: "FontAwesome5",//FontAwesome
        name: "futbol",//soccer-ball-o
    },
}, {
    descId: 3,
    labelKey: "eventTypes.golf",
    icon: {
        source: "FontAwesome5",
        name: "golf-ball",
    },
}, {
    descId: 4,
    labelKey: "eventTypes.roundball",
    icon: {
        source: "FontAwesome5",//MaterialCommunityIcons
        name: "baseball-ball",//baseball-bat
    },
}, {
    descId: 5,
    labelKey: "eventTypes.basketball",
    icon: {
        source: "FontAwesome5",
        name: "basketball-ball",
    },
}, {
    descId: 6,
    labelKey: "eventTypes.hockey",
    icon: {
        source: "FontAwesome5",
        name: "hockey-puck",
    },
}, {
    descId: 7,
    labelKey: "eventTypes.dogWalking",
    icon: {
        source: "FontAwesome5",
        name: "dog",
    },
}, {
    descId: 8,
    labelKey: "eventTypes.walk",
    icon: {
        source: "FontAwesome5",
        name: "hiking",//walking
    },
}, {
    descId: 9,
    labelKey: "eventTypes.run",
    icon: {
        source: "FontAwesome5",
        name: "running",
    },
}, {
    descId: 10,
    labelKey: "eventTypes.horseRiding",
    icon: {
        source: "FontAwesome5",//MaterialCommunityIcons
        name: "hiking",//horse-human
    },
}, {
    descId: 11,
    labelKey: "eventTypes.rollerSkating",
    icon: {
        source: "MaterialCommunityIcons",
        name: "rollerblade",
    },
}, {
    descId: 12,
    labelKey: "eventTypes.swimming",
    icon: {
        source: "FontAwesome5",
        name: "swimmer",
    },
}, {
    descId: 13,
    labelKey: "eventTypes.tableTennis",
    icon: {
        source: "FontAwesome5",
        name: "table-tennis",
    },
}, {
    descId: 14,
    labelKey: "eventTypes.americanFootball",
    icon: {
        source: "FontAwesome5",
        name: "football-ball",
    },
}, {
    descId: 15,
    labelKey: "eventTypes.volleyBall",
    icon: {
        source: "FontAwesome5",
        name: "volleyball-ball",
    },
}, {
    descId: 16,
    labelKey: "eventTypes.tennis",
    icon: {
        source: "MaterialCommunityIcons",
        name: "tennis-ball",
    },
}, {
    descId: 17,
    labelKey: "eventTypes.badminton",
    icon: {
        source: "MaterialCommunityIcons",
        name: "badminton",
    },
}, {
    descId: 18,
    labelKey: "eventTypes.yoga",
    icon: {
        source: "MaterialCommunityIcons",
        name: "yoga",
    },
}, {
    descId: 19,
    labelKey: "eventTypes.crossfit",
    icon: {
        source: "MaterialCommunityIcons",
        name: "arm-flex",
    },
}, {
    descId: 20,
    labelKey: "eventTypes.rowing",
    icon: {
        source: "MaterialIcons",
        name: "rowing",
    },
}, {
    descId: 21,
    labelKey: "eventTypes.fishing",
    icon: {
        source: "MaterialCommunityIcons",
        name: "hook",
    },
}, {
    descId: 22,
    labelKey: "eventTypes.bicycling",
    icon: {
        source: "FontAwesome5",
        name: "bicycle",
    },
}, {
    descId: 23,
    labelKey: "eventTypes.babyWalk",
    icon: {
        source: "MaterialIcons",
        name: "child-friendly",
    },
}, {
    descId: 24,
    labelKey: "eventTypes.snowmanBuilding",
    icon: {
        source: "FontAwesome5",
        name: "snowman",
    },
}, {
    descId: 25,
    labelKey: "eventTypes.kiteSurfing",
    icon: {
        source: "MaterialCommunityIcons",
        name: "police-station",//kitesurfing
    },
}, {
    descId: 26,
    labelKey: "eventTypes.kayaking",
    icon: {
        source: "MaterialCommunityIcons",
        name: "kayaking",
    },
}, {
    descId: 27,
    labelKey: "eventTypes.handball",
    icon: {
        source: "MaterialCommunityIcons",
        name: "handball",
    },
}, {
    descId: 28,
    labelKey: "eventTypes.chess",
    icon: {
        source: "FontAwesome5",
        name: "chess-knight",
    },
}, {
    descId: 29,
    labelKey: "eventTypes.archery",
    icon: {
        source: "MaterialCommunityIcons",
        name: "bow-arrow",
    },
}, {
    descId: 30,
    labelKey: "eventTypes.shooting",
    icon: {
        source: "MaterialCommunityIcons",
        name: "pistol",
    },
}];

export default EVENT_TYPES;
