
//Framework.
import React from "react";

//Constants.
import USER from "@constants/models/user";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import Template from "@components/inputs/templates/unauthenticated";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    value: (string),
    label: (string),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) returnKeyType: (string: "done"),
    (Optional) onChange: (method),
    (Optional) onBlur: (method),
    (Optional) error: (string),
*/

const Component = ({
    value: valueIn,
    label: labelIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    returnKeyType: returnKeyTypeIn = "done",
    onChange: onChangeIn,
    onBlur: onBlurIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <Template
            label={ labelIn }
            value={ valueIn }
            disabled={ disabledIn }
            editable={ editableIn }
            maxLength={ USER.password.maxLength }
            inputMode="text"
            textContentType="password"
            returnKeyType={ returnKeyTypeIn }
            sensitive={ true }
            onChange={ onChangeIn }
            onBlur={ onBlurIn }
            error={ errorIn }
        />
    );
}

//Exports.
export default Component;
