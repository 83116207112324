
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const stylesheet = (theme, media) => {
    return StyleSheet.create({
        scrollContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            paddingBottom: getCreateButtonOverflow(theme, media),
        },

        //Nav buttons.
        navButtonsContainer: {
            padding: theme.spacing.medium / 2,
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
        },
        navButtonContainer: {
            padding: theme.spacing.medium / 2,
            flexBasis: 250,
            flexShrink: 1,
        },
        navButtonCounterContainer: {
            position: "absolute",
            top: 0,
            right: 0,
        },

        //Events.
        eventsContainer: {
            padding: theme.spacing.medium / 2,
        },
        eventContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
