
//Framework.
import React, { useImperativeHandle, forwardRef } from "react";
import { View } from "react-native";
import * as Location from "expo-location";

//Constants.
import { LATITUDE_FALLBACK, LONGITUDE_FALLBACK, ZOOM_LEVEL_FALLBACK } from "@constants/map";

//Context.
import ThemeContext from "@contexts/theme";
import LoadingScreenContext from "@contexts/loadingScreen";

//Children.
import MapView from "./view/main";

const Component = ({ markers:markersIn = [], interactable:interactableIn = true, onPress:onPressIn, onMove:onMoveIn, onInitialised:onInitialisedIn }, ref) => {
    const theme = React.useContext(ThemeContext);
    const loadingScreen = React.useContext(LoadingScreenContext);

    //Bind ref.
    const mapViewRef = React.useRef();
    useImperativeHandle(ref, () => ({
        zoomIn: () => mapViewRef.current.zoomIn(),
        zoomOut: () => mapViewRef.current.zoomOut(),
        zoomHome: () => mapViewRef.current.zoomHome(),
    }));

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ homeCoords, setHomeCoords ] = React.useState({
        latitude: LATITUDE_FALLBACK,
        longitude: LONGITUDE_FALLBACK,
        zoom: ZOOM_LEVEL_FALLBACK,
    });

    //Request user's location on startup.
    React.useEffect(() => {
        loadingScreen.queue((finish) => (async () => {
            /*
            const status = await Location.requestForegroundPermissionsAsync().catch((error) => console.log(error));
            if (status.granted) {
                console.log(status);
            } else {
                setInitialCoords({});
            }
            */
            finish();
        })());
    }, []);
    React.useEffect(() => {
        if (!loadingScreen.isLoading && !isInitialised) {
            setIsInitialised(true);
        }
    }, [ loadingScreen.isLoading ]);
    React.useEffect(() => {
        if (isInitialised && onInitialisedIn) onInitialisedIn();
    }, [ isInitialised ]);
    
    //Make map reference.
    const mapRef = React.useRef();

    //Render.
    return isInitialised && (
        <MapView
            ref={ mapViewRef }
            homeCoords={ homeCoords }
            markers={ markersIn }
            interactable={ interactableIn }
            onPress={ onPressIn }
            onMove={ onMoveIn }
        />
    );
}

//Exports.
export default forwardRef(Component);
