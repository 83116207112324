
//Framework.
import React from "react";
import { View, Pressable, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import SessionContext from "@contexts/session";

//Components.
import Icon from "@components/misc/icon";
import UserImage from "@components/images/user";

//Styling.
import stylesheet from "./stylesheets/def";

const Component = ({ navigation, route, options, back, title }) => {
    const theme = React.useContext(ThemeContext);
    const session = React.useContext(SessionContext);

    //Return.
    const styles = stylesheet(theme);
    return (
        <View style={ styles.container }>
            { navigation.canGoBack() && back !== undefined && (
                <Pressable
                    style={ styles.button }
                    onPress={ navigation.goBack }
                >
                    <Icon
                        source="FontAwesome5"
                        name="chevron-left"
                        size={ theme.fonts.text.lineHeights.medium }
                        colour={ theme.colour.primary }
                    />
                </Pressable>
            )}
            <Text
                style={ styles.label }
                selectable={ false }
                numberOfLines={ 1 }
            >
                { title }
            </Text>
            <View style={ styles.imageContainer }>
                <UserImage
                    user={ session.user }
                    size={ theme.fonts.text.lineHeights.large + theme.spacing.medium }
                />
            </View>
        </View>
    );
}

//Exports.
export default Component;
