
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";

const fetch = async (key) => {
    return await AsyncStorage.getItem(key);
};
const store = async (key, value) => {
    await AsyncStorage.setItem(key, value);
};
const remove = async (key) => {
    await AsyncStorage.removeItem(key);
};

const fetchSecure = async (key) => {
    let value;
    if (Platform.OS === "web") {
        value = await AsyncStorage.getItem(key);
    } else {
        value = await SecureStore.getItemAsync(key);
    }
    return value;
};
const storeSecure = async (key, value) => {
    if (Platform.OS === "web") {
        await AsyncStorage.setItem(key, value);
    } else {
        await SecureStore.setItemAsync(key, value);
    }
};
const removeSecure = async (key) => {
    if (Platform.OS === "web") {
        await AsyncStorage.removeItem(key);
    } else {
        await SecureStore.deleteItemAsync(key);
    }
};

export {
    fetch,
    store,
    remove,
    fetchSecure,
    storeSecure,
    removeSecure,
}
