
//Framework.
import React from "react";
import { AppState, View, Platform, } from "react-native";

//Helpers.
import { fetchSecure, storeSecure, removeSecure } from "@helpers/device";
import { send } from "@helpers/connection";

//Contexts.
import LocalisationContext from "@contexts/localisation";
import SessionContext from "@contexts/session";
import ModalContext from "@contexts/modal";
import LoadingScreenContext from "@contexts/loadingScreen";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import CardDialogue from "@components/containers/cards/def/dialogue";
import CardServerError from "@components/containers/cards/def/serverError";
import CardConnectionError from "@components/containers/cards/def/connectionError";
import CardUserError from "@components/containers/cards/def/userError";

//Styling.
import stylesheet from "./stylesheets/authentication";

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {
    const localisation = React.useContext(LocalisationContext);
    const session = React.useContext(SessionContext);
    const modal = React.useContext(ModalContext);
    const loadingScreen = React.useContext(LoadingScreenContext);
    
    //When app gets focus, check if session is still valid.
    //https://reactnative.dev/docs/appstate
    /*
    React.useEffect(() => {
        const sessionExpirationChecker = AppState.addEventListener("change", () => {
            if (
                appState.current.match(/inactive|background/) &&
                nextAppState === "active"
            ) {
                console.log("App has come to the foreground!");
            }
        });
    }, []);
    */

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ isAuthenticated, setIsAuthenticated ] = React.useState(false);

    //Methods.
    const restore = React.useCallback(() => {
        (async () => {
            //Fetch token.
            const token = await fetchSecure("accessToken");
            //Verify token back-end.
            if (token) {
                send({
                    url: "restore-token",
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "text/plain",
                        Authorization: `Bearer ${ token }`,
                    },
                    authenticated: false,
                    localisation: localisation.locale.key,
                    onSuccess: async (response) => {
                        const result = await response.json();
                        //Update session.
                        session.start(result.session.user);
                        //Flag authenticated.
                        setIsAuthenticated(true);
                    },
                    onServerError: async (response) => {
                        const onClose = () => modal.close();
                        modal.fire({
                            onRequestClose: onClose,
                            content: (
                                <WrapperModal size="small">
                                    <CardServerError
                                        onClose={ onClose }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onUserError: async (response) => {
                        const onClose = () => modal.close();
                        modal.fire({
                            onRequestClose: onClose,
                            content: (
                                <WrapperModal size="small">
                                    <CardUserError
                                        title="test title"
                                        text="test text"
                                        errors={[ "I shit my pants", "U R ugly", "holobonga my hazassle mah nigga boii" ]}
                                        onClose={ onClose }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onConnectionError: () => {
                        const onClose = () => modal.close();
                        modal.fire({
                            onRequestClose: onClose,
                            content: (
                                <WrapperModal size="small">
                                    <CardConnectionError
                                        onClose={ onClose }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onFinish: () => setIsInitialised(true),
                });
                /*
                connection.send({
                    url: "restore-token",
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "text/plain",
                        Authorization: `Bearer ${token}`,
                    },
                    authenticated: false,
                    onSuccess: async (response) => {
                        const result = await response.json();
                        //Update session.
                        session.setIsAuthenticated(true);
                        session.set(result.session);
                        //Auto authenticate.
                        dispatch({ type:"RESTORE_TOKEN", token:token });
                    },
                    onError: async (response) => {
                        //Clear saved token.
                        await clearAccessToken(Platform.OS);
                        dispatch({ type:"RESTORE_TOKEN" });
                    },
                    onCriticalError: async () => {
                        //Clear saved token.
                        await clearAccessToken(Platform.OS);
                        dispatch({ type:"RESTORE_TOKEN" });
                    },
                });
                */
            } else {
                //Mark as initialised.
                setIsInitialised(true);
            }
        })()
    }, []);
    const signIn = React.useCallback((phoneNumberPrefix, phoneNumber, password, onServerError, onUserError, onConnectionError) => {
        loadingScreen.queue((finish) => {
            send({
                url: "sign-in",
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: {
                    phoneNumberPrefix: phoneNumberPrefix,
                    phoneNumber: phoneNumber,
                    password: password,
                },
                authenticated: false,
                localisation: localisation.locale.key,
                onSuccess: async (response) => {
                    const result = await response.json();
                    //Save token.
                    storeSecure("accessToken", result.token);
                    //Update session.
                    session.start(result.session.user);
                    //Flag authenticated.
                    setIsAuthenticated(true);
                },
                onServerError: onServerError,
                onUserError: onUserError,
                onConnectionError: onConnectionError,
                onFinish: () => finish(),
            })
        });
    }, []);
    const signOut = React.useCallback(() => {
        send({
            url: "sign-out",
            method: "post",
            //localisation: localisation.locale.key,
            onFinish: () => {
                //Clear session.
                session.end();
                //Clear token.
                removeSecure("accessToken");
                //Update state.
                setIsAuthenticated(false);
            },
        });
    }, []);
    const register = React.useCallback((phoneNumberPrefix, phoneNumber, password, firstName, lastName, birthdate, gender, onSuccess, onServerError, onUserError, onConnectionError) => {
        loadingScreen.queue((finish) => {
            send({
                url: "sign-up",
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: {
                    phoneNumberPrefix: phoneNumberPrefix,
                    phoneNumber: phoneNumber,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    birthdate: birthdate,
                    gender: gender,
                },
                authenticated: false,
                localisation: localisation.locale.key,
                onSuccess: onSuccess,
                onServerError: onServerError,
                onUserError: onUserError,
                onConnectionError: onConnectionError,
                onFinish: () => finish(),
            })
        });
    }, []);

    //Create value.
    const value = React.useMemo(() => ({
        isAuthenticated: isAuthenticated,
        isInitialised: isInitialised,

        restore: restore,
        signIn: signIn,
        signOut: signOut,
        register: register,
        /*
        state: state,
        restoreToken: restoreToken,
        signIn: signIn,
        signOut: signOut,
        signUp: signUp,
        */
        //refresh: refresh,
        //queueRefresh: queueRefresh,
        //setQueueRefresh: setQueueRefresh,
    }), [ isAuthenticated, isInitialised ]);
    global.authentication = value;
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <Context.Provider value={value}>
            { children }
        </Context.Provider>
    );
}

/*
const SignUpVerificationModal = (props) => {
    //Get contexts.
    const localisation = React.useContext(LocalisationContext);
    const theme = React.useContext(ThemeContext);
    const connection = React.useContext(ConnectionContext);
    const modal = React.useContext(ModalContext);
    
    //Sign up verification failed modal.
    const [fireFailedModal, setFireFailedModal] = React.useState(false);
    const [failedMsgs, setFailedMsgs] = React.useState("");
    React.useEffect(() => {
        if (fireFailedModal) {
            setFireFailedModal(false);
            modal.fire("info", {
                size: "medium",
                onRequestClose: () => modal.close("info"),
                content: (
                    <CardError
                        onClose={ () => modal.close("info") }
                        title={ i18n.t("contexts.authentication.signUpVerificationFailedTitle") }
                        text={ i18n.t("contexts.authentication.signUpVerificationFailedDesc") }
                        errors={ failedMsgs }
                    />
                ),
            });
        }
    }, [ fireFailedModal ]);
    
    //States.
    const form = useForm({ mode: "onSubmit", });
    const [formData, setFormData] = React.useState({});
    const [fireSubmit, setFireSubmit] = React.useState(false);
    
    //Effects.
    React.useEffect(() => {
        form.clearErrors();
    }, [localisation]);
    React.useEffect(() => {
        if (fireSubmit) {
            setFireSubmit(false);
            modal.close("form");
            //Send request.
            connection.send({
                url: "activate-user",
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: {
                    phoneNumberPrefix: props.phoneNumberPrefix,
                    phoneNumber: props.phoneNumber,
                    digits: formData.digits,
                },
                authenticated: false,
                onSuccess: async (response) => {
                    //props.showSuccessModalSetter(true);
                    //setFireSuccessModal(true);
                    props.alert.post({
                        content: (
                            <AlertSuccess
                                title={ i18n.t("contexts.authentication.signUpSuccessTitle") }
                                text={ i18n.t("contexts.authentication.signUpSuccessDesc") }
                                icon={{
                                    source: "FontAwesome5",
                                    name: "user-plus",
                                }}
                            />
                        ),
                    });
                },
                onError: async (response) => {
                    const result = await response.json();
                    //props.signUpMsgSetter(result);
                    //props.showFailureModalSetter(true);
                    setFailedMsgs(result);
                    setFireFailedModal(true);
                },
            });
        }
    }, [fireSubmit]);
    
    //Methods.
    const submitFunc = () => {
        setFormData(form.getValues());
        setFireSubmit(true);
        //modal.close("form");
    }
    
    //Render.
    return (
        <CardDef
            header={(
                <CardHeaderDef
                    text={ i18n.t("contexts.authentication.signUpVerificationModalTitle") }
                    status="primary"
                />
            )}
            body={(
                <CardBodyDef>
                    <View style={{ padding:theme.spacing.large / 2, }}>
                        <View style={{ padding:theme.spacing.medium / 2, textAlign:"center", }}>
                            <TextDef>
                                { i18n.t("contexts.authentication.signUpVerificationModalDesc", { phoneNumber:props.phoneNumber, phoneNumberPrefix:props.phoneNumberPrefix }) }
                            </TextDef>
                        </View>
                        <View style={{ padding:theme.spacing.medium / 2, }}>
                            <Controller
                                control={ form.control }
                                name="digits"
                                defaultValue={ "" }
                                render={({field: { onChange, value, onBlur }}) => (
                                    <InputString
                                        theme="def"
                                        label={ i18n.t("screens.unauthenticated.signUp.signUpModalInputLabel") }
                                        value={ value }
                                        onChange={ onChange }
                                        onBlur={ onBlur }
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: i18n.t("validation.required", { field:i18n.t("screens.unauthenticated.signUp.signUpModalInputLabelValidation"), }),
                                    },
                                    minLength: {
                                        value: 6,
                                        message: i18n.t("validation.minLength", { field:i18n.t("screens.unauthenticated.signUp.signUpModalInputLabelValidation"), value:6, }),
                                    },
                                    maxLength: {
                                        value: 6,
                                        message: i18n.t("validation.maxLength", { field:i18n.t("screens.unauthenticated.signUp.signUpModalInputLabelValidation"), value:6, }),
                                    },
                                }}
                            />
                            { form.formState.errors.digits ? (
                                <TextError>
                                    { form.formState.errors.digits.message }
                                </TextError>
                            ) : (<></>) }
                        </View>
                    </View>
                </CardBodyDef>
            )}
            footer={(
                <CardFooterDef
                    buttons={[(
                        <FooterButtonIcon
                            source="FontAwesome5"
                            name="times"
                            status="danger"
                            onPress={() => modal.close("form")}
                        />
                    ), (
                        <FooterButtonIcon
                            source="FontAwesome5"
                            name="check"
                            status="success"
                            onPress={() => form.handleSubmit(submitFunc)()}
                        />
                    )]}
                />
            )}
        />
    );
}
*/

/*
function getReducer() {
    return React.useReducer((prevState, action) => {
        switch (action.type) {
            case "RESTORE_TOKEN":
                return {
                    token: action.token,
                    isAuthenticated: action.token ? true : false,
                    isInitialised: true,
                };
            case "SIGN_IN":
                return {
                    ...prevState,
                    token: action.token,
                    isAuthenticated: true,
                };
            case "SIGN_OUT":
                return {
                    ...prevState,
                    token: null,
                    isAuthenticated: false,
                };
            default:
                throw new Error(`Error in contexts/authentication, getReducer: Action type '${action.type}' not supported.`);
        }
    }, {
        isInitialised: false,
        isAuthenticated: false,
        token: null,
    });
}

async function fetchAccessToken(OS) {
    let token = null;
    try {
        if (OS === "web") {
            token = await AsyncStorage.getItem("accessToken");
        } else {
            token = await SecureStore.getItemAsync("accessToken");
        }
    } catch (e) {
        if (__DEV__) console.log("Token fetch error");
    }
    return token;
}

async function saveAccessToken(OS, token) {
    try {
        if (OS === "web") {
            await AsyncStorage.setItem("accessToken", token);
        } else {
            await SecureStore.setItemAsync("accessToken", token);
        }
    } catch(error) {
        if (__DEV__) console.log(error);
    }
}

async function clearAccessToken(OS) {
    try {
        if (OS === "web") {
            await AsyncStorage.removeItem("accessToken");
        } else {
            await SecureStore.deleteItemAsync("accessToken");
        }
    } catch (e) {
        if (__DEV__) console.log("Token removal error");
    }
}
*/

//Exports.
export default Context;
export { Provider };
