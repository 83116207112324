
import { StyleSheet } from "react-native";
import { getSize as getControlsSize } from "@components/misc/map/controls/button";

const stylesheet = (theme, animations) => {

    const constrolsWidth = getControlsSize(theme, 1);

    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
        },

        //Controls.
        filterControlsContainer: {
            position: "absolute",
            bottom: theme.spacing.medium,
            left: animations.filterControlsPush.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ -(constrolsWidth + theme.spacing.medium), theme.spacing.medium ],
            }),
            borderRadius: constrolsWidth / 2,
        },
        zoomControlsContainer: {
            position: "absolute",
            bottom: theme.spacing.medium,
            right: animations.zoomControlsPush.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ -(constrolsWidth + theme.spacing.medium), theme.spacing.medium ],
            }),
            borderRadius: constrolsWidth / 2,
        },
        homeControlsContainer: {
            position: "absolute",
            bottom: 2 * theme.spacing.medium + getControlsSize(theme, 2),
            right: animations.homeControlsPush.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ -(constrolsWidth + theme.spacing.medium), theme.spacing.medium ],
            }),
            borderRadius: constrolsWidth / 2,
            transform: [{ scaleX:-1 }],
        },
    });
}

//Exports.
export default stylesheet;
