
//From env file.
import {
    LATITUDE_FALLBACK as LATITUDE_FALLBACK_ENV,
    LONGITUDE_FALLBACK as LONGITUDE_FALLBACK_ENV,
    ZOOM_LEVEL_FALLBACK as ZOOM_LEVEL_FALLBACK_ENV,
} from "@env";

const MAX_AREA = 10;

const LATITUDE_FALLBACK = parseFloat(LATITUDE_FALLBACK_ENV);
const LONGITUDE_FALLBACK = parseFloat(LONGITUDE_FALLBACK_ENV);
const ZOOM_LEVEL_FALLBACK = parseFloat(ZOOM_LEVEL_FALLBACK_ENV);

export {
    LATITUDE_FALLBACK,
    LONGITUDE_FALLBACK,
    ZOOM_LEVEL_FALLBACK,
    MAX_AREA,
}
