
import { StyleSheet } from "react-native";

const stylesheet = (theme, colour, align) => {
    return StyleSheet.create({
        text: {
            opacity: theme.opacity.faded,
            color: colour,
            textAlign: align,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.small,
        },
    });
}

//Exports.
export default stylesheet;
