
import { StyleSheet } from "react-native";

const stylesheet = (theme, event, heights) => {
    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.foreground,
            borderWidth: theme.border.width.large,
            borderColor: theme.colour.primary,
            borderRadius: 30,
            overflow: "hidden",
        },

        //Header.
        header: {
            height: heights.header,
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing.medium / 2,
            paddingRight: heights.header,
        },
        iconContainer: {
            padding: theme.spacing.small / 2,
            flexBasis: heights.header,
        },
        textContainer: {
            padding: theme.spacing.small / 2,
            flexBasis: 0,
            flexGrow: 1,
        },
        title: {
            color: theme.colour.text,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
        },
        timestamp: {
            color: theme.colour.textDiscrete,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.small,
        },

        //Host.
        hostContainer: {
            backgroundColor: theme.colour.primary,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            borderTopWidth: theme.border.width.large,
            borderBottomWidth: theme.border.width.large,
            borderColor: theme.colour.primary,
            padding: theme.spacing.small / 2,
        },
        hostImageContainer: {
            padding: theme.spacing.small / 2,
        },
        hostTextContainer: {
            padding: theme.spacing.small / 2,
            flexGrow: 1,
        },
        hostName: {
            padding: theme.spacing.medium / 2,
            paddingTop: 0,
            paddingBottom: 0,
            color: theme.colour.primaryText,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: heights.image,
        },
        hostRatingContainer: {
            padding: (heights.image - heights.rating + theme.spacing.small) / 2,
            paddingLeft: theme.spacing.medium / 2,
            paddingRight: theme.spacing.medium / 2,
        },
        priceContainer: {
            backgroundColor: theme.colour.foreground,
            padding: theme.spacing.medium,
            paddingTop: 0,
            paddingBottom: 0,
            color: event.price > 0 ? theme.colour.text : theme.colour.success,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: heights.image,
        },

        //Stats.
        statsContainer: {
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing.medium / 2,
        },
        statContainer: {
            flexBasis: 0,
            flexGrow: 1,
            alignItems: "center",
        },
        statLabel: {
            padding: theme.spacing.small / 2,
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.small,
            textAlign: "center",
        },
        statValue: {
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: heights.stat,
            textAlign: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        playersStatContainer: {
            backgroundColor: "rgba(20, 255, 20, 0.5)",
            margin: theme.spacing.small / 2,
            width: heights.stat,
            height: heights.stat,
            borderRadius: heights.stat / 2,
        },
        pendingStatContainer: {
            backgroundColor: "rgba(255, 255, 20, 0.5)",
            margin: theme.spacing.small / 2,
            width: heights.stat,
            height: heights.stat,
            borderRadius: heights.stat / 2,
        },
        skillLevelStatContainer: {
            margin: theme.spacing.small / 2,
            width: heights.stat,
            height: heights.stat,
        },

        //Buttons.
    });
}

//Exports.
export default stylesheet;
