
//Framework.
import React from "react";
import { Image, View } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import MediaContext from "@contexts/media";

//Styling.
import stylesheet from "./stylesheets/companyBackground";

const Component = ({}) => {
    const theme = React.useContext(ThemeContext);
    const media = React.useContext(MediaContext);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <View style={ styles.container }>
            <Image
                style={ styles.image }
                source={ require("@assets/images/logo/1200x1200.png") }
            />
        </View>
    );
}

//Exports.
export default Component;
