
import { StyleSheet, Platform, Animated } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            flexGrow: 1,
            flexBasis: 0,

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        opacityWrapper: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
            backgroundColor: "rgb(0, 0, 0)",
            width: "100%",
            height: "100%",
        },
        darkenWrapper: {
            opacity: Animated.subtract(1, Animated.add(animations.hover.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.1],
            }), animations.press.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [0, 0.2, 0],
            }))),
            backgroundColor: theme.colour.foreground,
            width: "100%",
            height: "100%",
        },
        iconContainer: {
            padding: theme.spacing.medium,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
        },
    });
}

//Exports.
export default stylesheet;
