
import { StyleSheet } from "react-native";

const stylesheet = (theme, prefixWidth) => {
    return StyleSheet.create({
        prefixButton: {
            padding: theme.spacing.small / 2,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            width: prefixWidth,
        },
        prefixItemContainer: {
            padding: theme.spacing.small / 2,
        },
        prefixImage: {
            width: 30,
            height: "100%",
        },
        prefixText: {
            padding: (theme.fonts.text.sizes.medium - theme.fonts.text.sizes.small),
            paddingLeft: 0,
            paddingRight: 0,
            color: theme.colour.unauthenticatedCardText,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
        },
    });
}

//Exports.
export default stylesheet;
