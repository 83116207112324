
//Framework.
import React from "react";
import { ScrollView, View, Text } from "react-native";

//Helpers.
import { send } from "@helpers/connection";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";
import SessionContext from "@contexts/session";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";

//Children.
import Notification from "./notification";

//Styling.
import stylesheet from "./stylesheets/main";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);
    const session = React.useContext(SessionContext);

    //Define abort controller.
    const abortControllerRef = React.useRef(null);
    React.useEffect(() => {
        abortControllerRef.current = new AbortController();
        return () => abortControllerRef.current.abort();
    }, []);

    //Methods.
    const markAsRead = React.useCallback((notifications) => {
        if (notifications.length > 0) {
            send({
                url: "mark-notifications-as-seen",
                method: "post",
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/json",
                },
                body: {
                    list: notifications.map(notification => notification.id),
                },
                signal: abortControllerRef.current.signal,
                localisation: localisation.locale.key,
                onSuccess: session.refresh,
            });
        }
    }, []);

    //Mark loaded notifications as read.
    React.useEffect(() => markAsRead(session.user.notifications.filter(notification => !notification.seen)), [ session.user.notifications ]);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <ScrollView contentContainerStyle={ styles.scrollContainer }>
            <ScreenWrapper>
                <View style={ styles.container }>
                    { session.user.notifications.map(notification => (
                        <Notification
                            key={ notification.id }
                            notification={ notification }
                        />
                    ))}
                </View>
            </ScreenWrapper>
        </ScrollView>
    );
}

//Exports.
export default Component;
