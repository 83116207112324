
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            borderColor: theme.colour.foregroundSplitter,
            display: "flex",
            flexDirection: "row",
            borderTopWidth: theme.border.width.small,
            justifyContent: "space-evenly",
        },
        splitter: {
            backgroundColor: theme.colour.foregroundSplitter,
            width: theme.border.width.small,
            height: "100%",
        },
    });
}

//Exports.
export default stylesheet;
