
const title = {
    families: {
        light: "sst-light",
        regular: "sst-regular",
        bold: "sst-bold",
    },
    sizes: {
        small: 30,
        medium: 40,
        large: 60,
    },
    lineHeights: {
        small: 39,
        medium: 52,
        large: 79,
    },
};

const text = {
    families: {
        light: "sst-light",
        regular: "sst-regular",
        bold: "sst-bold",
    },
    sizes: {
        small: 13,
        medium: 16,
        large: 20,
    },
    lineHeights: {
        small: 17,
        medium: 21,
        large: 27,
    },
};

//Exports.
export default {
    title: title,
    text: text,
};
