
import months from "./months";
import weekdays from "./weekdays";

const translations = {
    months: months,
    weekdays: weekdays,
};

export default translations;
