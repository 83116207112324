
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations, hasIcon) => {

    const height = theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small + 2 * theme.border.width.medium;

    return StyleSheet.create({
        container: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
        },
        contentContainer: {
            backgroundColor: theme.colour.primary,
            padding: theme.border.width.medium,
            height: height,
            borderRadius: height / 2,
            display: "flex",
            flexDirection: "row",
        },
        iconContainer: {
            flexBasis: theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small,
            padding: theme.spacing.small,
        },
        inputContainer: {
            backgroundColor: theme.colour.foreground,
            flexBasis: 0,
            flexGrow: 1,
            borderRadius: (height - 2 * theme.border.width.medium) / 2,
        },
        input: {
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.small,
            paddingRight: hasIcon ? theme.spacing.small + theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small : theme.spacing.small,
            width: "100%",

            textAlign: "center",
            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        errorContainer: {
            padding: theme.spacing.small / 2,
        },
    });
}

//Exports.
export default stylesheet;
