
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.foreground,
            padding: theme.spacing.medium / 2,
            paddingLeft: theme.spacing.large / 2,
            paddingRight: theme.spacing.large / 2,
            display: "flex",
            flexDirection: "row",

            //Shadow.
            shadowColor: theme.colour.shadow,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: theme.opacity.shadow,
            shadowRadius: 5,
        },
        button: {
            padding: theme.spacing.medium / 2 + (theme.fonts.text.lineHeights.large - theme.fonts.text.lineHeights.medium) / 2,
            paddingLeft: theme.spacing.medium / 2,
            paddingRight: theme.spacing.medium / 2,
        },
        label: {
            flexBasis: 0,
            flexGrow: 1,
            color: theme.colour.primary,
            padding: theme.spacing.medium / 2,
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
        },
        imageContainer: {
        },
    });
}

//Exports.
export default stylesheet;
