
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import WrapperBody from "@components/containers/wrappers/body";

//Styling.
import stylesheet from "./stylesheets/def";

/*
    title: (string),
*/

const Component = ({ children, title }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme);
    return (
        <View style={ styles.container }>
            <WrapperBody>
                <Text
                    adjustsFontSizeToFit={ true }
                    selectable={ false }
                    style={ styles.title }
                >
                    { title }
                </Text>
                <View style={ styles.contentContainer }>
                    { children }
                </View>
            </WrapperBody>
        </View>
    );
}

//Exports.
export default Component;
