
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import CardTemplate from "@components/containers/cards/def/template";
import CardHeaderTitle from "@components/containers/cards/def/headers/title";
import CardFooterIcons from "@components/containers/cards/def/footers/icons";
import WrapperBody from "@components/containers/wrappers/body";

//Styling.
import stylesheet from "./stylesheets/stats";

/*
    title: (string),
    stats: [{
        left: (component),
        right: (component),
    }],
*/

const Component = ({ title, stats, }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme);
    return (
        <CardTemplate>
            <CardHeaderTitle
                text={ title }
                backgroundColour={ theme.colour.primary }
                textColour={ theme.colour.primaryText }
            />
            <WrapperBody>
                { stats.map((stat, index) => (
                    <View
                        key={ index }
                        style={ styles.row }
                    >
                        <View style={ styles.itemContainer }>
                            { stat.left }
                        </View>
                        <View style={ styles.itemContainer }>
                            { stat.right }
                        </View>
                    </View>
                ))}
            </WrapperBody>
        </CardTemplate>
    );
}

//Exports.
export default Component;
