
import event from "./event";
import notification from "./notification";
import user from "./user";

const translations = {
    event: event,
    notification: notification,
    user: user,
};

export default translations;
