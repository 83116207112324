
import { StyleSheet } from "react-native";

const stylesheet = (theme, backgroundColour, textColour) => {
    return StyleSheet.create({
        container: {
            backgroundColor: backgroundColour,
            padding: theme.spacing.medium,
            borderRadius: theme.border.radius.small,

            //Shadow.
            shadowColor: theme.colour.shadow,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: theme.opacity.shadow,
            shadowRadius: 5,
        },
        label: {
            color: textColour,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
            textAlign: "center",
        },
    });
}

//Exports.
export default stylesheet;
