
const translations = {
    title: "Profile",

    addFriend: "Add friend",
    chat: "Chat",

    profileStatsTitle: "Profile",
    gender: "Gender",
    male: "Male",
    female: "Female",
    age: "Age",

    report: "Report",

    userFetchFailedTitle: "Failure",
    userFetchFailedText: "The user could not be loaded.",

    userImageFetchFailedTitle: "Failure",
    userImageFetchFailedText: "The user's image could not be loaded.",

    userImageSaveFailedTitle: "Failure",
    userImageSaveFailedText: "Image could not be saved.",

    userImageSaveSuccessText: "Image updated",
};

export default translations;
