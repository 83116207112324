
//Framework.
import React from "react";
import { View, Text, Pressable, Animated, Easing } from "react-native";
import { debounce } from "lodash";

//Constants.
import { DEBOUNCE_DURATION_MS } from "@constants/timing";

//Context.
import ThemeContext from "@contexts/theme";
import MediaContext from "@contexts/media";

//Components.
import CounterBubble, { getMaxValue as getCounterBubbleMaxValue } from "@components/misc/counterBubble";
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/socialNav";

//Constants.
const ICON_SIZE_MOBILE = 20;
const ICON_SIZE_TABLET = 30;
const ICON_SIZE_DESKTOP = 50;
const ANIMATION_DISABLE_DURATION_MS = 300;
const ANIMATION_PRESS_DURATION_MS = 300;

const Component = ({ icon:iconIn, label:labelIn, onPress:onPressIn, count:countIn, }) => {
    const theme = React.useContext(ThemeContext);
    const media = React.useContext(MediaContext);

    //Animation.
    const animations = {
        press: React.useRef(new Animated.Value(0)).current,
    };

    //Methods.
    const onPressDebounce = React.useCallback(
        debounce((method) => method(), DEBOUNCE_DURATION_MS, {
            leading: true,
            trailing: false,
        }),
    []);
    const onPress = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.press, {
            toValue: 1,
            duration: theme.animations.bounce.duration,
            easing: Easing.elastic(theme.animations.bounce.easing),
            useNativeDriver: true,
        }).start(() => animations.press.setValue(0));
        //Fire callback.
        onPressDebounce(() => onPressIn());
    }, [ onPressIn ]);

    //Return.
    const styles = stylesheet(theme, animations);
    return (
        <Animated.View style={ styles.animationWrapper }>
            <Pressable
                style={ styles.container }
                onPress={ onPress }
            >
                <View style={ styles.iconContainer }>
                    <Icon
                        source={ iconIn.source }
                        name={ iconIn.name }
                        colour={ theme.colour.primaryText }
                        size={
                            media.break === media.breaks.mobile ? ICON_SIZE_MOBILE : media.break === media.breaks.tablet ? ICON_SIZE_TABLET : ICON_SIZE_DESKTOP
                        }
                    />
                </View>
                <Text
                    style={ styles.label }
                    selectable={ false }
                    numberOfLines={ 1 }
                >
                    { labelIn }
                </Text>
                <View style={ styles.countContainer }>
                    <CounterBubble
                        value={ Math.min(countIn || 0, getCounterBubbleMaxValue(theme)) }
                        visible={ countIn }
                        invert={ true }
                    />
                </View>
            </Pressable>
        </Animated.View>
    );
}

const getHeight = (theme, media) => {
    const iconSize = media.break === media.breaks.mobile ? ICON_SIZE_MOBILE : media.break === media.breaks.tablet ? ICON_SIZE_TABLET : ICON_SIZE_DESKTOP;
    return iconSize + theme.spacing.large + theme.fonts.text.lineHeights.medium + 3 * theme.spacing.medium;
};

//Exports.
export default Component;
export { getHeight }
