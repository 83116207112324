
import animations from "./animations";
import colour from "./colour";
import fonts from "./fonts";
import spacing from "./spacing";
import border from "./border";
import opacity from "./opacity";

export default {
    animations: animations,
    colour: colour,
    fonts: fonts,
    spacing: spacing,
    border: border,
    opacity: opacity,
};
