
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        backdrop: {
            backgroundColor: theme.colour.backdrop,
            opacity: animations.opacity,
            position: "absolute",
            width:"100%",
            height:"100%",
        },
        indicator: {
            width:"100%",
            height:"100%",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
        },
    });
};

//Exports.
export default stylesheet;
