
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <ScreenWrapper>
            <View style={ styles.container }>
                <Text
                    style={ styles.label }
                >
                    Friends
                </Text>
            </View>
        </ScreenWrapper>
    );
}

//Exports.
export default Component;
