
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {

    const height = theme.fonts.text.lineHeights.medium + theme.spacing.medium;

    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
        },
        wrapper: {
            height: height,
            borderRadius: height / 2,
            padding: theme.spacing.medium / 2,
            overflow: "hidden",
        },
        label: {
            color: theme.colour.text,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
        selectedOverlay: {
            opacity: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 0, 0.5 ],
            }),
            backgroundColor: theme.colour.success,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    });
}

//Exports.
export default stylesheet;
