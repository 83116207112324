
//Framework.
import React from "react";
import { View } from "react-native";

//Constants.
import USER_MODEL from "@constants/models/user";
import { BLACKLISTED_WORDS } from "@constants/validation";

//Helpers.
import validate from "@helpers/validate";
import { unixToDate } from "@helpers/time";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import AuthenticationContext from "@contexts/authentication";

//Components.
import CardUnauthenticated from "@components/containers/cards/unauthenticated/def";
import ButtonUnauthenticated from "@components/buttons/unauthenticated";
import TextError from "@components/text/error";
import InputPhoneNumber from "@components/inputs/phoneNumber/unauthenticated";
import InputText from "@components/inputs/text/unauthenticated";
import InputPassword from "@components/inputs/password/unauthenticated";
import InputDatetime from "@components/inputs/datetime/unauthenticated";
import InputGender from "@components/inputs/gender/unauthenticated";

import WrapperModal from "@components/containers/wrappers/modal";
import CardServerError from "@components/containers/cards/def/serverError";
import CardConnectionError from "@components/containers/cards/def/connectionError";
import CardUserError from "@components/containers/cards/def/userError";

//Children.
import VerifyPhoneNumberModal from "./verifyPhoneNumberModal";
import SuccessModal from "./successModal";
import VerifyEmailModal from "./verifyEmailModal";

//Styling.
import stylesheet from "./stylesheets/main";

const Component = ({ enabled, onGoToSignIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const authentication = React.useContext(AuthenticationContext);

    //Validation.
    const check = React.useCallback((phoneNumberPrefix, phoneNumber, password, passwordConfirmation, firstName, lastName, birthdate, gender) => {
        const validations = {
            //Phone number prefix.
            phoneNumberPrefix: validate(phoneNumberPrefix, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.phoneNumberPrefix.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                    value: USER_MODEL.phoneNumberPrefix.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.phoneNumberPrefix.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                    value: USER_MODEL.phoneNumberPrefix.maxLength,
                }),
            }, {
                type: "isPhoneNumberPrefix",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                }),
            }]),
            //Phone number.
            phoneNumber: validate(phoneNumber, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.phoneNumber.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                    value: USER_MODEL.phoneNumber.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.phoneNumber.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                    value: USER_MODEL.phoneNumber.maxLength,
                }),
            }, {
                type: "isPhoneNumber",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                }),
            }]),
            //Password.
            password: validate(password, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.password.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.password.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.password.validation"),
                    value: USER_MODEL.password.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.password.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.password.validation"),
                    value: USER_MODEL.password.maxLength,
                }),
            }]),
            //Password confirmation.
            passwordConfirmation: validate(passwordConfirmation, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.passwordConfirmation.validation"),
                }),
            }, {
                type: "match",
                value: password,
                message: localisation.t("validation.notMatching", {
                    field: localisation.t("models.user.passwordConfirmation.validation"),
                    target: localisation.t("models.user.password.validationTarget"),
                }),
            }]),
            //First name.
            firstName: validate(firstName, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.firstName.validation"),
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.firstName.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.firstName.validation"),
                    value: USER_MODEL.firstName.maxLength,
                }),
            }, {
                type: "blacklist",
                value: BLACKLISTED_WORDS,
                message: localisation.t("validation.containsForbiddenWord", {
                    field: localisation.t("models.user.firstName.validation"),
                }),
            }]),
            //Last name.
            lastName: validate(lastName, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.lastName.validation"),
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.lastName.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.lastName.validation"),
                    value: USER_MODEL.lastName.maxLength,
                }),
            }, {
                type: "blacklist",
                value: BLACKLISTED_WORDS,
                message: localisation.t("validation.containsForbiddenWord", {
                    field: localisation.t("models.user.lastName.validation"),
                }),
            }]),
            //birthdate.
            birthdate: validate(birthdate, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.birthdate.validation"),
                }),
            }, {
                type: "isInteger",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.birthdate.validation"),
                }),
            }, {
                type: "min",
                value: USER_MODEL.birthdate.getEarliest(),
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.birthdate.validation"),
                }),
            }, {
                type: "max",
                value: USER_MODEL.birthdate.getLatest(),
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.birthdate.validation"),
                }),
            }/* Do something */]),
            //Gender.
            gender: validate(gender, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.gender.validation"),
                }),
            }, {
                type: "choices",
                value: USER_MODEL.gender.choices,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.gender.validation"),
                }),
            }]),
        };
        //Return.
        return {
            isValid:
                validations.phoneNumberPrefix.isValid &&
                validations.phoneNumber.isValid &&
                validations.password.isValid &&
                validations.passwordConfirmation.isValid &&
                validations.firstName.isValid &&
                validations.lastName.isValid &&
                validations.birthdate.isValid &&
                validations.gender.isValid,
            errors: {
                phoneNumberPrefix: validations.phoneNumberPrefix.isValid ? null : validations.phoneNumberPrefix.errors[0],
                phoneNumber: validations.phoneNumber.isValid ? null : validations.phoneNumber.errors[0],
                password: validations.password.isValid ? null : validations.password.errors[0],
                passwordConfirmation: validations.passwordConfirmation.isValid ? null : validations.passwordConfirmation.errors[0],
                firstName: validations.firstName.isValid ? null : validations.firstName.errors[0],
                lastName: validations.lastName.isValid ? null : validations.lastName.errors[0],
                birthdate: validations.birthdate.isValid ? null : validations.birthdate.errors[0],
                gender: validations.gender.isValid ? null : validations.gender.errors[0],
            },
        };
    }, []);

    //Form.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        let newState = { ...oldState };
        switch(action.type) {
            case("handshake"): return { ...oldState, toBeSubmitted:false };
            case("submit"):
                newState.isSubmitted = true;
                //Validate.
                const { isValid, errors } = check(
                    oldState.phoneNumberPrefix.value,
                    oldState.phoneNumber.value,
                    oldState.password.value,
                    oldState.passwordConfirmation.value,
                    oldState.firstName.value,
                    oldState.lastName.value,
                    oldState.birthdate.value,
                    oldState.gender.value
                );
                newState.phoneNumberPrefix.error = errors.phoneNumberPrefix;
                newState.phoneNumber.error = errors.phoneNumber;
                newState.password.error = errors.password;
                newState.passwordConfirmation.error = errors.passwordConfirmation;
                newState.firstName.error = errors.firstName;
                newState.lastName.error = errors.lastName;
                newState.birthdate.error = errors.birthdate;
                newState.gender.error = errors.gender;
                //Flag for submit.
                newState.toBeSubmitted = isValid;
                //Return.
                return newState;
            case("onChange"):
                //Assign new value.
                switch(action.input) {
                    case("phoneNumber"):
                        newState.phoneNumberPrefix.value = action.value.prefix;
                        newState.phoneNumber.value = action.value.number;
                        break;
                    case("password"):
                        newState.password.value = action.value;
                        break;
                    case("passwordConfirmation"):
                        newState.passwordConfirmation.value = action.value;
                        break;
                    case("firstName"):
                        newState.firstName.value = action.value;
                        break;
                    case("lastName"):
                        newState.lastName.value = action.value;
                        break;
                    case("birthdate"):
                        newState.birthdate.value = action.value;
                        break;
                    case("gender"):
                        newState.gender.value = action.value;
                        break;
                }
                //Validate.
                if (oldState.isSubmitted) {
                    const { isValid, errors } = check(
                        newState.phoneNumberPrefix.value,
                        newState.phoneNumber.value,
                        newState.password.value,
                        newState.passwordConfirmation.value,
                        newState.firstName.value,
                        newState.lastName.value,
                        newState.birthdate.value,
                        newState.gender.value
                    );
                    newState.phoneNumberPrefix.error = errors.phoneNumberPrefix;
                    newState.phoneNumber.error = errors.phoneNumber;
                    newState.password.error = errors.password;
                    newState.passwordConfirmation.error = errors.passwordConfirmation;
                    newState.firstName.error = errors.firstName;
                    newState.lastName.error = errors.lastName;
                    newState.birthdate.error = errors.birthdate;
                    newState.gender.error = errors.gender;
                }
                //Return.
                return newState;
        }
    }, {
        phoneNumberPrefix: {
            value: "+45",
            error: null,
        },
        phoneNumber: {
            value: "1122531",//"",
            error: null,
        },
        password: {
            value: "PooPoo55",//"",
            error: null,
        },
        passwordConfirmation: {
            value: "PooPoo55",//"",
            error: null,
        },
        firstName: {
            value: "first",//"",
            error: null,
        },
        lastName: {
            value: "last",//"",
            error: null,
        },
        birthdate: {
            value: USER_MODEL.birthdate.getLatest(),
            error: null,
        },
        gender: {
            value: "m",
            error: null,
        },
        isSubmitted: false,
        toBeSubmitted: false,
    });
    React.useEffect(() => {
        if (state.toBeSubmitted) {
            //Create responses.
            const close = () => modal.close();
            const onSuccess = () => showVerifyPhoneNumberModal(state.phoneNumberPrefix.value, state.phoneNumber.value);
            const onUserError = async (response) => {
                const result = await response.json();
                showUserErrorModal(
                    localisation.t("screens.unauthenticated.signUpFailedTitle"),
                    localisation.t("screens.unauthenticated.signUpFailedText"),
                    result,
                    close
                );
            };
            const onServerError = () => showServerErrorModal(close);
            const onConnectionError = () => showConnectionErrorModal(close);
            //Attempt to register.
            authentication.register(
                state.phoneNumberPrefix.value,
                state.phoneNumber.value,
                state.password.value,
                state.firstName.value,
                state.lastName.value,
                unixToDate(state.birthdate.value),
                state.gender.value,
                onSuccess,
                onServerError,
                onUserError,
                onConnectionError
            );
            //Enable other submits.
            dispatch({ type:"handshake" });
        }
    }, [ state.toBeSubmitted ]);

    //Methods.
    const onSubmitPress = React.useCallback(() => dispatch({ type:"submit" }), []);
    const onChangePhoneNumber = React.useCallback((value) => dispatch({ type:"onChange", input:"phoneNumber", value:{ prefix:value.prefix, number:value.number }}), []);
    const onChangePassword = React.useCallback((value) => dispatch({ type:"onChange", input:"password", value:value }), []);
    const onChangePasswordConfirmation = React.useCallback((value) => dispatch({ type:"onChange", input:"passwordConfirmation", value:value }), []);
    const onChangeFirstName = React.useCallback((value) => dispatch({ type:"onChange", input:"firstName", value:value }), []);
    const onChangeLastName = React.useCallback((value) => dispatch({ type:"onChange", input:"lastName", value:value }), []);
    const onChangeBirthdate = React.useCallback((value) => dispatch({ type:"onChange", input:"birthdate", value:value }), []);
    const onChangeGender = React.useCallback((value) => dispatch({ type:"onChange", input:"gender", value:value }), []);
    //const showSuccessModal = React.useCallback(() => {}, []);

    //Show modals methods.
    const showServerErrorModal = React.useCallback((onClose) => {
        modal.fire({
            onRequestClose: onClose,
            content: (
                <WrapperModal size="small">
                    <CardServerError
                        onClose={ onClose }
                    />
                </WrapperModal>
            ),
        });
    }, []);
    const showConnectionErrorModal = React.useCallback((onClose) => {
        modal.fire({
            onRequestClose: onClose,
            content: (
                <WrapperModal size="small">
                    <CardConnectionError
                        onClose={ onClose }
                    />
                </WrapperModal>
            ),
        });
    }, []);
    const showUserErrorModal = React.useCallback((title, text, errors, onClose) => {
        modal.fire({
            onRequestClose: onClose,
            content: (
                <WrapperModal size="small">
                    <CardUserError
                        title={ title }
                        text={ text }
                        errors={ errors }
                        onClose={ onClose }
                    />
                </WrapperModal>
            ),
        });
    }, []);
    const showVerifyPhoneNumberModal = React.useCallback((prefix, number) => {
        const close = () => modal.close();
        const submit = (digits) => modal.close(() => {
            dispatch({ type:"crash" });
        });
        modal.fire({
            onRequestClose: close,
            content: (
                <WrapperModal size="small">
                    <VerifyPhoneNumberModal
                        onSubmit={ submit }
                        onClose={ close }
                    />
                </WrapperModal>
            ),
        });
    }, []);
    const showSuccessModal = React.useCallback((token) => {
        const close = () => modal.close();
        modal.fire({
            onRequestClose: close,
            content: (
                <WrapperModal size="small">
                </WrapperModal>
            ),
        });
    }, []);
    const showVerifyEmailModal = React.useCallback((token) => {
        const close = () => modal.close();
        modal.fire({
            onRequestClose: close,
            content: (
                <WrapperModal size="small">
                </WrapperModal>
            ),
        });
    }, []);
    
    //Return.
    const styles = stylesheet(theme);
    return (
        <CardUnauthenticated
            title={ localisation.t("screens.unauthenticated.signUpTitle") }
        >
            <View style={ styles.container }>
                {/* Phone number. */}
                <View style={ styles.padder }>
                    <InputPhoneNumber
                        label={ localisation.t("models.user.phoneNumber.label") }
                        value={{
                            prefix: state.phoneNumberPrefix.value,
                            number: state.phoneNumber.value,
                        }}
                        disabled={ !enabled }
                        onChange={ onChangePhoneNumber }
                        error={ state.phoneNumberPrefix.error || state.phoneNumber.error }
                    />
                </View>
                {/* Password. */}
                <View style={ styles.padder }>
                    <InputPassword
                        label={ localisation.t("models.user.password.label") }
                        value={ state.password.value }
                        disabled={ !enabled }
                        onChange={ onChangePassword }
                        error={ state.password.error }
                    />
                </View>
                {/* Password confirmation. */}
                <View style={ styles.padder }>
                    <InputPassword
                        label={ localisation.t("models.user.passwordConfirmation.label") }
                        value={ state.passwordConfirmation.value }
                        disabled={ !enabled }
                        onChange={ onChangePasswordConfirmation }
                        error={ state.passwordConfirmation.error }
                    />
                </View>
                {/* First name. */}
                <View style={ styles.padder }>
                    <InputText
                        label={ localisation.t("models.user.firstName.label") }
                        value={ state.firstName.value }
                        onChange={ onChangeFirstName }
                        maxLength={ USER_MODEL.firstName.maxLength }
                        error={ state.firstName.error }
                    />
                </View>
                {/* Last name. */}
                <View style={ styles.padder }>
                    <InputText
                        label={ localisation.t("models.user.lastName.label") }
                        value={ state.lastName.value }
                        onChange={ onChangeLastName }
                        maxLength={ USER_MODEL.lastName.maxLength }
                        error={ state.lastName.error }
                    />
                </View>
                {/* Birthdate. */}
                <View style={ styles.padder }>
                    <InputDatetime
                        label={ localisation.t("models.user.birthdate.label") }
                        value={ state.birthdate.value }
                        onChange={ onChangeBirthdate }
                        useDate={ true }
                        useTime={ false }
                        max={ USER_MODEL.birthdate.getLatest() }
                        min={ USER_MODEL.birthdate.getEarliest() }
                        onOpen={ (open) => open() }
                        error={ state.birthdate.error }
                    />
                </View>
                {/* Gender. */}
                <View style={ styles.padder }>
                    <InputGender
                        value={ state.gender.value }
                        onChange={ onChangeGender }
                    />
                </View>

                {/* Buttons. */}
                <View style={ styles.padder }>
                    <ButtonUnauthenticated
                        label={ localisation.t("screens.unauthenticated.signUpLabel") }
                        disabled={ !enabled }
                        backgroundColour={ theme.colour.primary }
                        textColour={ theme.colour.primaryText }
                        onPress={ onSubmitPress }
                    />
                </View>
                <View style={ styles.padder }>
                    <ButtonUnauthenticated
                        label={ localisation.t("screens.unauthenticated.signInLabel") }
                        disabled={ !enabled }
                        backgroundColour={ theme.colour.secondary }
                        textColour={ theme.colour.secondaryText }
                        onPress={ onGoToSignIn }
                    />
                </View>
            </View>
        </CardUnauthenticated>
    );
}

//Exports.
export default Component;
