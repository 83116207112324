
//Framework.
import React from "react";
import { View, Pressable, ScrollView, Text, Animated } from "react-native";

//Constants.
import EVENT_MODEL from "@constants/models/event";

//Helpers.
import { unixToDatetime } from "@helpers/time/";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import ModalContext from "@contexts/modal";

//Components.
import BodyWrapper from "@components/containers/wrappers/body";
import Icon from "@components/misc/icon";
import TextInput from "@components/inputs/text/def";
import ModalWrapper from "@components/containers/wrappers/modal";
import CardDialogue from "@components/containers/cards/def/dialogue";
import WrapperFade from "@components/containers/wrappers/fade";
import EventTypesSelect from "@components/inputs/select/modals/eventTypes";
import LocationModal from "@components/inputs/location/modals/def";
import DatetimeModal from "@components/inputs/datetime/modal";

//Styling.
import stylesheet from "./stylesheets/step0";

//Constants.
const TYPE_SIZE = 80;

const Component = ({ disabled:disabledIn, state:stateIn, dispatch:dispatchIn, show:showIn, hide:hideIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const modal = React.useContext(ModalContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onChangeTitle = React.useCallback((value) => dispatchIn({ type:"onChange", input:"title", value:value, }), []);
    const onTypePress = React.useCallback(() => {
        hideIn(() => {
            const reject = () => modal.close(() => showIn({}));
            const accept = (value) => modal.close(() => showIn({ type:value }));
            modal.fire({
                onRequestClose: reject,
                content: (
                    <EventTypesSelect
                        value={ stateIn.type.value }
                        onAccept={ accept }
                        onCancel={ reject }
                    />
                ),
            });
        });
    }, []);
    const onLocationPress = React.useCallback(() => {
        hideIn(() => {
            const reject = () => modal.close(() => showIn({}));
            const accept = (value) => modal.close(() => showIn({ address:value.address, latitude:value.latitude, longitude:value.longitude }));
            modal.fire({
                onRequestClose: reject,
                content: (
                    <LocationModal
                        value={{
                            address: stateIn.address.value,
                            latitude: stateIn.latitude.value,
                            longitude: stateIn.longitude.value,
                        }}
                        onAccept={ accept }
                        onCancel={ reject }
                    />
                ),
            });
        });
    }, [ stateIn.address.value, stateIn.latitude.value, stateIn.longitude.value ]);
    const onDatetimePress = React.useCallback(() => {
        hideIn(() => {
            const reject = () => modal.close(() => showIn({}));
            const accept = (value) => modal.close(() => showIn({ datetime:value }));
            modal.fire({
                onRequestClose: reject,
                content: (
                    <DatetimeModal
                        initialValue={ stateIn.datetime.value }
                        useDate={ true }
                        useTime={ true }
                        max={ EVENT_MODEL.datetime.getLatest() }
                        min={ EVENT_MODEL.datetime.getEarliest() }
                        onAccept={ accept }
                        onCancel={ reject }
                    />
                ),
            });
        });
    }, [ stateIn.datetime.value ]);

    //Animations.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);

    //Find selected event type.
    const eventType = React.useMemo(() => {
        return Object.values(EVENT_MODEL.types).find(type => type.id === stateIn.type.value)
    }, [ stateIn.type ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheet(theme, animations, TYPE_SIZE);
    return (
        <BodyWrapper>
            <View style={ styles.container }>

                {/* Title. */}
                <View style={ styles.inputContainer }>
                    <TextInput
                        value={ stateIn.title.value }
                        onChange={ onChangeTitle }
                        icon={{
                            source: "FontAwesome5",
                            name: "pen",
                        }}
                        disabled={ disabledIn }
                        placeholder={ localisation.t("models.event.title.label") }
                        maxLength={ EVENT_MODEL.title.maxLength }
                        inputMode="text"
                        error={ stateIn.title.error }
                    />
                </View>

                {/* Type. */}
                <Animated.View style={ styles.typeContainer }>
                    <Pressable
                        style={ styles.typeCircle }
                        onPress={ onTypePress }
                        disabled={ disabledIn }
                    >
                        <Icon
                            source={ eventType.icon.source }
                            name={ eventType.icon.name }
                            colour={ theme.colour.primary }
                            size={ TYPE_SIZE - 2 * (theme.spacing.medium + theme.border.width.large) }
                        />
                    </Pressable>
                </Animated.View>

                {/* Location. */}
                <Pressable
                    style={ styles.inputContainer }
                    onPress={ onLocationPress }
                >
                    <TextInput
                        value={ stateIn.address.value }
                        icon={{
                            source: "FontAwesome5",
                            name: "map",
                        }}
                        disabled={ disabledIn }
                        editable={ false }
                        placeholder={ localisation.t("models.event.address.label") }
                        error={ stateIn.address.error || stateIn.latitude.error || stateIn.longitude.error }
                    />
                </Pressable>

                {/* Datetime. */}
                <Pressable
                    style={ styles.inputContainer }
                    onPress={ onDatetimePress }
                >
                    <TextInput
                        value={ unixToDatetime(stateIn.datetime.value) }
                        icon={{
                            source: "FontAwesome5",
                            name: "clock",
                        }}
                        disabled={ disabledIn }
                        editable={ false }
                        placeholder={ localisation.t("models.event.datetime.label") }
                        error={ stateIn.datetime.error }
                    />
                </Pressable>

            </View>
        </BodyWrapper>
    );
};

//Exports.
export default Component;
