
//Framework.
import React from "react";
import { StyleSheet, StatusBar, SafeAreaView } from "react-native";
import { useFonts } from "expo-font";

//Navigation.
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

//Screens.
import SplashScreen from "@screens/splash/main";
import UnauthenticatedScreen from "@screens/unauthenticated/main";
import HomeScreen from "@screens/home/main";

//Contexts.
import SessionContext, { Provider as SessionProvider } from "@contexts/session";
import LocalisationContext, { Provider as LocalisationProvider } from "@contexts/localisation";
import ThemeContext, { Provider as ThemeProvider } from "@contexts/theme";
import { Provider as MediaProvider } from "@contexts/media";
import { Provider as LoadingScreenProvider } from "@contexts/loadingScreen";
import { Provider as AlertProvider } from "@contexts/alert";
import { Provider as ModalProvider } from "@contexts/modal";
import AuthenticationContext, { Provider as AuthenticationProvider } from "@contexts/authentication";


/*
import * as Sentry from "sentry-expo";
Sentry.init({
    dsn: "YOUR DSN HERE",
    enableInExpoDevelopment: true,
    debug: __DEV__ ? true : false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
*/

//Navigation.
const Stack = createNativeStackNavigator();

const App = () => {
    const [ fontsLoaded ] = useFonts({
        "sst-light": require("@assets/fonts/sst/SSTLight.TTF"),
        "sst-regular": require("@assets/fonts/sst/SSTMedium.TTF"),
        "sst-bold": require("@assets/fonts/sst/SSTBold.TTF"),
    });

    //Render.
    return fontsLoaded && (
        <SessionProvider>
            <LocalisationProvider>
                <MediaProvider>
                    <ThemeProvider>
                        <LoadingScreenProvider>
                            <AlertProvider>
                                <ModalProvider>
                                    <AuthenticationProvider>
                                        <NavigationContainer>
                                            <RootView/>
                                        </NavigationContainer>
                                    </AuthenticationProvider>
                                </ModalProvider>
                            </AlertProvider>
                        </LoadingScreenProvider>
                    </ThemeProvider>
                </MediaProvider>
            </LocalisationProvider>
        </SessionProvider>
    );
}

const RootView = ({}) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const authentication = React.useContext(AuthenticationContext);
    const session = React.useContext(SessionContext);
  
    //Run bootstrap.
    React.useEffect(function() {
        (async () => {
            //await session.loadFilters(),
            //Attempt to restore previous user.
            await authentication.restore();
        })();
    }, []);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <SafeAreaView style={ styles.container }>
            <StatusBar
                animated={ true }
                backgroundColor={ theme.colour.primary }
                barStyle={ "light-content" }
            />
            <Stack.Navigator>
                { !authentication.isInitialised ? (
                    <Stack.Screen
                        name = "splash"
                        component = { SplashScreen }
                        options = {{
                            title: localisation.t("common.navTitle", { title:localisation.t("screens.splash.title") }),
                            headerShown: false,
                        }}
                    />
                ) : !authentication.isAuthenticated ? (
                    <Stack.Screen
                        name = "unauthenticated"
                        component = { UnauthenticatedScreen }
                        options = {{
                            title: localisation.t("common.navTitle", { title:localisation.t("screens.unauthenticated.title") }),
                            headerShown: false,
                        }}
                    />
                ) : (
                    <Stack.Screen
                        name = "Home"
                        component = { HomeScreen }
                        options = {{
                            title: localisation.t("common.navTitle", { title:localisation.t("screens.home.title") }),
                            headerShown: false,
                        }}
                    />
                )}
            </Stack.Navigator>
        </SafeAreaView>
    );
}

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: theme.colour.background,
        },
    });
}

//Exports.
export default App;
