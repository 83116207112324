
const disabled = 0.6;
const shadow = 0.3;
const faded = 0.5;

//Specific.
const searchField = 0.4;
const notifications = {
    seen: 0.85,
    unseen: 1,
};

//Exports.
export default {
    disabled,
    faded,
    shadow,
    searchField,
    notifications,
};
