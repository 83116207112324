
//Framework.
import React from "react";
import { View, Pressable, Text, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
//import WeekdayDef from "./weekdays/def";

//Styling.
import stylesheetComponent from "./stylesheets/def/component";
import stylesheetWeekday from "./stylesheets/def/weekday";

/*
    value: {
        monday: (bool),
        monday: (bool),
        wednesday: (bool),
        thursday: (bool),
        friday: (bool),
        saturday: (bool),
        sunday: (bool),
    },
    onChange: (method),
    disabled: (bool),
*/

const Component = ({ value:valueIn, onChange:onChangeIn, disabled:disabledIn, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Methods.
    const onWeekdayPress = React.useCallback((weekdayNum) => {
        switch(weekdayNum) {
            case(0):
                onChangeIn({
                    ...valueIn,
                    monday: !valueIn.monday,
                });
                break;
            case(1):
                onChangeIn({
                    ...valueIn,
                    tuesday: !valueIn.tuesday,
                });
                break;
            case(2):
                onChangeIn({
                    ...valueIn,
                    wednesday: !valueIn.wednesday,
                });
                break;
            case(3):
                onChangeIn({
                    ...valueIn,
                    thursday: !valueIn.thursday,
                });
                break;
            case(4): onChangeIn({
                ...valueIn,
                friday: !valueIn.friday,
            });
            break;
            case(5):
                onChangeIn({
                    ...valueIn,
                    saturday: !valueIn.saturday,
                });
                break;
            case(6):
                onChangeIn({
                    ...valueIn,
                    sunday: !valueIn.sunday,
                });
                break;
        }
    }, [ valueIn, onChangeIn ]);

    //Render.
    const styles = stylesheetComponent(theme);
    return (
        <View style={ styles.container }>
            {/* Monday. */}
            <Weekday
                onPress={ () => onWeekdayPress(0) }
                label={ localisation.t("datetime.weekdays.monday.single") }
                selected={ valueIn.monday }
                disabled={ disabledIn }
            />
            {/* Tuesday. */}
            <Weekday
                onPress={ () => onWeekdayPress(1) }
                label={ localisation.t("datetime.weekdays.tuesday.single") }
                selected={ valueIn.tuesday }
                disabled={ disabledIn }
            />
            {/* Wednesday. */}
            <Weekday
                onPress={ () => onWeekdayPress(2) }
                label={ localisation.t("datetime.weekdays.wednesday.single") }
                selected={ valueIn.wednesday }
                disabled={ disabledIn }
            />
            {/* Thursday. */}
            <Weekday
                onPress={ () => onWeekdayPress(3) }
                label={ localisation.t("datetime.weekdays.thursday.single") }
                selected={ valueIn.thursday }
                disabled={ disabledIn }
            />
            {/* Friday. */}
            <Weekday
                onPress={ () => onWeekdayPress(4) }
                label={ localisation.t("datetime.weekdays.friday.single") }
                selected={ valueIn.friday }
                disabled={ disabledIn }
            />
            {/* Saturday. */}
            <Weekday
                onPress={ () => onWeekdayPress(5) }
                label={ localisation.t("datetime.weekdays.saturday.single") }
                selected={ valueIn.saturday }
                disabled={ disabledIn }
            />
            {/* Sunday. */}
            <Weekday
                onPress={ () => onWeekdayPress(6) }
                label={ localisation.t("datetime.weekdays.sunday.single") }
                selected={ valueIn.sunday }
                disabled={ disabledIn }
            />
        </View>
    );
}

const Weekday = ({ onPress:onPressIn, label:labelIn, selected:selectedIn, disabled:disabledIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(0)).current,
        select: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.disable, {
                toValue: disabledIn ? 1 : 0,
                duration: theme.animations.disable.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ disabledIn ]);
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.select, {
                toValue: selectedIn ? 1 : 0,
                duration: theme.animations.select.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ selectedIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetWeekday(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPressIn }
            disabled={ disabledIn }
        >
            <Animated.Text style={ styles.label }>
                { labelIn }
            </Animated.Text>
        </Pressable>
    );
};

//Exports.
export default Component;
