
//Framework.
import React from "react";
import { View, ActivityIndicator, Modal, Animated, } from "react-native";

//Contexts.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/loadingScreen";

//Constants.
const ANIMATION_DURATION_MS = 200;

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isBlocking, setIsBlocking ] = React.useState(false);

    //Methods.
    const addToQueue = React.useCallback((method) => {
        dispatch({ type:"queue", method:method });
    }, []);
    const finish = React.useCallback(() => {
        dispatch({ type:"next" });
    }, []);

    //Reducer.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        switch(action.type) {
            case("queue"):
                if (!oldState.isLoading) action.method(finish);
                return {
                    isLoading: true,
                    queue: oldState.isLoading ? oldState.queue.concat(action.method) : oldState.queue,
                };
            case("next"):
                if (oldState.queue.length > 0) oldState.queue.slice(0, 1)[0](finish);
                return {
                    isLoading: oldState.queue.length > 0 ? true : false,
                    queue: oldState.queue.slice(1),
                };
        }
    }, {
        isLoading: false,
        queue: [],
    });

    //Animation.
    const animations = {
        opacity: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (state.isLoading && !isBlocking) {
            setIsBlocking(true);
            Animated.timing(animations.opacity, {
                toValue: 1,
                duration: ANIMATION_DURATION_MS,
                useNativeDriver: true,
            }).start();
        } else if (!state.isLoading) {
            Animated.timing(animations.opacity, {
                toValue: 0,
                duration: ANIMATION_DURATION_MS,
                useNativeDriver: true,
            }).start(() => {
                if (isBlocking) {
                    setIsBlocking(false);
                }
            });
        }
    }, [ state.isLoading, isBlocking ]);
    
    //Create value.
    const value = React.useMemo(() => ({
        queue: addToQueue,
        isLoading: state.isLoading,
    }), [ state.isLoading ]);
    global.loadingScreen = value;
    
    //Render.
    const styles = stylesheet(theme, animations, isBlocking);
    return (
        <Context.Provider value={ value }>
            { children }
            <Modal
                visible={ isBlocking }
                animationType="none"
                transparent={ true }
                onRequestClose={ null }
            >
                <Animated.View
                    style={ styles.backdrop }
                >
                    <ActivityIndicator
                        style={ styles.indicator }
                        size={ "large" }
                        color={ theme.colour.primary }
                    />
                </Animated.View>
            </Modal>
        </Context.Provider>
    );
}

//Exports.
export default Context;
export { Provider };
