
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        body: {
            padding: theme.spacing.medium / 2,
        },
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        itemContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
