
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Image } from "react-native";

//Constants.
import USER from "@constants/models/user";
import PHONE_NUMBER_PREFIXES from "@constants/phoneNumberPrefixes";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import ModalContext from "@contexts/modal";

//Components.
import Template from "@components/inputs/templates/unauthenticated";
//import SelectDef from "@components/inputs/select/def/def";
import WrapperModal from "@components/containers/wrappers/modal";
import WrapperBody from "@components/containers/wrappers/body";
import WrapperFade from "@components/containers/wrappers/fade";
import CardTemplate from "@components/containers/cards/def/template";
import CardFooterIcons, { HEIGHT as FOOTER_HEIGHT } from "@components/containers/cards/def/footers/icons";

import Modal from "./modal";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    value: (string),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) returnKeyType: (string: "done"),
    (Optional) onChange: (method),
    (Optional) onBlur: (method),
    (Optional) onShowModal: (method),
    (Optional) onHideModal: (method),
    (Optional) error: (string),
*/

//Constants.
const PREFIX_WIDTH = 70;

const Component = ({
    value: valueIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    returnKeyType: returnKeyTypeIn = "done",
    onChange: onChangeIn,
    onBlur: onBlurIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const modal = React.useContext(ModalContext);

    //Methods.
    const onChangePrefix = React.useCallback((value) => {
        modal.close();
        onChangeIn({
            prefix: value,
            number: valueIn.number,
        });
    }, [ onChangeIn, valueIn ]);
    const onChangeNumber = React.useCallback((value) => {
        onChangeIn({
            prefix: valueIn.prefix,
            number: value,
        })
    }, [ onChangeIn, valueIn ]);
    const onPrefixPress = React.useCallback(() => {
        const close = () => modal.close();
        modal.fire({
            onRequestClose: close,
            content: (
                <Modal
                    value={ valueIn.prefix }
                    onAccept={ onChangePrefix }
                    onCancel={ close }
                />
            )
        });
    });

    //Find selected prefix.
    const selectedPrefix = React.useMemo(() => PHONE_NUMBER_PREFIXES.find(prefix => prefix.value === valueIn.prefix), [ valueIn.prefix ]);
    
    //Render.
    const styles = stylesheet(theme, PREFIX_WIDTH);
    return (
        <Template
            label={ localisation.t("models.user.phoneNumber.label") }
            value={ valueIn.number }
            disabled={ disabledIn }
            editable={ editableIn }
            maxLength={ USER.phoneNumber.maxLength }
            inputMode="numeric"
            textContentType="telephoneNumber"
            returnKeyType={ returnKeyTypeIn }
            onChange={ onChangeNumber }
            onBlur={ onBlurIn }
            left={(
                <Pressable
                    style={ styles.prefixButton }
                    disabled={ disabledIn }
                    onPress={ onPrefixPress }
                >
                    <View style={ styles.prefixItemContainer }>
                        <Image
                            style={ styles.prefixImage }
                            resizeMode="contain"
                            source={ selectedPrefix.flag.small }
                        />
                    </View>
                    <View style={ styles.prefixItemContainer }>
                        <Text
                            style={ styles.prefixText }
                            selectable={ false }
                        >
                            { valueIn.prefix }
                        </Text>
                    </View>
                </Pressable>
            )}
            pad={{
                right: PREFIX_WIDTH,
            }}
            error={ errorIn }
        />
    );
}

//Exports.
export default Component;
