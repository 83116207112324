
import text from "./text/main";
import gender from "./gender/main";
import datetime from "./datetime/main";

const translations = {
    text: text,
    gender: gender,
    datetime: datetime,
};

export default translations;
