
//Framework.
import React from "react";
import { ScrollView, View, Text, Pressable } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";
import SessionContext from "@contexts/session";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import OutlinedButton from "@components/buttons/outlined";
import CounterBubble, { getMaxValue as getCounterBubbleMaxValue } from "@components/misc/counterBubble";
import MinifiedEvent from "@components/misc/events/minified";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);
    const session = React.useContext(SessionContext);

    crash();

    //Methods.
    const onInvitationsPress = React.useCallback(() => {
        console.log("In onInvitesPress");
    }, []);
    const onInterestedPress = React.useCallback(() => {
        console.log("In onInterestedPress");
    }, []);
    const onEventPress = React.useCallback((id) => {
        console.log({ where:"In onEventPress", id });
    }, []);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <ScrollView contentContainerStyle={ styles.scrollContainer }>
            <ScreenWrapper>
                <View style={ styles.container }>

                    {/* Nav buttons. */}
                    <View style={ styles.navButtonsContainer }>
                        <View style={ styles.navButtonContainer }>
                            <OutlinedButton
                                icon={{
                                    source: "FontAwesome5",
                                    name: "envelope",
                                    colour: theme.colour.text,
                                }}
                                size="large"
                                label={ localisation.t("screens.home.events.invitations") }
                                onPress={ onInvitationsPress }
                            />
                            <View style={ styles.navButtonCounterContainer }>
                                <CounterBubble
                                    value={ session.user.receivedEventInvitations.length }
                                    visible={ session.user.receivedEventInvitations.length > 0 }
                                />
                            </View>
                        </View>
                        <View style={ styles.navButtonContainer }>
                            <OutlinedButton
                                icon={{
                                    source: "FontAwesome5",
                                    name: "lightbulb",
                                    colour: theme.colour.gold,
                                }}
                                size="large"
                                label={ localisation.t("screens.home.events.interested") }
                                onPress={ onInterestedPress }
                            />
                            <View style={ styles.navButtonCounterContainer }>
                                <CounterBubble
                                    value={ session.user.interestedEvents.length }
                                    visible={ session.user.interestedEvents.length > 0 }
                                />
                            </View>
                        </View>
                    </View>

                    {/* Events. */}
                    <View style={ styles.eventsContainer }>
                        { session.user.eventRoles.map((role, index) => (
                            <Pressable
                                key={ role.event.id }
                                style={ styles.eventContainer }
                                onPress={ () => onEventPress(role.event.id) }
                            >
                                {/*
                                <MinifiedEvent
                                    event={ role.event }
                                />
                                */}
                                <Text>{ role.event.title }</Text>
                            </Pressable>
                        ))}
                    </View>
                </View>
            </ScreenWrapper>
        </ScrollView>
    );
}

//Exports.
export default Component;
