
//Framework.
import React from "react";
import { ScrollView, View, Text, Pressable, Image } from "react-native";

//Helpers.
import { getAge } from "@helpers/time";
import { send } from "@helpers/connection";
import { formatFullName } from "@helpers/formatting";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import SessionContext from "@contexts/session";
import ModalContext from "@contexts/modal";
import LoadingScreenContext from "@contexts/loadingScreen";
import MediaContext from "@contexts/media";
import AlertContext from "@contexts/alert";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import DefButton from "@components/buttons/def";
import UserImage from "@components/images/user";
import StatsCard from "@components/containers/cards/def/stats";
import Stars from "@components/misc/stars";
import DefAlert from "@components/misc/alerts/def";

import WrapperModal from "@components/containers/wrappers/modal";
import CardServerError from "@components/containers/cards/def/serverError";
import CardConnectionError from "@components/containers/cards/def/connectionError";
import CardUserError from "@components/containers/cards/def/userError";

//Children.
import ImageModal from "./imageModal";
import SendFriendRequestModal from "./sendFriendRequestModal";
import ReportModal from "./reportModal";

//Styling.
import stylesheet from "./stylesheets/main";

//Constants.
const IMAGE_SIZE_MOBILE = 100;
const IMAGE_SIZE_TABLET = 150;
const IMAGE_SIZE_DESKTOP = 225;
const RATING_SIZE_MOBILE = 30;
const RATING_SIZE_TABLET = 40;
const RATING_SIZE_DESKTOP = 50;

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const session = React.useContext(SessionContext);
    const modal = React.useContext(ModalContext);
    const loadingScreen = React.useContext(LoadingScreenContext);
    const media = React.useContext(MediaContext);
    const alert = React.useContext(AlertContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ user, setUser ] = React.useState(null);

    //Fetch user on load.
    React.useEffect(() => {
        if (route.params.id === session.user.id) {
            setUser(session.user);
            setIsInitialised(true);
        } else {
            loadingScreen.queue((finish) => {
                const close = () => {
                    modal.close();
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.jumpTo("social");
                    }
                };
                send({
                    url: `get-user-screen/${ route.params.id }`,
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    localisation: localisation.locale.key,
                    onSuccess: async (response) => {
                        const result = await response.json();
                        //Save event properties.
                        setUser(result.user);
                        //Set initialised.
                        setIsInitialised(true);
                    },
                    onServerError: async () => {
                        modal.fire({
                            onRequestClose: close,
                            content: (
                                <WrapperModal size="small">
                                    <CardServerError
                                        onClose={ close }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onUserError: async () => {
                        modal.fire({
                            onRequestClose: close,
                            content: (
                                <WrapperModal size="small">
                                    <CardUserError
                                        title={ localisation.t("screens.home.profile.userFetchFailedTitle") }
                                        text={ localisation.t("screens.home.profile.userFetchFailedText") }
                                        errors={ result }
                                        onClose={ close }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onConnectionError: async () => {
                        modal.fire({
                            onRequestClose: close,
                            content: (
                                <WrapperModal size="small">
                                    <CardConnectionError
                                        onClose={ close }
                                    />
                                </WrapperModal>
                            ),
                        });
                    },
                    onFinish: () => finish(),
                });
            });
        }
    }, [ route.params.id === session.user.id && session.user, route.params.id ]);

    //Methods.
    const onPicturePress = React.useCallback(() => {
        loadingScreen.queue((finish) => {
            //Methods.
            const close = () => modal.close();
            const change = (base64) => {
                modal.close(() => loadingScreen.queue((finish) => {
                    send({
                        url: `set-profile-image`,
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: {
                            uri: base64,
                        },
                        localisation: localisation.locale.key,
                        onSuccess: async (response) => {
                            alert.post({
                                content: (
                                    <DefAlert
                                        text={ localisation.t("screens.home.profile.userImageSaveSuccessText") }
                                        status="success"
                                    />
                                ),
                            });
                            session.refresh();
                        },
                        onServerError: async () => modal.fire({
                            onRequestClose: close,
                            content: (
                                <WrapperModal size="small">
                                    <CardServerError
                                        onClose={ close }
                                    />
                                </WrapperModal>
                            ),
                        }),
                        onUserError: async (response) => {
                            const result = await response.json();
                            modal.fire({
                                onRequestClose: close,
                                content: (
                                    <WrapperModal size="small">
                                        <CardUserError
                                            title={ localisation.t("screens.home.profile.userImageSaveFailedTitle") }
                                            text={ localisation.t("screens.home.profile.userImageSaveFailedText") }
                                            errors={ result }
                                            onClose={ close }
                                        />
                                    </WrapperModal>
                                ),
                            });
                        },
                        onConnectionError: async () => modal.fire({
                            onRequestClose: close,
                            content: (
                                <WrapperModal size="small">
                                    <CardConnectionError
                                        onClose={ close }
                                    />
                                </WrapperModal>
                            ),
                        }),
                        onFinish: () => finish(),
                    });
                }));
            };
            //Send request.
            send({
                url: `get-user-image/${ user.id }/large/${ user.imageUpdatedAt }`,
                method: "get",
                localisation: localisation.locale.key,
                onSuccess: async (response) => {
                    const result = await response.blob();
                    //Convert blob to string.
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        //Get dimensions.
                        Image.getSize(reader.result, (width, height) => {
                            //Fire modal.
                            finish();
                            modal.fire({
                                onRequestClose: close,
                                content: (
                                    <ImageModal
                                        onChange={ change }
                                        onClose={ close }
                                        editable={ user.id === session.user.id }
                                        uri={ reader.result }
                                        initialWidth={ width }
                                        initialHeight={ height }
                                    />
                                ),
                            });
                        });
                    }
                    reader.readAsDataURL(result);
                },
                onServerError: async () => {
                    finish();
                    modal.fire({
                        onRequestClose: close,
                        content: (
                            <WrapperModal size="small">
                                <CardServerError
                                    onClose={ close }
                                />
                            </WrapperModal>
                        ),
                    });
                },
                onUserError: async (response) => {
                    const result = await response.json();
                    finish();
                    modal.fire({
                        onRequestClose: close,
                        content: (
                            <WrapperModal size="small">
                                <CardUserError
                                    title={ localisation.t("screens.home.profile.userImageFetchFailedTitle") }
                                    text={ localisation.t("screens.home.profile.userImageFetchFailedText") }
                                    errors={ result }
                                    onClose={ close }
                                />
                            </WrapperModal>
                        ),
                    });
                },
                onConnectionError: async () => {
                    finish();
                    modal.fire({
                        onRequestClose: close,
                        content: (
                            <WrapperModal size="small">
                                <CardConnectionError
                                    onClose={ close }
                                />
                            </WrapperModal>
                        ),
                    });
                },
            });
        });
        /*
        const close = () => modal.close();
        const change = () => {
            modal.close();
        };
        modal.fire({
            onRequestClose: close,
            content: (
                <ImageModal
                    onChange={ change }
                    onClose={ close }
                    user={ user }
                />
            ),
        });
        */
    }, [ user ]);
    const onFriendPress = React.useCallback(() => {
        const cancel = () => modal.close();
        const submit = () => {
            modal.close();
        };
        modal.fire({
            onRequestClose: cancel,
            content: (
                <SendFriendRequestModal
                    onSubmit={ submit }
                    onCancel={ cancel }
                />
            ),
        });
    }, [ user, session.user ]);
    const onChatPress = React.useCallback(() => {
        console.log("onChatPress");
    }, []);
    const onFriendRequestReceivedPress = React.useCallback(() => {
        console.log("onFriendRequestReceivedPress");
    }, []);
    const onFriendRequestSentPress = React.useCallback(() => {
        console.log("onFriendRequestSentPress");
    }, []);
    const onReportPress = React.useCallback(() => {
        const cancel = () => modal.close();
        const submit = () => {
            modal.close();
        };
        modal.fire({
            onRequestClose: cancel,
            content: (
                <ReportModal
                    onSubmit={ submit }
                    onCancel={ cancel }
                />
            ),
        });
    }, []);

    //Calculate age.
    const age = React.useMemo(() => {
        if (isInitialised) {
            const unix = (new Date(user.birthdate)).getTime();
            return getAge(unix);
        }
    }, [ user ]);

    //Calculate sizes.
    const sizes = React.useMemo(() => {
        const image = media.break === media.breaks.mobile ? IMAGE_SIZE_MOBILE : media.break === media.breaks.tablet ? IMAGE_SIZE_TABLET : IMAGE_SIZE_DESKTOP;
        const rating = media.break === media.breaks.mobile ? RATING_SIZE_MOBILE : media.break === media.breaks.tablet ? RATING_SIZE_TABLET : RATING_SIZE_DESKTOP;
        return {
            image: image,
            rating: rating,
        };
    }, [ media.break ]);

    //Return.
    const styles = stylesheet(theme, sizes);
    return isInitialised && (
        <ScrollView contentContainerStyle={ styles.scrollContainer }>
            <ScreenWrapper>
                <View style={ styles.container }>

                    {/* Profile picture. */}
                    <Pressable
                        style={ styles.imageContainer }
                        onPress={ onPicturePress }
                    >
                        <UserImage
                            user={ user }
                            size={ sizes.image }
                        />
                    </Pressable>

                    {/* Name. */}
                    <Text
                        style={ styles.username }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { user.username }
                    </Text>
                    { (user.id === session.user.id || !user.isPrivate) && (
                        <Text
                            style={ styles.fullName }
                            selectable={ false }
                            numberOfLines={ 1 }
                        >
                            { formatFullName(user.firstName, user.lastName) }
                        </Text>
                    )}

                    {/* Rating. */}
                    <View style={ styles.itemContainer }>
                        <View style={ styles.ratingContainer }>
                            <Stars
                                rating={ user.rating }
                                size={ sizes.rating }
                            />
                        </View>
                    </View>

                    {/* Interaction buttons. */}
                    <View style={ styles.interactionContainer }>
                        <View style={ styles.interactionItemContainer }>
                            <DefButton
                                icon={{
                                    source: "FontAwesome5",
                                    name: "user-plus",
                                }}
                                label={ localisation.t("screens.home.profile.addFriend") }
                                onPress={ onFriendPress }
                            />
                        </View>
                        <View style={ styles.interactionItemContainer }>
                            <DefButton
                                icon={{
                                    source: "Ionicons",
                                    name: "chatbubble",
                                }}
                                label={ localisation.t("screens.home.profile.chat") }
                                onPress={ onChatPress }
                            />
                        </View>
                    </View>

                    {/* Stats. */}
                    <View style={ styles.itemContainer }>
                        <StatsCard
                            title={ localisation.t("screens.home.profile.profileStatsTitle") }
                            stats={[{
                                left: (
                                    <Text style={ styles.statLabel }>
                                        { localisation.t("screens.home.profile.gender") }
                                    </Text>
                                ),
                                right: (
                                    <Text style={ styles.statLabel }>
                                        { user.gender === "m" ? localisation.t("screens.home.profile.male") : localisation.t("screens.home.profile.female") }
                                    </Text>
                                ),
                            }, {
                                left: (
                                    <Text style={ styles.statLabel }>
                                        { localisation.t("screens.home.profile.age") }
                                    </Text>
                                ),
                                right: (
                                    <Text style={ styles.statLabel }>
                                        { age }
                                    </Text>
                                ),
                            }]}
                        />
                    </View>

                    {/* Report button. */}
                    { /*user.id !== session.user.id &&*/ (
                        <View style={ styles.reportContainer }>
                            <View style={ styles.reportWrapper }>
                                <DefButton
                                    label={ localisation.t("screens.home.profile.report") }
                                    onPress={ onReportPress }
                                />
                            </View>
                        </View>
                    )}

                </View>
            </ScreenWrapper>
        </ScrollView>
    );
}

//Exports.
export default Component;
