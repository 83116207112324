
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations) => {

    const height = theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small + 2 * theme.border.width.medium;

    return StyleSheet.create({
        container: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
        },
        contentContainer: {
            backgroundColor: theme.colour.primary,
            padding: theme.border.width.medium,
            height: height,
            borderRadius: height / 2,
            display: "flex",
            flexDirection: "row",
        },

        //Icon.
        iconContainer: {
            flexBasis: theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small,
            padding: theme.spacing.small,
        },

        //Input.
        inputContainer: {
            backgroundColor: theme.colour.foreground,
            flexBasis: 0,
            flexGrow: 1,
            borderRadius: (height - 2 * theme.border.width.medium) / 2,
            flexDirection: "row",
            padding: theme.spacing.medium / 2,
        },
        levelContainer: {
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.medium / 2,
        },
        levelWrapper: {
            borderWidth: theme.border.width.medium,
            borderRadius: (height - theme.spacing.medium) / 2,
            borderColor: theme.colour.primary,
            height: "100%",
            overflow: "hidden",
        },

        //Error.
        errorContainer: {
            padding: theme.spacing.small / 2,
        },
    });
}

//Exports.
export default stylesheet;
