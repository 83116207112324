
import splash from "./splash";
import unauthenticated from "./unauthenticated";
import home from "./home/main";

const translations = {
    splash: splash,
    unauthenticated: unauthenticated,
    home: home,
};

export default translations;
