
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing.large / 2,
        },
        button: {
            flexBasis: theme.fonts.title.lineHeights.small + theme.spacing.medium,
            padding: theme.spacing.medium / 2,
        },
        disableWrapperPrev: {
            opacity: animations.disablePrev.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
        },
        disableWrapperNext: {
            opacity: animations.disableNext.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
        },
        label: {
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.medium / 2,
            color: theme.colour.text,
            textAlign: "center",
            fontSize: theme.fonts.title.sizes.small,
            fontFamily: theme.fonts.title.families.regular,
            lineHeight: theme.fonts.title.lineHeights.small,
        },
    });
}

//Exports.
export default stylesheet;
