
//Framework.
import React from "react";
import { View } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import CardTemplate from "@components/containers/cards/def/template";
import CardHeaderTitle from "@components/containers/cards/def/headers/title";
import CardFooterIcons from "@components/containers/cards/def/footers/icons";

//Styling.
//import stylesheet from "./stylesheets/dialogue";

/*
    title: (string),
    status: (string, choices are "primary", "secondary", "success", "warning", "danger"),
    buttons: [{
        onPress: (method),
        icon: {
            source: (string),
            name: (string),
            colour: (string),
        },
    }],
*/

const Component = ({ children, title, status, buttons, }) => {
    const theme = React.useContext(ThemeContext);

    //Get colours from status.
    const [ backgroundColour, textColour ] = React.useMemo(() => {
        switch(status) {
            case("primary"): return [ theme.colour.primary, theme.colour.primaryText ];
            case("secondary"): return [ theme.colour.secondary, theme.colour.secondaryText ];
            case("success"): return [ theme.colour.success, theme.colour.successText ];
            case("warning"): return [ theme.colour.warning, theme.colour.warningText ];
            case("danger"): return [ theme.colour.danger, theme.colour.dangerText ];
        }
    }, [ status ]);

    //Render.
    return (
        <CardTemplate>
            <>
                <CardHeaderTitle
                    text={ title }
                    backgroundColour={ backgroundColour }
                    textColour={ textColour }
                />
                <>
                    { children }
                </>
                <CardFooterIcons
                    buttons={ buttons }
                />
            </>
        </CardTemplate>
    );
}

//Exports.
export default Component;
