
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
    });
}

//Exports.
export default stylesheet;
