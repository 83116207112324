
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
        },
        padder: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
