
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
        },
        text: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.foreground, theme.colour.primary ],
            }),
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.primary, theme.colour.primaryText ],
            }),
            textAlign: "center",
            padding: theme.spacing.medium,
        },
    });
}

//Exports.
export default stylesheet;
