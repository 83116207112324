
import screens from "./screens/main";
import components from "./components/main";
import models from "./models/main";
import datetime from "./datetime/main";
import validation from "./validation";
import countries from "./countries";
import common from "./common";

const translations = {
    screens: screens,
    components: components,
    models: models,
    datetime: datetime,
    validation: validation,
    countries: countries,
    common: common,
};

export default translations;
