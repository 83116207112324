
import React from "react";

//Constants.
import { SESSION_REFRESH_INTERVAL_MS } from "@constants/timing";

//Helpers.
import { send } from "@helpers/connection";

//Helpers.
import { fetch, store } from "@helpers/device";

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {

    //States.
    const [ isActive, setIsActive ] = React.useState(false);
    const [ user, setUser ] = React.useState(null);
    const [ filters, setFilters ] = React.useState(null);

    //Define abort controller.
    const abortControllerRef = React.useRef(null);
    React.useEffect(() => {
        abortControllerRef.current = new AbortController();
        return () => abortControllerRef.current.abort();
    }, []);

    //Define interval reference.
    const timeoutRef = React.useRef(null);
    
    //Methods.
    const start = React.useCallback((user) => {
        //Set properties.
        setUser(user);
        //Start refresh loop.
        triggerTimeout();
        //Fetch filters from device.
        (async () => {
            const string = await fetch("filters");
            if (string === null) {
                setFilters([]);
            } else {
                setFilters(JSON.parse(string));
            }
            setIsActive(true);
        })();
    }, []);
    const end = React.useCallback(() => {
        //Clear properties.
        setUser(null);
        //setFilters(null);
        setIsActive(false);
        //Stop refresh.
        abortControllerRef.current.abort();
        clearTimeout(timeoutRef.current);
    }, []);
    const saveFilters = React.useCallback((filters) => {
        store("filters", JSON.stringify(filters));
    }, []);
    const triggerTimeout = React.useCallback(() => {
        timeoutRef.current = setTimeout(refresh, SESSION_REFRESH_INTERVAL_MS);
    }, []);
    const refresh = React.useCallback(() => {
        //Cancel old check if one is already active.
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        //Send.
        send({
            url: "check-session",
            method: "post",
            headers: {
                Accept: "text/plain",
                "Content-Type": "application/json",
            },
            body: {
                timestamp: session.user.lastRefreshedAt,
            },
            signal: abortControllerRef.current.signal,
            onSuccess: async(response) => {
                const result = await response.json();
                if (result.value) {
                    setUser(result.session.user);
                }
            },
            onFinish: triggerTimeout,
        });
    }, []);

    //Handle fitlers.
    /*
    const [ filters, setFilters ] = React.useState(null);
    const loadFilters = React.useCallback(async () => setFilters(await fetchFilters(Platform.OS)), []);
    const saveFilters = React.useCallback((filters) => {
        storeFilters(Platform.OS, filters);
        setFilters(filters);
    }, []);
    */
    
    //Create value.
    const value = React.useMemo(() => ({
        user: user,
        filters: filters,

        start: start,
        end: end,
        refresh: refresh,
        saveFilters: saveFilters,
    }), [ isActive, user, filters ]);
    global.session = value;
    
    //Render.
    return (
        <Context.Provider value={ value }>
            { children }
        </Context.Provider>
    );
}

/*
async function fetchFilters(OS) {
    let filters = [];
    try {
        if (OS === "web") {
            filters = JSON.parse(await AsyncStorage.getItem("filters"));
        } else {
            filters = JSON.parse(await SecureStore.getItemAsync("filters"));
        }
    } catch (e) {
        if (__DEV__) console.log("Token fetch error");
    }
    if (filters == null) filters = [];
    return filters;
}

async function storeFilters(OS, filters) {
    try {
        if (OS === "web") {
            await AsyncStorage.setItem("filters", JSON.stringify(filters));
        } else {
            await SecureStore.setItemAsync("filters", JSON.stringify(filters));
        }
    } catch(error) {
        if (__DEV__) console.log(error);
    }
}
*/

//Exports.
export default Context;
export { Provider };
