
//Framework.
import React from "react";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Template from "@components/inputs/templates/unauthenticated";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    value: (string),
    label: (string),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) maxLength: (integer),
    (Optional) inputMode: (string),
    (Optional) textContentType: (string),
    (Optional) returnKeyType: (string: "done"),
    (Optional) onChange: (method),
    (Optional) onBlur: (method),
    (Optional) onShowModal: (method),
    (Optional) onHideModal: (method),
    (Optional) error: (string),
*/

const Component = ({
    value: valueIn,
    label: labelIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    maxLength: maxLengthIn,
    inputMode: inputModeIn,
    textContentType: textContentTypeIn,
    returnKeyType: returnKeyTypeIn = "done",
    onChange: onChangeIn,
    onBlur: onBlurIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);
    
    //Render.
    const styles = stylesheet(theme);
    return (
        <Template
            label={ labelIn }
            value={ valueIn }
            disabled={ disabledIn }
            editable={ editableIn }
            maxLength={ maxLengthIn }
            inputMode={ inputModeIn }
            textContentType={ textContentTypeIn }
            returnKeyType={ returnKeyTypeIn }
            onChange={ onChangeIn }
            onBlur={ onBlurIn }
            error={ errorIn }
        />
    );
}

//Exports.
export default Component;
