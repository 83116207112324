
import { StyleSheet, Animated } from "react-native";

const stylesheet = (theme, animations, backgroundColour, textColour) => {

    const height = theme.fonts.text.lineHeights.large + 2 * theme.spacing.medium;

    return StyleSheet.create({
        disableWrapper: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.animations.disable.opacity ],
            }),
            width: "100%",
            height: height,
            borderRadius: height / 2,
            backgroundColor: "rgba(0, 0, 0, 1)",
        },
        blinkWrapper: {
            opacity: Animated.subtract(1, Animated.add(animations.hover.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.1],
            }), animations.press.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [0, 0.2, 0],
            }))),
            width: "100%",
            height: height,
            borderRadius: height / 2,
        },
        container: {
            backgroundColor: backgroundColour,
            width: "100%",
            height: height,
            borderRadius: height / 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        label: {
            color: textColour,
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
            textAlign: "center",
        },
    });
}

//Exports.
export default stylesheet;
