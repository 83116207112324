
import { StyleSheet } from "react-native";

const INDICATOR_HEIGHT = 10;

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.small / 2,
            flexBasis: 0,
            flexGrow: 1,
        },
        indicator: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(0,0,0,0)", theme.colour.primary ],
            }),
            width: "100%",
            height: INDICATOR_HEIGHT,
            borderRadius: INDICATOR_HEIGHT / 2,
            borderWidth: theme.border.width.medium,
            borderColor: theme.colour.primary,
        },
    });
}

//Exports.
export default stylesheet;
