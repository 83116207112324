
//Framework.
import React from "react";
import { View, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import CompanyBackground from "@components/misc/companyBackground";
import WrapperModal from "@components/containers/wrappers/modal";
import CardUnauthenticated from "@components/containers/cards/unauthenticated/def";
import LocalisationInput from "@components/inputs/localisation/selectable";

//Children.
import SignIn from "./signIn/main";
import SignUp from "./signUp/main";

//Styling.
import stylesheet from "./stylesheet";

//Constants.
const SIGN_IN_CARD_NUM = 0;
const SIGN_UP_CARD_NUM = 1;
const ANIMATION_DURATION_MS = 500;

const Component = ({}) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ isAnimating, setIsAnimating ] = React.useState(false);
    const [ cardNum, setCardNum ] = React.useState(SIGN_IN_CARD_NUM);
    const [ screenWidth, setScreenWidth ] = React.useState(null);

    //Methods.
    const onLayout = React.useCallback((e) => setScreenWidth(e.nativeEvent.layout.width), []);
    const goToCard = React.useCallback((cardNum) => setCardNum(cardNum), []);

    //Animation.
    const animations = {
        left: React.useRef(new Animated.Value(0)).current,
    };

    //Switch card when card num changes.
    React.useEffect(() => {
        if (!isInitialised) {
            setIsInitialised(true);
        } else {
            setIsAnimating(true);
            const left = -cardNum * screenWidth;
            switch(cardNum) {
                case(SIGN_IN_CARD_NUM):
                    Animated.timing(animations.left, {
                        toValue: left,
                        duration: ANIMATION_DURATION_MS,
                        useNativeDriver: true,
                    }).start(() => setIsAnimating(false));
                    break;
                case(SIGN_UP_CARD_NUM):
                    Animated.timing(animations.left, {
                        toValue: left,
                        duration: ANIMATION_DURATION_MS,
                        useNativeDriver: true,
                    }).start(() => setIsAnimating(false));
                    break;
            }
        }
    }, [ cardNum ]);
    React.useEffect(() => animations.left.setValue(-cardNum * screenWidth), [ screenWidth ]);
    
    //Return.
    const styles = stylesheet(theme, animations);
    return (
        <View
            style={ styles.container }
            onLayout={ onLayout }
        >
            {/* Company logo background. */}
            <View style={ styles.backgroundContainer }>
                <CompanyBackground/>
            </View>

            {/* Cards. */}
            <Animated.View style={ styles.cardsContainer }>
                {/* Sign in card. */}
                <View style={ styles.cardContainer }>
                    <WrapperModal size="small">
                        <SignIn
                            enabled={ !isAnimating }
                            onGoToSignUp={ () => goToCard(1) }
                        />
                    </WrapperModal>
                </View>
                {/* Sign up card. */}
                <View style={ styles.cardContainer }>
                    <WrapperModal size="small">
                        <SignUp
                            enabled={ !isAnimating }
                            onGoToSignIn={ () => goToCard(0) }
                        />
                    </WrapperModal>
                </View>
            </Animated.View>

            {/* Localisation button. */}
            {/*
            <View style={ styles.localisationContainer }>
                <LocalisationInput/>
            </View>
            */}
        </View>
    );
}

//Exports.
export default Component;
