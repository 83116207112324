
//Framework.
import React from "react";
import { View } from "react-native";

//Constants.
import { MOBILE, TABLET, DESKTOP, } from "@constants/mediaBreaks";

//Styling.
import stylesheet from "./stylesheets/media";

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {

    //States.
    const [ breakType, setBreakType ] = React.useState(null);

    //Methods.
    const onLayout = React.useCallback((e) => {
        const width = e.nativeEvent.layout.width;
        setBreakType(width < MOBILE ? MOBILE : width < TABLET ? TABLET : DESKTOP);
    }, []);

    //Create value.
    const value = React.useMemo(() => ({
        break: breakType,
        breaks: {
            mobile: MOBILE,
            tablet: TABLET,
            desktop: DESKTOP,
        },
    }), [ breakType ]);
    global.media = value;
    
    //Render.
    const styles = stylesheet();
    return (
        <Context.Provider value={ value }>
            <View style={ styles.container } onLayout={ onLayout }>
                { children }
            </View>
        </Context.Provider>
    );
}

//Exports.
export default Context;
export { Provider };
