
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        animationWrapper: {
            transform: [
                {
                    scaleX: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleX, 1 ],
                    }),
                },{
                    scaleY: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleY, 1 ],
                    }),
                }
            ],
        },
        container: {
            backgroundColor: theme.colour.primary,
            padding: theme.spacing.medium / 2,
            borderRadius: theme.border.radius.medium,
        },
        iconContainer: {
            padding: theme.spacing.medium / 2,
        },
        countContainer: {
            position: "absolute",
            top: theme.spacing.medium,
            right: theme.spacing.medium,
        },
        label: {
            color: theme.colour.primaryText,
            padding: theme.spacing.medium / 2,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
    });
}

//Exports.
export default stylesheet;
