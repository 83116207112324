
const SESSION_REFRESH_INTERVAL_MS = 3000;
const CHAT_REFRESH_INTERVAL_MS = 3000;
const MAP_EVENTS_REFRESH_INTERVAL_MS = 3000;
const ANIMATION_DELAY_STEP_MS = 110;
const DEBOUNCE_DURATION_MS = 200;

export {
    SESSION_REFRESH_INTERVAL_MS,
    CHAT_REFRESH_INTERVAL_MS,
    MAP_EVENTS_REFRESH_INTERVAL_MS,
    ANIMATION_DELAY_STEP_MS,
    DEBOUNCE_DURATION_MS,
}
