
const MOBILE = 480;
const TABLET = 768;
const DESKTOP = 1200;

export {
    MOBILE,
    TABLET,
    DESKTOP,
};
