
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import SwitchIcons from "@components/inputs/switches/icons";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    value: (any),
    onChange: (method),
    (Optional) disabled: (bool),
*/

const Component = ({ value:valueIn, onChange:onChangeIn, disabled:disabledIn, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    
    //Return.
    const styles = stylesheet(theme);
    return (
        <View style={ styles.container }>

            {/* Label. */}
            <Text
                selectable={ false }
                style={ styles.label }
            >
                { localisation.t("components.inputs.gender.unauthenticated.label") }
            </Text>

            {/* Switch. */}
            <SwitchIcons
                value={ valueIn }
                onChange={ onChangeIn }
                disabled={ disabledIn }
                options={{
                    off: {
                        value: "m",
                        icon: {
                            source: "FontAwesome5",
                            name: "male",
                        },
                        colours: {
                            track: theme.colour.switchTrack,
                            thumb: theme.colour.switchThumb,
                            selected: {
                                background: theme.colour.male,
                                icon: theme.colour.unauthenticatedCardText,
                            },
                            unselected: {
                                background: "transparent",
                                icon: theme.colour.male,
                            },
                        },
                    },
                    on: {
                        value: "f",
                        icon: {
                            source: "FontAwesome5",
                            name: "female",
                        },
                        colours: {
                            track: theme.colour.switchTrack,
                            thumb: theme.colour.switchThumb,
                            selected: {
                                background: theme.colour.female,
                                icon: theme.colour.unauthenticatedCardText,
                            },
                            unselected: {
                                background: "transparent",
                                icon: theme.colour.female,
                            },
                        },
                    },
                }}
            />

        </View>
    );
}

//Exports.
export default Component;
