
const translations = {
    title: "Unauthenticated",

    signInTitle: "Login",
    signInLabel: "Log In",

    signUpTitle: "Sign up",
    signUpLabel: "Register",

    signInFailedTitle: "Sign in failed",
    signInFailedText: "Your sign in request could not be completed.",

    signUpFailedTitle: "Registration failed",
    signUpFailedText: "Your registration attempt could not be completed.",

    signUpVerifyPhoneNumberTitle: "Verify phone number",
    signUpVerifyPhoneNumberText: "A text message has been sent to you with a code. Please verify your number by typing it in below.",

    signUpSuccessTitle: "Account created",
    signUpSuccessText: "While not a requirement, you can register your e-mail with your account.",
};

export default translations;
