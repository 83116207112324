
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Animated, Image, ScrollView } from "react-native";

//Constants.
import PHONE_NUMBER_PREFIXES from "@constants/phoneNumberPrefixes";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import WrapperBody from "@components/containers/wrappers/body";
import WrapperFade from "@components/containers/wrappers/fade";
import CardTemplate from "@components/containers/cards/def/template";
import CardFooterIcons, { getHeight as getFooterHeight } from "@components/containers/cards/def/footers/icons";
import SearchInput, { getHeight as getSearchInputHeight } from "@components/inputs/text/search";
import SearchResultsText, { getHeight as getSearchResultsHeight } from "@components/text/searchResults";

//Styling.
import stylesheetModal from "./stylesheets/modal/component";
import stylesheetOption from "./stylesheets/modal/option";

/*
    value: (any),
    options: [{
        value: (any),
        label: (string),
        left: (component),
        words: [(string)],
    }],
*/

//Constants.
const ANIMATION_SELECT_DURATION_MS = 300;
const OPTION_HEIGHT = 50;

const Component = ({ value:valueIn, onAccept:onAcceptIn, onCancel:onCancelIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ value, setValue ] = React.useState(valueIn);
    const [ searchText, setSearchText ] = React.useState("");

    //Methods.
    const onOptionPress = React.useCallback((value) => setValue(value), []);
    const onAccept = React.useCallback(() => onAcceptIn(value), [ value ]);
    const onCancel = React.useCallback(onCancelIn, []);
    const onSearchChange = React.useCallback((value) => setSearchText(value), []);

    //Get options filtered by the search.
    const visibleOptions = React.useMemo(() => {
        return PHONE_NUMBER_PREFIXES.filter(prefix => checkIfVisible(prefix, searchText));
    }, [ searchText ]);

    //Calculate sizes.
    const heights = React.useMemo(() => {
        //Search input.
        const searchInput = getSearchInputHeight(theme) + theme.spacing.medium
        //Options.
        const options =  visibleOptions.length === 0 ? 0 : visibleOptions.length * OPTION_HEIGHT + 2 * theme.spacing.small + 2 * theme.spacing.medium;
        //Search results.
        const searchResults = visibleOptions.length === PHONE_NUMBER_PREFIXES.length ? 0 : getSearchResultsHeight(theme) + theme.spacing.medium;
        //Footer.
        const footer = getFooterHeight(theme);
        //Return.
        return {
            searchInput,
            options,
            searchResults,
            footer,
        };
    }, [ searchText ]);

    //Render.
    const styles = stylesheetModal(theme, heights);
    return (
        <WrapperModal size={ "tiny" }>
            <CardTemplate>
                <View style={ styles.container }>

                    {/* Body. */}
                    <View style={ styles.bodyContainer }>
                        <WrapperBody>

                            {/* Search field. */}
                            <View style={ styles.searchInputContainer }>
                                <SearchInput
                                    value={ searchText }
                                    onChange={ onSearchChange }
                                />
                            </View>

                            {/* Options. */}
                            { visibleOptions.length !== 0 && (
                                <View style={ styles.optionsContainer }>
                                    <WrapperFade transitionSize={ theme.spacing.medium / 2 }>
                                        <ScrollView
                                            contentContainerStyle={ styles.optionsWrapper }
                                            showsVerticalScrollIndicator={ false }
                                        >
                                            { PHONE_NUMBER_PREFIXES.map((prefix, index) => (
                                                <Option
                                                    key={ index }
                                                    selected={ prefix.value === value }
                                                    prefix={ prefix }
                                                    searchText={ searchText }
                                                    onPress={ onOptionPress }
                                                >
                                                </Option>
                                            )) }
                                        </ScrollView>
                                    </WrapperFade>
                                </View>
                            )}

                            {/* Search results text. */}
                            { visibleOptions.length !== PHONE_NUMBER_PREFIXES.length && (
                                <View style={ styles.searchResultsContainer }>
                                    <SearchResultsText
                                        found={ visibleOptions.length }
                                        total={ PHONE_NUMBER_PREFIXES.length }
                                    />
                                </View>
                            )}

                        </WrapperBody>
                    </View>

                    {/* Footer. */}
                    <View style={ styles.footerContainer }>
                        <CardFooterIcons
                            buttons={[{
                                icon: {
                                    source: "FontAwesome5",
                                    name: "times",
                                    colour: theme.colour.danger,
                                },
                                onPress: onCancel,
                            }, {
                                icon: {
                                    source: "FontAwesome5",
                                    name: "check",
                                    colour: theme.colour.success,
                                },
                                onPress: onAccept,
                            }]}
                        />
                    </View>

                </View>
            </CardTemplate>
        </WrapperModal>
    );
}

const Option = ({ prefix:prefixIn, selected:selectedIn, searchText:searchTextIn, onPress:onPressIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ isVisible, setIsVisible ] = React.useState(true);

    //Animation.
    const animations = {
        select: React.useRef(new Animated.Value(selectedIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selectedIn) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selectedIn ]);

    //Methods.
    const onPress = React.useCallback(() => onPressIn(prefixIn.value), [ prefixIn.value, onPress ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Show or hide depending on search text.
    React.useEffect(() => setIsVisible(checkIfVisible(prefixIn, searchTextIn)), [ searchTextIn ]);

    //Render.
    const styles = stylesheetOption(theme, animations, OPTION_HEIGHT);
    return isVisible && (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
        >
            <Animated.View
                style={ styles.selectWrapper }
            >

                {/* Flag. */}
                <View style={ styles.flagContainer }>
                    <View style={ styles.flagWrapper }>
                        <Image
                            style={ styles.flag }
                            source={ prefixIn.flag.medium }
                            resizeMode="contain"
                        />
                    </View>
                </View>

                {/* Label. */}
                <View style={ styles.labelContainer }>
                    <Animated.Text style={ styles.label }>
                        { prefixIn.value }
                    </Animated.Text>
                </View>

            </Animated.View>
        </Pressable>
    );
}

const checkIfVisible = (prefix, searchText) => {
    const searchTextLower = searchText.toLowerCase();
    return searchTextLower === "" ||
        prefix.value.includes(searchTextLower) ||
        prefix.label.native.toLowerCase().includes(searchTextLower) ||
        localisation.t(prefix.label.key).toLowerCase().includes(searchTextLower)
        ? true : false;
};

//Exports.
export default Component;
