
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations, typeSize) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
        },
        inputContainer: {
            padding: theme.spacing.medium / 2,
        },

        //Type.
        typeContainer: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
            padding: theme.spacing.medium / 2,
            alignItems: "center",
        },
        typeCircle: {
            width: typeSize,
            height: typeSize,
            borderRadius: typeSize / 2,
            borderWidth: theme.border.width.large,
            borderColor: theme.colour.primary,
            padding: theme.spacing.medium,
        },
    });
}

//Exports.
export default stylesheet;
