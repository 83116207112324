
//Framework.
import React from "react";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/fade";

const Component = ({ children, transitionSize }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme, transitionSize);
    return (
        <View style={ styles.container }>
            { children }

            <LinearGradient
                colors={[ theme.colour.foreground, "transparent" ]}
                style={ styles.topGradient }
            />
            <LinearGradient
                colors={["transparent", theme.colour.foreground ]}
                style={ styles.bottomGradient }
            />
        </View>
    );
}

//Exports.
export default Component;
