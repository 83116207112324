
const model = {
    //Desc IDs.
    types: {
        friendRequestReceived: {
            id: 1,
        },
        newFriend: {
            id: 2,
        },
        eventInvitationReceived: {
            id: 3,
        },
        userAcceptedYourEventInvitation: {
            id: 4,
        },
        userJoinedYourEvent: {
            id: 5,
        },
        joinedEventHasChanged: {
            id: 6,
        },
        kickedFromEvent: {
            id: 7,
        },
        joinedEventHasBeenCancelled: {
            id: 8,
        },
        chatMessageHasBeenPosted: {
            id: 9,
        },
    },
};

export default model;
