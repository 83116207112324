
//Framework.
import React from "react";
import { View, Pressable, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";
import TextError from "@components/text/error";

//Styling.
import stylesheetComponent from "./stylesheets/def/component";
import stylesheetLevel from "./stylesheets/def/level";

/*
    value: (float),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) onChange: (method),
    (Optional) error: (string),
*/

const Component = ({
    value: valueIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    onChange: onChangeIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onLevelPress = React.useCallback((value) => onChangeIn(value), [ onChangeIn ]);

    //Animations.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);
    
    //Render.
    const styles = stylesheetComponent(theme, animations);
    return (
        <Animated.View style={ styles.container }>
            <View style={ styles.contentContainer }>

                {/* Icon. */}
                <View style={ styles.iconContainer }>
                    <Icon
                        source={ "FontAwesome5" }
                        name={ "trophy" }
                        colour={ theme.colour.primaryText }
                        size={ theme.fonts.text.lineHeights.medium }
                    />
                </View>

                {/* Input. */}
                <View style={ styles.inputContainer }>
                    <View style={ styles.levelContainer }>
                        <View style={ styles.levelWrapper }>
                            <Level
                                selected={ valueIn >= 1 }
                                onPress={ () => onLevelPress(1) }
                            />
                        </View>
                    </View>
                    <View style={ styles.levelContainer }>
                        <View style={ styles.levelWrapper }>
                            <Level
                                selected={ valueIn >= 2 }
                                onPress={ () => onLevelPress(2) }
                            />
                        </View>
                    </View>
                    <View style={ styles.levelContainer }>
                        <View style={ styles.levelWrapper }>
                            <Level
                                selected={ valueIn >= 3 }
                                onPress={ () => onLevelPress(3) }
                            />
                        </View>
                    </View>
                </View>

            </View>
            { !errorIn ? null : (
                <View style={ styles.errorContainer }>
                    <TextError>
                        { errorIn }
                    </TextError>
                </View>
            )}
        </Animated.View>
    );
}

const Level = ({ selected, onPress }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animations.
    const animations = {
        select: React.useRef(new Animated.Value(selected ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selected) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: theme.animations.select.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: theme.animations.select.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selected ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheetLevel(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
        >
            <Animated.View style={ styles.content }/>
        </Pressable>
    );
};

//Exports.
export default Component;
