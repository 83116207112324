
import { StyleSheet } from "react-native";

const stylesheet = (theme, sizes) => {
    return StyleSheet.create({
        scrollContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        container: {
            padding: theme.spacing.medium / 2,
            flexGrow: 1,
            justifyContent: "center",
        },
        itemContainer: {
            padding: theme.spacing.medium / 2,
        },

        imageContainer: {
            padding: theme.spacing.medium,
            alignItems: "center",
        },

        username: {
            padding: theme.spacing.small / 2,
            textAlign: "center",
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
        },
        fullName: {
            padding: theme.spacing.small / 2,
            textAlign: "center",
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.small,
        },

        ratingContainer: {
            padding: theme.spacing.medium / 2,
            alignItems: "center",
        },

        interactionContainer: {
            padding: theme.spacing.medium / 2,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        interactionItemContainer: {
            padding: theme.spacing.medium / 2,
            width: 220,
            maxWidth: "100%",
        },

        statLabel: {
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },

        reportContainer: {
            padding: theme.spacing.medium / 2,
            alignItems: "center",
        },
        reportWrapper: {
            width: 200,
            maxWidth: "100%",
        },
    });
}

//Exports.
export default stylesheet;
