
//Framework.
import React from "react";
import { Animated, Easing } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/counterBubble";

//Constants.
const ANIMATION_SHAKE_DURATION_MS = 600;
const ANIMATION_VISIBLE_DURATION_MS = 300;
const ANIMATION_INVERT_DURATION_MS = 300;

const Component = ({ value:valueIn, visible:visibleIn, invert:invertIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialed ] = React.useState(false);
    const [ hidden, setHidden ] = React.useState(!visibleIn);

    //Animation.
    const animations = {
        visible: React.useRef(new Animated.Value(visibleIn ? 1 : 0)).current,
        shake: React.useRef(new Animated.Value(0)).current,
        invert: React.useRef(new Animated.Value(invertIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.shake, {
                toValue: 1,
                duration: ANIMATION_SHAKE_DURATION_MS,
                easing: Easing.elastic(10),
                useNativeDriver: true,
            }).start(() => animations.shake.setValue(0));
        }
    }, [ valueIn ]);
    React.useEffect(() => {
        if (isInitialised) {
            if (visibleIn) {
                setHidden(false);
                Animated.timing(animations.visible, {
                    toValue: 1,
                    duration: ANIMATION_VISIBLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.visible, {
                    toValue: 0,
                    duration: ANIMATION_VISIBLE_DURATION_MS,
                    useNativeDriver: true,
                }).start(() => setHidden(true));
            }
        }
    }, [ visibleIn ]);
    React.useEffect(() => {
        if (isInitialised) {
            if (invertIn) {
                setHidden(false);
                Animated.timing(animations.invert, {
                    toValue: 1,
                    duration: ANIMATION_INVERT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.invert, {
                    toValue: 0,
                    duration: ANIMATION_INVERT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ invertIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialed(true), []);
    
    //Return.
    const styles = stylesheet(theme, animations, hidden);
    return (
        <Animated.View style={ styles.container }>
            <Animated.Text
                style={ styles.label }
                selectable={ false }
                numberOfLines={ 1 }
            >
                { valueIn }
            </Animated.Text>
        </Animated.View>
    );
}

const getMaxValue = (theme) => 99;
const getSize = (theme) => theme.fonts.text.lineHeights.small + 2 * theme.spacing.tiny;

//Exports.
export default Component;
export { getMaxValue, getSize };
