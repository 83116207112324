
import { StyleSheet, Animated } from "react-native";

const stylesheet = (theme, animations, sizes, hasIcon) => {

    const height = sizes.lineHeight + 2 * sizes.padding;

    return StyleSheet.create({
        animationWrapper: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.animations.disable.opacity ],
            }),
            transform: [
                {
                    scaleX: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleX, 1 ],
                    }),
                },{
                    scaleY: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleY, 1 ],
                    }),
                }
            ],
            height: height,
            borderRadius: height / 2,
        },
        container: {
            backgroundColor: "transparent",
            height: height,
            borderRadius: height / 2,
            borderWidth: theme.border.width.medium,
            borderColor: theme.colour.primary,
            padding: (sizes.padding - theme.border.width.medium) / 2,
            flexDirection: "row",
        },
        iconContainer: {
            padding: sizes.padding / 2,
            flexBasis: sizes.lineHeight + sizes.padding,
        },
        label: {
            padding: sizes.padding / 2,
            paddingRight: sizes.padding / 2 + (hasIcon && (sizes.lineHeight + sizes.padding)),
            color: theme.colour.primary,
            fontSize: sizes.font,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: sizes.lineHeight,
            textAlign: "center",
            flexBasis: 0,
            flexGrow: 1,
        },
    });
}

//Exports.
export default stylesheet;
