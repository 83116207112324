
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const stylesheet = (theme, media) => {
    return StyleSheet.create({
        scrollContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            justifyContent: "center",
            padding: theme.spacing.medium / 2,
            paddingBottom: theme.spacing.medium / 2 + getCreateButtonOverflow(theme, media),
        },
    });
}

//Exports.
export default stylesheet;
