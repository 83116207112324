
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const BUTTON_WIDTH = 400;
const SPLITTER_WIDTH = 500;

const stylesheet = (theme, defButtonHeight) => {
    return StyleSheet.create({
        container: {
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing.large / 2,
        },
        itemContainer: {
            padding: theme.spacing.large / 2,
            flexBasis: defButtonHeight + theme.spacing.large,
            width: BUTTON_WIDTH,
            maxWidth: "100%",
        },
        splitter: {
            backgroundColor: theme.colour.text,
            height: theme.border.width.medium,
            margin: theme.spacing.large / 2,
            marginLeft: 0,
            marginRight: 0,
            width: SPLITTER_WIDTH,
            maxWidth: "100%",
        },
    });
}

//Exports.
export default stylesheet;
