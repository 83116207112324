
//Framework.
import React from "react";
import { View, Pressable, Text, FlatList, Animated, Easing, Image, ScrollView } from "react-native";

//Constants.
import EVENT_MODEL from "@constants/models/event";
import { ANIMATION_DELAY_STEP_MS } from "@constants/timing";

//Helpers.
import { send } from "@helpers/connection";
import validate from "@helpers/validate";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import CardTemplate from "@components/containers/cards/def/template";
import CardFooterIcons from "@components/containers/cards/def/footers/icons";
import Icon from "@components/misc/icon";
import MapControls, { getSize as getControlsSize } from "@components/misc/map/controls/button";
import MapView from "@components/misc/map/main";
import TextInput from "@components/inputs/text/def";

//Styling.
import stylesheet from "./stylesheets/def";

//Constants.
const ANIMATION_CONTROLS_PUSH_DURATION_MS = 500;

/*
    value: {
        address: (string),
        latitude: (float),
        longitude: (float),
    },
    onAccept: (method),
    onCancel: (method),
*/

const Component = ({ value:valueIn, onAccept:onAcceptIn, onCancel:onCancelIn, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ isLoading, setIsLoading ] = React.useState(false);

    //Define abort controller.
    const abortControllerRef = React.useRef(new AbortController);
    React.useEffect(() => {
        return () => abortControllerRef.current.abort();
    }, []);

    //Validation.
    const check = React.useCallback((latitude, longitude, address) => {
        const validations = {
            //Latitude.
            latitude: validate(latitude, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.latitude.validation"),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.latitude.min,
                message: localisation.t("validation.min", {
                    field: localisation.t("models.event.latitude.validation"),
                    value: EVENT_MODEL.latitude.min,
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.latitude.max,
                message: localisation.t("validation.max", {
                    field: localisation.t("models.event.latitude.validation"),
                    value: EVENT_MODEL.latitude.max,
                }),
            }]),
            //Longitude.
            longitude: validate(longitude, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.longitude.validation"),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.longitude.min,
                message: localisation.t("validation.min", {
                    field: localisation.t("models.event.longitude.validation"),
                    value: EVENT_MODEL.longitude.min,
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.longitude.max,
                message: localisation.t("validation.max", {
                    field: localisation.t("models.event.longitude.validation"),
                    value: EVENT_MODEL.longitude.max,
                }),
            }]),
            //Address.
            address: validate(address, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.address.validation"),
                }),
            }, {
                type: "maxLength",
                value: EVENT_MODEL.address.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.event.address.validation"),
                    value: EVENT_MODEL.address.maxLength,
                }),
            }]),
        }
        //Return.
        return {
            isValid:
                validations.latitude.isValid &&
                validations.longitude.isValid &&
                validations.address.isValid,
            errors: {
                latitude: validations.latitude.isValid ? null : validations.latitude.errors[0],
                longitude: validations.longitude.isValid ? null : validations.longitude.errors[0],
                address: validations.address.isValid ? null : validations.address.errors[0],
            },
        };
    }, []);

    //Reducer.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        let newState = { ...oldState }, isValid, errors;
        switch(action.type) {
            case("set"):
                //Set.
                newState.latitude.value = action.latitude;
                newState.longitude.value = action.longitude;
                newState.address.value = action.address;
                //Validate.
                if (newState.isSubmitted) {
                    let result = check(
                        newState.latitude.value,
                        newState.longitude.value,
                        newState.address.value
                    );
                    isValid = result.isValid;
                    errors = result.errors;
                    newState.latitude.error = errors.latitude;
                    newState.longitude.error = errors.longitude;
                    newState.address.error = errors.address;
                }
                //Return.
                break;
            case("setAddress"):
                //Set.
                newState.address.value = action.value;
                //Validate.
                if (newState.isSubmitted) {
                    let result = check(
                        newState.latitude.value,
                        newState.longitude.value,
                        newState.address.value
                    );
                    isValid = result.isValid;
                    errors = result.errors;
                    newState.latitude.error = errors.latitude;
                    newState.longitude.error = errors.longitude;
                    newState.address.error = errors.address;
                }
                //Return.
                break;
            case("submit"):
                //Validate.
                let result = check(
                    newState.latitude.value,
                    newState.longitude.value,
                    newState.address.value
                );
                isValid = result.isValid;
                errors = result.errors;
                newState.latitude.error = errors.latitude;
                newState.longitude.error = errors.longitude;
                newState.address.error = errors.address;
                //Set.
                newState.isSubmitted = true;
                newState.toBeSubmitted = isValid;
                //Return.
                break;
            case("handshake"):
                //Set.
                newState.toBeSubmitted = false;
                //Return.
                break;
        }
        //Return.
        return newState;
    }, {
        toBeSubmitted: false,
        isSubmitted: false,
        latitude: { value: valueIn.latitude, error:"", },
        longitude: { value: valueIn.longitude, error:"", },
        address: { value: valueIn.address, error:"", },
    });
    React.useEffect(() => {
        dispatch({ type:"handshake", });
        if (state.toBeSubmitted) {
            onAcceptIn({
                latitude: state.latitude.value,
                longitude: state.longitude.value,
                address: state.address.value,
            });
        }
    }, [ state.toBeSubmitted ]);

    //Methods.
    const onAcceptPress = React.useCallback(() => dispatch({ type: "submit" }), []);
    const onCancelPress = React.useCallback(onCancelIn, []);
    const onMapPress = React.useCallback((latitude, longitude) => {
        const onFinish = () => setIsLoading(false);
        const onError = () => dispatch({ type:"set", latitude:null, longitude:null, address:"", });
        const onSuccess = async (response) => {
            const result = await response.text();
            dispatch({
                type: "set",
                latitude: latitude,
                longitude: longitude,
                address: result,
            });
        };
        setIsLoading(true);
        //Abort previous request.
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController;
        //Fire request.
        send({
            url: `get-address-with-coordinates/${ latitude }/${ longitude }`,
            method: "get",
            headers: {
                Accept: "text/plain",
                "Content-Type": "text/plain",
            },
            localisation: localisation.locale.key,
            signal: abortControllerRef.current.signal,
            onSuccess: onSuccess,
            onConnectionError: onError,
            onServerError: onError,
            onUserError: onError,
            onFinish: onFinish,
        });
    }, []);
    const onAddressChange = React.useCallback((value) => dispatch({ type:"setAddress", value:value }), []);

    //Define controls.
    const mapRef = React.useRef();
    const zoomIn = React.useCallback(() => mapRef.current.zoomIn(), []);
    const zoomOut = React.useCallback(() => mapRef.current.zoomOut(), []);
    const zoomHome = React.useCallback(() => mapRef.current.zoomHome(), []);

    //Animations.
    const animations = {
        zoomControlsPush: React.useRef(new Animated.Value(0)).current,
        homeControlsPush: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        Animated.timing(animations.zoomControlsPush, {
            toValue: 1,
            duration: ANIMATION_CONTROLS_PUSH_DURATION_MS,
            delay: ANIMATION_DELAY_STEP_MS,
            easing: Easing.elastic(1.25),
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.homeControlsPush, {
            toValue: 1,
            duration: ANIMATION_CONTROLS_PUSH_DURATION_MS,
            delay: 2 * ANIMATION_DELAY_STEP_MS,
            easing: Easing.elastic(1.25),
            useNativeDriver: true,
        }).start();
    }, []);

    //Render.
    const styles = stylesheet(theme, animations);
    return (
        <View style={ styles.container }>
            <CardTemplate>
                {/* Body. */}
                <View style={ styles.body }>
                    {/* Map. */}
                    <View style={ styles.mapContainer }>
                        <MapView
                            ref={ mapRef }
                            onPress={ onMapPress }
                            markers={ state.latitude && state.longitude && [/*{
                                type: "event",
                                id: 1,
                                descId: 1,
                                longitude: state.longitude,
                                latitude: state.latitude,
                            }*/]}
                        />
                    </View>

                    {/* Controls. */}
                    <Animated.View style={ styles.zoomControlsContainer }>
                        <MapControls
                            buttons={[{
                                onPress: zoomIn,
                                icon: {
                                    source: "FontAwesome5",
                                    name: "plus",
                                },
                            }, {
                                onPress: zoomOut,
                                icon: {
                                    source: "FontAwesome5",
                                    name: "minus",
                                },
                            }]}
                        />
                    </Animated.View>
                    <Animated.View style={ styles.homeControlsContainer }>
                        <MapControls
                            buttons={[{
                                onPress: zoomHome,
                                icon: {
                                    source: "FontAwesome5",
                                    name: "location-arrow",
                                },
                            }]}
                        />
                    </Animated.View>

                    {/* Address input. */}
                    <View style={ styles.inputContainer }>
                        <TextInput
                            value={ state.address.value }
                            disabled={ isLoading }
                            maxLength={ EVENT_MODEL.address.maxLength }
                            placeholder={ localisation.t("models.event.address.label") }
                            icon={{
                                source: "FontAwesome5",
                                name: "map",
                            }}
                            onChange={ onAddressChange }
                        />
                    </View>
                </View>

                {/* Footer. */}
                <View style={ styles.footerContainer }>
                    <CardFooterIcons
                        buttons={[{
                            icon: {
                                source: "FontAwesome5",
                                name: "times",
                                colour: theme.colour.danger,
                            },
                            onPress: onCancelIn,
                        }, {
                            icon: {
                                source: "FontAwesome5",
                                name: "check",
                                colour: theme.colour.success,
                            },
                            onPress: onAcceptPress,
                            disabled: isLoading,
                        }]}
                    />
                </View>
            </CardTemplate>
        </View>
    );
}

//Exports.
export default Component;
