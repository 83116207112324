
import { StyleSheet, Platform } from "react-native";

const stylesheet = function(theme) {
    return StyleSheet.create({
    });
}

//Exports.
export default stylesheet;
