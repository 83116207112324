
//Framework.
import React from "react";
import { View, Text, Pressable, Switch, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
//import ExpandableDef from "@components/containers/expandables/def";
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/toggle";

/*
    title: (string),
    icon: {
        source: (string),
        name: (string),
    },
*/

//Constants.
const ANIMATION_OPEN_DURATION_MS = 500;

const Component = ({ children, title:titleIn, icon:iconIn, open:openIn, onChange:onChangeIn, disabled:disabledIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ isAnimating, setIsAnimating ] = React.useState(false);
    const [ contentSize, setContentSize ] = React.useState(0);

    //Methods.
    const onContentLayout = React.useCallback((e) => setContentSize(e.nativeEvent.layout.height), []);
    const onHeaderPress = React.useCallback(() => onChangeIn(!openIn), [ openIn ]);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
        open: React.useRef(new Animated.Value(openIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);
    React.useEffect(() => {
        if (isInitialised) {
            setIsAnimating(true);
            if (openIn) {
                Animated.timing(animations.open, {
                    toValue: 1,
                    duration: ANIMATION_OPEN_DURATION_MS,
                    useNativeDriver: true,
                }).start(() => setIsAnimating(false));
            } else {
                Animated.timing(animations.open, {
                    toValue: 0,
                    duration: ANIMATION_OPEN_DURATION_MS,
                    useNativeDriver: true,
                }).start(() => setIsAnimating(false));
            }
        }
    }, [ openIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheet(theme, animations, isAnimating, openIn, contentSize);
    return (
        <View style={ styles.container }>

            {/* Header. */}
            <Pressable
                style={ styles.headerContainer }
                onPress={ onHeaderPress }
                disabled={ disabledIn || isAnimating }
            >
                <View style={ styles.iconContainer }>
                    <Icon
                        source={ iconIn.source }
                        name={ iconIn.name }
                        size={ theme.fonts.text.lineHeights.medium }
                        colour={ theme.colour.primaryText }
                    />
                </View>
                <View style={ styles.titleContainer }>
                    <Text
                        style={ styles.title }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { titleIn }
                    </Text>
                </View>
                <View style={ styles.switchContainer }>
                    <Switch
                        value={ openIn }
                        thumbColor={ theme.colour.switchThumb }
                        activeThumbColor={ theme.colour.switchThumb }
                        trackColor={{
                            true: theme.colour.success,
                            false: theme.colour.switchTrack,
                        }}
                    />
                </View>
            </Pressable>

            {/* Content. */}
            <Animated.View style={ styles.contentContainer }>
                <View
                    style={ styles.contentBorderWrapper }
                    onLayout={ onContentLayout }
                >
                    <View
                        style={ styles.contentWrapper }
                    >
                        { children }
                    </View>
                </View>
            </Animated.View>

        </View>
    );
}

//Exports.
export default Component;
