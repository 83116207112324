
//Framework.
import React from "react";
import { View, Pressable, Text } from "react-native";

//Constants.
import NOTIFICATION_MODEL from "@constants/models/notification";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import CounterBubble, { getSize as getCounterBubbleSize } from "@components/misc/counterBubble";
import UserImage from "@components/images/user";

//Styling.
import stylesheet from "./stylesheets/notification";

const Component = ({ notification }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Get display height.
    const displayHeight = React.useMemo(() => {
        return theme.fonts.text.lineHeights.medium + theme.fonts.text.lineHeights.large + 2 * theme.spacing.small;
    }, [ theme ]);

    //Get notification features.
    const features = React.useMemo(() => getFeatures(notification, theme, localisation, displayHeight), [ notification, theme, localisation, media.break ]);

    //Methods.
    const onPress = React.useCallback(features.onPress, [ features.onPress ]);

    //Return.
    const styles = stylesheet(theme, getCounterBubbleSize(theme));
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
        >
            {/* Text */}
            <View style={ styles.textContainer }>
                <Text
                    style={ styles.title }
                    selectable={ false }
                >
                    { features.title }
                </Text>
                <Text
                    style={ styles.text }
                    selectable={ false }
                >
                    { features.text }
                </Text>
            </View>

            {/* Display. */}
            <View style={ styles.displayContainer }>
                { features.display }
            </View>

            {/* Seen/unseen. */}
            <View style={ styles.counterContainer }>
                <CounterBubble
                    value="!"
                    visible={ !notification.seen }
                />
            </View>
        </Pressable>
    );
}

const getFeatures = (notification, theme, localisation, displayHeight) => {
    switch(notification.descId) {
        //Friend request received.
        case(NOTIFICATION_MODEL.types.friendRequestReceived.id): return {
            title: localisation.t("models.notification.types.friendRequestReceived.title"),
            text: localisation.t("models.notification.types.friendRequestReceived.text", {
                username: notification.properties[0].model.username,
            }),
            onPress: () => console.log({ where:"friendRequestReceived press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //New friend.
        case(NOTIFICATION_MODEL.types.newFriend.id): return {
            title: localisation.t("models.notification.types.newFriend.title"),
            text: localisation.t("models.notification.types.newFriend.text", {
                username: notification.properties[0].model.username,
            }),
            onPress: () => console.log({ where:"newFriend press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //Event invitation received.
        case(NOTIFICATION_MODEL.types.eventInvitationReceived.id): return {
            title: localisation.t("models.notification.types.eventInvitationReceived.title"),
            text: localisation.t("models.notification.types.eventInvitationReceived.text", {
                username: notification.properties[0].model.username,
                title: notification.properties[1].model.title,
            }),
            onPress: () => console.log({ where:"eventInvitationReceived press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //User has accepted your event invitation.
        case(NOTIFICATION_MODEL.types.userAcceptedYourEventInvitation.id): return {
            title: localisation.t("models.notification.types.userAcceptedYourEventInvitation.title"),
            text: localisation.t("models.notification.types.userAcceptedYourEventInvitation.text", {
                username: notification.properties[0].model.username,
                title: notification.properties[1].model.title,
            }),
            onPress: () => console.log({ where:"userAcceptedYourEventInvitation press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //User has joined your event.
        case(NOTIFICATION_MODEL.types.userJoinedYourEvent.id): return {
            title: localisation.t("models.notification.types.userJoinedYourEvent.title"),
            text: localisation.t("models.notification.types.userJoinedYourEvent.text", {
                username: notification.properties[0].model.username,
                title: notification.properties[1].model.title,
            }),
            onPress: () => console.log({ where:"userJoinedYourEvent press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //Joined event has changed.
        case(NOTIFICATION_MODEL.types.joinedEventHasChanged.id): return {
            title: localisation.t("models.notification.types.joinedEventHasChanged.title"),
            text: localisation.t("models.notification.types.joinedEventHasChanged.text", {
                title: notification.properties[0].model.title,
            }),
            onPress: () => console.log({ where:"joinedEventHasChanged press", notification }),
        };
        //Kicked from event.
        case(NOTIFICATION_MODEL.types.kickedFromEvent.id): return {
            title: localisation.t("models.notification.types.kickedFromEvent.title"),
            text: localisation.t("models.notification.types.kickedFromEvent.text", {
                username: notification.properties[0].model.username,
                title: notification.properties[1].model.title,
            }),
            onPress: () => console.log({ where:"kickedFromEvent press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
        //Joined event has been cancelled.
        case(NOTIFICATION_MODEL.types.joinedEventHasBeenCancelled.id): return {
            title: localisation.t("models.notification.types.joinedEventHasBeenCancelled.title"),
            text: localisation.t("models.notification.types.joinedEventHasBeenCancelled.text", {
                title: notification.properties[0].model.title,
            }),
            onPress: () => console.log({ where:"joinedEventHasBeenCancelled press", notification }),
        };
        //New chat message.
        case(NOTIFICATION_MODEL.types.chatMessageHasBeenPosted.id): return {
            title: localisation.t("models.notification.types.chatMessageHasBeenPosted.title"),
            text: localisation.t("models.notification.types.chatMessageHasBeenPosted.text", {
                username: notification.properties[0].model.username,
            }),
            onPress: () => console.log({ where:"chatMessageHasBeenPosted press", notification }),
            display: (
                <View style={{ padding:theme.spacing.small / 2 }}>
                    <UserImage
                        user={ notification.properties[0].model }
                        size={ displayHeight - theme.spacing.small }
                    />
                </View>
            ),
            displayWidth: displayHeight,
        };
    }
};

//Exports.
export default Component;
