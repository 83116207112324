
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations) => {

    const size = theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small;

    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.primary,
            width: size,
            height: size,
            borderRadius: size / 2,
            padding: theme.border.width.medium,
        },
        label: {
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.text, theme.colour.primaryText ],
            }),
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.foreground, theme.colour.primary ],
            }),
            width: size - 2 * theme.border.width.medium,
            height: size - 2 * theme.border.width.medium,
            borderRadius: (size - 2 * theme.border.width.medium) / 2,
            padding: theme.spacing.small,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
    });
}

//Exports.
export default stylesheet;
