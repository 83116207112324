
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Animated, Image, ScrollView } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import CardTemplate from "@components/containers/cards/def/template";
import CardFooterIcons from "@components/containers/cards/def/footers/icons";
import Icon from "@components/misc/icon";

//Styling.
//import stylesheet from "./stylesheets/modal";
import stylesheetComponent from "./stylesheets/modal/component";
import stylesheetYear from "./stylesheets/modal/year";
import stylesheetDatesHeader from "./stylesheets/modal/datesHeader";
import stylesheetDate from "./stylesheets/modal/date";
import stylesheetClock from "./stylesheets/modal/clock";

/*
                    initialValue={ valueIn }
                    useDate={ useDateIn }
                    useTime={ useTimeIn }
                    max={ maxIn }
                    min={ minIn }
                    onAccept={ onAccept }
                    onCancel={ onClose }
*/

//Constants.
const ANIMATION_SELECT_DURATION_MS = 300;
const ANIMATION_DISABLE_DURATION_MS = 300;

const Component = ({ initialValue:initialValueIn, useDate:useDateIn, useTime:useTimeIn, max:maxIn, min:minIn, onAccept:onAcceptIn, onCancel:onCancelIn, }) => {
    //console.log({ initialValueIn, useDateIn, useTimeIn, maxIn, minIn, });
    const theme = React.useContext(ThemeContext);

    //Process times.
    const initialDatetime = React.useMemo(() => {
        const datetime = new Date(initialValueIn);
        return {
            year: datetime.getFullYear(),
            month: datetime.getMonth(),
            date: datetime.getDate(),
            hours: datetime.getHours(),
            minutes: datetime.getMinutes(),
            seconds: datetime.getSeconds(),
        };
    }, []);
    const maxYear = React.useMemo(() => (new Date(maxIn)).getFullYear(), [ maxIn ]);
    const minYear = React.useMemo(() => (new Date(minIn)).getFullYear(), [ minIn ]);

    //States.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        switch(action.type) {
            case("setYear"):
                const datetime = new Date(action.value, oldState.month, 1);
                datetime.setMonth(datetime.getMonth() + 1);
                datetime.setDate(-1);
                datetime.setHours(23);
                datetime.setMinutes(59);
                datetime.setSeconds(59);
                datetime.setMilliseconds(999);
                return {
                    ...oldState,
                    visibleMonth: datetime.getTime < minIn ? (new Date(min)).getMonth() : oldState.month,
                    visibleYear: action.value,
                };
            case("prevMonth"):
                return {
                    ...oldState,
                    visibleMonth: oldState.visibleMonth === 0 ? 11 : oldState.visibleMonth - 1,
                    visibleYear: oldState.visibleMonth === 0 ? oldState.visibleYear - 1 : oldState.visibleYear,
                };
            case("nextMonth"):
                return {
                    ...oldState,
                    visibleMonth: oldState.visibleMonth === 11 ? 0 : oldState.visibleMonth + 1,
                    visibleYear: oldState.visibleMonth === 11 ? oldState.visibleYear + 1 : oldState.visibleYear,
                };
            case("selectDate"): return {
                    ...oldState,
                    year: action.year,
                    month: action.month,
                    date: action.date,
                    visibleYear: action.year,
                    visibleMonth: action.month,
                };
            case("setTime"): return {
                ...oldState,
                hours: action.hours,
                minutes: action.minutes,
            };
        }
    }, {
        visibleYear: initialDatetime.year,
        visibleMonth: initialDatetime.month,
        year: initialDatetime.year,
        month: initialDatetime.month,
        date: initialDatetime.date,
        hours: initialDatetime.hours,
        minutes: initialDatetime.minutes,
    });

    //Methods.
    const onAccept = React.useCallback(() => {
        const datetime = new Date(state.year, state.month, state.date, state.hours, state.minutes);
        onAcceptIn(datetime.getTime());
    }, [ state.year, state.month, state.date, state.hours, state.minutes ]);
    const onClose = React.useCallback(() => onCancelIn(), []);
    const onSelectYear = React.useCallback((value) => dispatch({ type:"setYear", value:value }), []);
    const onPrevMonth = React.useCallback(() => dispatch({ type:"prevMonth" }), []);
    const onNextMonth = React.useCallback(() => dispatch({ type:"nextMonth" }), []);
    const onPressDate = React.useCallback((year, month, date) => dispatch({ type:"selectDate", year:year, month:month, date:date }), []);
    const onChangeTime = React.useCallback((hours, minutes) => dispatch({ type:"setTime", hours:hours, minutes:minutes }), []);

    //Find dates for the currently selected month.
    const weeks = React.useMemo(() => {
        //Find earliest Monday, even if it's in last month.
        const startDate = new Date(state.visibleYear, state.visibleMonth, 1);
        while(startDate.getDay() !== 1) {
            startDate.setDate(startDate.getDate() - 1);
        }
        //Find last Sunday.
        const endDate = new Date(state.visibleYear, state.visibleMonth, 1);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(endDate.getDate() - 1);
        while(endDate.getDay() !== 1) {
            endDate.setDate(endDate.getDate() + 1);
        }
        //Loop through dates to generate list.
        const dates = [];
        while(!(startDate.getMonth() === endDate.getMonth() && startDate.getDate() === endDate.getDate())) {
            dates.push({
                year: startDate.getFullYear(),
                month: startDate.getMonth(),
                date: startDate.getDate(),
            });
            startDate.setDate(startDate.getDate() + 1);
        }
        //Split into weeks.
        const weeks = [];
        while(dates.length) weeks.push(dates.splice(0,7));
        //Return.
        return weeks;
    }, [ state.visibleYear, state.visibleMonth ]);

    //Render.
    const styles = stylesheetComponent(theme);
    return (
        <WrapperModal size="medium">
            <CardTemplate>
                {/* Body. */}
                <View style={ styles.body }>

                    {/* Years. */}
                    <View style={ styles.yearsContainer }>
                        <ScrollView
                            style={ styles.yearsScroller }
                            contentContainerStyle={ styles.yearsContent }
                            showsVerticalScrollIndicator={ false }
                        >
                            { [ ...Array(maxYear - minYear + 1) ].map((item, index) => minYear + index).map(((year, index) => (
                                <Year
                                    key={ index }
                                    value={ year }
                                    onPress={ onSelectYear }
                                    selected={ year === state.visibleYear }
                                />
                            ))) }
                        </ScrollView>
                    </View>

                    {/* Dates. */}
                    <View style={ styles.datesContainer }>
                        {/* Header. */}
                        <DatesHeader
                            year={ state.visibleYear }
                            month={ state.visibleMonth }
                            max={ maxIn }
                            min={ minIn }
                            onPrev={ onPrevMonth }
                            onNext={ onNextMonth }
                        />
                        {/* Body. */}
                        <View style={ styles.datesBodyContainer }>
                            {/* Weekdays. */}
                            <View style={ styles.weekdaysContainer }>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.monday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.tuesday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.wednesday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.thursday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.friday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.saturday.single") }</Text>
                                <Text style={ styles.weekday } selectable={ false }>{ localisation.t("datetime.weekdays.sunday.single") }</Text>
                            </View>
                            {/* Dates. */}
                            <View style={ styles.weeksContainer }>
                                { weeks.map((week, index) => (
                                    <View
                                        key={ index }
                                        style={ styles.weekRow }
                                    >
                                        { week.map((date, index) => (
                                            <View
                                                key={ `${ date.year }-${ date.month }-${ date.date }` }
                                                style={ styles.dateContainer }
                                            >
                                                <CalendarDate
                                                    year={ date.year }
                                                    month={ date.month }
                                                    date={ date.date }
                                                    selected={ state.year === date.year && state.month === date.month && state.date === date.date }
                                                    min={ minIn }
                                                    max={ maxIn }
                                                    visibleMonth={ state.visibleMonth }
                                                    onPress={ onPressDate }
                                                />
                                            </View>
                                        )) }
                                    </View>
                                )) }
                            </View>
                        </View>
                    </View>

                </View>

                {/* Clock. */}
                { useTimeIn && (
                    <Clock
                        hours={ state.hours }
                        minutes={ state.minutes }
                        onChange={ onChangeTime }
                    />
                )}

                {/* Footer. */}
                <View style={ styles.footerContainer }>
                    <CardFooterIcons
                        buttons={[{
                            icon: {
                                source: "FontAwesome5",
                                name: "times",
                                colour: theme.colour.danger,
                            },
                            onPress: onClose,
                        }, {
                            icon: {
                                source: "FontAwesome5",
                                name: "check",
                                colour: theme.colour.success,
                            },
                            onPress: onAccept,
                        }]}
                    />
                </View>
            </CardTemplate>
        </WrapperModal>
    );
}

const Year = ({ value:valueIn, onPress:onPressIn, selected:selectedIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animations.
    const animations = {
        select: React.useRef(new Animated.Value(selectedIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selectedIn) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selectedIn ]);

    //Methods.
    const onPress = React.useCallback(() => onPressIn(valueIn), [ valueIn, onPressIn ]);

    //Mark as initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetYear(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
        >
            <Animated.Text
                style={ styles.label }
                selectable={ false }
            >
                { valueIn }
            </Animated.Text>
        </Pressable>
    );
};

const DatesHeader = ({ year:yearIn, month:monthIn, min:minIn, max:maxIn, onPrev:onPrevIn, onNext:onNextIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Get month name.
    const label = React.useMemo(() => {
        switch(monthIn) {
            case(0): return localisation.t("datetime.months.january.short");
            case(1): return localisation.t("datetime.months.february.short");
            case(2): return localisation.t("datetime.months.march.short");
            case(3): return localisation.t("datetime.months.april.short");
            case(4): return localisation.t("datetime.months.may.short");
            case(5): return localisation.t("datetime.months.june.short");
            case(6): return localisation.t("datetime.months.july.short");
            case(7): return localisation.t("datetime.months.august.short");
            case(8): return localisation.t("datetime.months.september.short");
            case(9): return localisation.t("datetime.months.october.short");
            case(10): return localisation.t("datetime.months.november.short");
            case(11): return localisation.t("datetime.months.december.short");
        }
    }, [ monthIn ]);

    //Check if it's possible to switch month.
    const canGoPrev = React.useMemo(() => {
        const datetime = new Date(yearIn, monthIn, 1);
        datetime.setDate(-1);
        return datetime.getTime() >= minIn;
    }, [ yearIn, monthIn, minIn ]);
    const canGoNext = React.useMemo(() => {
        const datetime = new Date(yearIn, monthIn, 1);
        datetime.setMonth(datetime.getMonth() + 1);
        return datetime.getTime() <= maxIn;
    }, [ yearIn, monthIn, maxIn ]);

    //Animations.
    const animations = {
        disablePrev: React.useRef(new Animated.Value(canGoPrev ? 0 : 1)).current,
        disableNext: React.useRef(new Animated.Value(canGoNext ? 0 : 1)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (canGoPrev) {
                Animated.timing(animations.disablePrev, {
                    toValue: 0,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disablePrev, {
                    toValue: 1,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ canGoPrev ]);
    React.useEffect(() => {
        if (isInitialised) {
            if (canGoNext) {
                Animated.timing(animations.disableNext, {
                    toValue: 0,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disableNext, {
                    toValue: 1,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ canGoNext ]);

    //Mark as initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetDatesHeader(theme, animations);
    return (
        <View style={ styles.container }>
            <Pressable
                style={ styles.button }
                onPress={ onPrevIn }
                disabled={ !canGoPrev }
            >
                <Animated.View style={ styles.disableWrapperPrev }>
                    <Icon
                        source="FontAwesome5"
                        name="chevron-left"
                        colour={ theme.colour.text }
                        size={ theme.fonts.title.sizes.small }
                    />
                </Animated.View>
            </Pressable>
            <Text
                style={ styles.label }
                selectable={ false }
            >
                { label }
            </Text>
            <Pressable
                style={ styles.button }
                onPress={ onNextIn }
                disabled={ !canGoNext }
            >
                <Animated.View style={ styles.disableWrapperNext }>
                    <Icon
                        source="FontAwesome5"
                        name="chevron-right"
                        colour={ theme.colour.text }
                        size={ theme.fonts.title.sizes.small }
                    />
                </Animated.View>
            </Pressable>
        </View>
    );
};

const CalendarDate = ({ year:yearIn, month:monthIn, date:dateIn, selected:selectedIn, min:minIn, max:maxIn, visibleMonth:visibleMonthIn, onPress:onPressIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animations.
    const animations = {
        select: React.useRef(new Animated.Value(selectedIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selectedIn) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selectedIn ]);

    //Methods.
    const onPress = React.useCallback(() => onPressIn( yearIn, monthIn, dateIn ), [ onPressIn ]);

    //Mark as initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Check if date is within min-max range.
    const isPressable = React.useMemo(() => {
        const datetime = new Date(yearIn, monthIn, dateIn);
        datetime.setHours(23);
        datetime.setMinutes(59);
        datetime.setSeconds(59);
        datetime.setMilliseconds(999);
        const unix = datetime.getTime();
        return unix <= maxIn && unix >= minIn;
    }, [ minIn, maxIn, yearIn, monthIn, dateIn, ]);

    //Return.
    const styles = stylesheetDate(theme, animations, !isPressable || visibleMonthIn !== monthIn);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
            disabled={ !isPressable }
        >
            <Animated.Text
                style={ styles.label }
                selectable={ false }
            >
                { dateIn }
            </Animated.Text>
        </Pressable>
    );
};

const Clock = ({ hours:hoursIn, minutes:minutesIn, onChange:onChangeIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ inFocus, setInFocus ] = React.useState(false);

    //Methods.
    const onChangeHours = React.useCallback((value) => {
        onChangeIn(Math.min(parseInt(value) || 0, 23), minutesIn);
    }, [ minutesIn ]);
    const onChangeMinutes = React.useCallback((value) => {
        onChangeIn(hoursIn, Math.min(parseInt(value) || 0, 59));
    }, [ hoursIn ]);
    const onFocus = React.useCallback(() => setInFocus(true), []);
    const onBlur = React.useCallback(() => setInFocus(false), []);

    //Return.
    const styles = stylesheetClock(theme);
    return (
        <View style={ styles.container }>
            <TextInput
                style={ styles.hoursInput }
                value={ inFocus ? hoursIn : zerofy(hoursIn) }
                onChangeText={ onChangeHours }
                onFocus={ onFocus }
                onBlur={ onBlur }
                inputMode="numeric"
                keyboardType="number-pad"
                maxLength="2"
            />
            <Text
                style={ styles.splitLabel }
                selectable={ false }
            >
                :
            </Text>
            <TextInput
                style={ styles.minutesInput }
                value={ inFocus ? minutesIn : zerofy(minutesIn) }
                onChangeText={ onChangeMinutes }
                onFocus={ onFocus }
                onBlur={ onBlur }
                inputMode="numeric"
                keyboardType="number-pad"
                maxLength="2"
            />
        </View>
    );
};
const zerofy = (value) => value < 10 ? `0${value}` : value;

//Exports.
export default Component;
