
import { StyleSheet } from "react-native";

const stylesheet = function(theme, media) {

    const size = media.break === media.breaks.mobile ? 400 : media.break === media.breaks.tablet ? 600 : media.break === media.breaks.desktop ? 800 : 0;

    return StyleSheet.create({
        container: {
            padding: theme.spacing.large,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        image: {
            width: size,
            height: size,
        },
    });
}

//Exports.
export default stylesheet;
