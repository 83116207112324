
import { StyleSheet, Platform } from "react-native";

const SWITCH_WIDTH = 40;

const stylesheet = (theme, animations, options, height) => {

    return StyleSheet.create({
        container: {
            width: "100%",
            height: height,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        offContainer: {
            backgroundColor: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ options.off.colours.selected, options.off.colours.unselected ],
            }),
            borderTopLeftRadius: height / 2,
            borderBottomLeftRadius: height / 2,
            height: "100%",
            flexBasis: 0,
            flexGrow: 1,
        },
        onContainer: {
            backgroundColor: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ options.on.colours.unselected, options.on.colours.selected ],
            }),
            borderTopRightRadius: height / 2,
            borderBottomRightRadius: height / 2,
            height: "100%",
            flexBasis: 0,
            flexGrow: 1,
        },
        switchContainer: {
            flexBasis: SWITCH_WIDTH + 2 * theme.spacing.medium,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        switchOnContainer: {
            backgroundColor: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "transparent", options.on.colours.selected ],
            }),
            position: "absolute",
            width: "100%",
            height: "100%",
            borderTopLeftRadius: height / 2,
            borderBottomLeftRadius: height / 2,
        },
        switchOffContainer: {
            backgroundColor: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ options.off.colours.selected, "transparent" ],
            }),
            position: "absolute",
            width: "100%",
            height: "100%",
            borderTopRightRadius: height / 2,
            borderBottomRightRadius: height / 2,
        },
    });
}

//Exports.
export default stylesheet;
