
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.foreground,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: 40,

            //Shadow.
            shadowColor: "rgb(0, 0, 0)",
            shadowOffset: {
                width: 4,
                height: 4,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
        },
    });
}

//Exports.
export default stylesheet;
