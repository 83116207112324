
//Framework.
import React from "react";
import { Animated, Easing, View, Pressable } from "react-native";

//Contexts.
import ThemeContext from "@contexts/theme";

//Assets.
import sound from "@assets/sounds/alert.mpga";

//Styling.
import stylesheetProvider from "./stylesheets/alert/provider";
import stylesheetAlert from "./stylesheets/alert/alert";

//Create context.
const Context = React.createContext();

const ANIMATION_SHOW_DURATION_MS = 500;
const ANIMATION_SHOWN_DURATION_MS = 5000;
const ANIMATION_HIDE_AUTO_DURATION_MS = 2000;
const ANIMATION_HIDE_PRESSED_DURATION_MS = 500;
const ANIMATION_HIDE_SHRINK_DURATION_MS = 500;

//Create provider.
const Provider = function({ children }) {
    const theme = React.useContext(ThemeContext);

    //Handle counter. Apparently it's necessary to avoid bugs caused by ignored dispatches or state change events.
    const countRef = React.useRef(0);
    const increment = React.useCallback(() => {
        countRef.current++;
    }, []);
    const decrement = React.useCallback(() => {
        if (countRef.current-- === 1) {
            dispatch({ type:"clear" });
        }
    }, []);

    //Reducer.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        switch(action.type) {
            case("push"):
                increment();
                return {
                    alerts: oldState.alerts.concat( action.alert ),
                };
            case("clear"): return { alerts: [] };
        }
    }, {
        alerts: [],
    });

    //Methods.
    const post = React.useCallback(({ onPress, content }) => {
        dispatch({
            type: "push",
            alert: {
                onPress: onPress,
                content: content,
            },
        });
    }, []);

    //Create value.
    const value = React.useMemo(() => ({
        post: post,
    }));
    global.alert = value;

    //Render.
    const styles = stylesheetProvider(theme);
    return (
        <Context.Provider value={ value }>
            { children }
            <View style={ styles.container }>
                { state.alerts.map((alert, index) =>
                    <Alert
                        key={ index }
                        onPress={ alert.onPress }
                        onFinish={ decrement }
                        content={ alert.content }
                    />
                ) }
            </View>
        </Context.Provider>
    );
}

//Alert component.
const Alert = ({ content:contentIn, onPress:onPressIn, onFinish:onFinishIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isPressable, setIsPressable ] = React.useState(true);

    //Animation.
    const animations = {
        opacity: React.useRef(new Animated.Value(0)).current,
        marginTop: React.useRef(new Animated.Value(0)).current,
        height: React.useRef(new Animated.Value(0)).current,
    };
    const timeoutRef = React.useRef(null);
    React.useEffect(() => () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }, []);
    React.useEffect(() => {
        //Show.
        Animated.timing(animations.marginTop, {
            toValue: theme.spacing.medium / 2,
            duration: ANIMATION_SHOW_DURATION_MS,
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.opacity, {
            toValue: 1,
            duration: ANIMATION_SHOW_DURATION_MS,
            useNativeDriver: true,
        }).start();
        //Hide.
        timeoutRef.current = setTimeout(() => {
            Animated.timing(animations.opacity, {
                toValue: 0,
                duration: ANIMATION_HIDE_AUTO_DURATION_MS,
                delay: ANIMATION_SHOWN_DURATION_MS,
                useNativeDriver: true,
            }).start();
            //Shrink.
            timeoutRef.current = setTimeout(() => {
                setIsPressable(false);
                Animated.timing(animations.height, {
                    toValue: 0,
                    duration: ANIMATION_HIDE_SHRINK_DURATION_MS,
                    easing: Easing.elastic(1),
                    useNativeDriver: true,
                }).start();
                Animated.timing(animations.marginTop, {
                    toValue: 0,
                    duration: ANIMATION_HIDE_SHRINK_DURATION_MS,
                    useNativeDriver: true,
                }).start(() => onFinishIn());
            }, ANIMATION_HIDE_AUTO_DURATION_MS + ANIMATION_SHOWN_DURATION_MS);
        }, ANIMATION_SHOW_DURATION_MS);
    }, []);

    //Methods.
    const onLayout = React.useCallback((e) => animations.height.setValue(e.nativeEvent.layout.height), []);
    const onPress = React.useCallback(() => {
        //Flag pressed.
        setIsPressable(false);
        //Halt any previous animation.
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        //Fire new animation.
        Animated.timing(animations.marginTop, {
            toValue: -animations.height._value,
            duration: ANIMATION_HIDE_PRESSED_DURATION_MS,
            easing: Easing.elastic(1),
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.opacity, {
            toValue: 0,
            duration: ANIMATION_HIDE_PRESSED_DURATION_MS,
            useNativeDriver: true,
        }).start(() => {
            animations.marginTop.setValue(0);
            animations.height.setValue(0);
            onFinishIn();
        });
        //Do press method if specified.
        if (onPressIn) onPressIn();
    }, []);

    //Play sound on render.
    React.useEffect(() => {
        const audio = new Audio(sound);
        audio.play();
    }, []);

    //Render.
    const styles = stylesheetAlert(theme, animations);
    return (
        <Animated.View
            style={ styles.container }
        >
            <Pressable
                onLayout={ onLayout }
                disabled={ !isPressable }
                onPress={ onPress }
            >
                { contentIn }
            </Pressable>
        </Animated.View>
    );
}

//Exports.
export default Context;
export { Provider };
