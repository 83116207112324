
const translations = {
    type: {
        label: "Type",
        validation: "The type",
    },
    title: {
        label: "Title",
        validation: "The title",
    },
    address: {
        label: "Address",
        validation: "The address",
    },
    latitude: {
        label: "Latitude",
        validation: "The latitude",
    },
    longitude: {
        label: "Longitude",
        validation: "The longitude",
    },
    datetime: {
        label: "Timestamp",
        validation: "The timestamp",
    },
    playerAmount: {
        label: "Player amount",
        validation: "The player amount",
    },
    skillLevel: {
        label: "Skill level",
        validation: "The skill level",
    },
    privacy: {
        label: "Privacy",
        validation: "The privacy",
    },
    price: {
        label: "Price",
        validation: "The price",
    },
    repeat: {
        label: "Repeat switch",
        validation: "The repeat switch",
    },
    repeatRules: {
        label: "Repeat rules",
        validation: "The repeat rules",
    },
    description: {
        label: "Description",
        validation: "The description",
    },

    types: {
        unlisted: "Unlisted",
        football: "Football",
        basketball: "Basketball",
        roundball: "Roundball",
        golf: "Golf",
        hockey: "Hockey",
        dogWalking: "Dog walking",
        walk: "Hike",
        run: "Run",
        horseRiding: "Horse riding",
        rollerSkating: "Roller skating",
        swimming: "Swimming",
        tableTennis: "Table tennis",
        americanFootball: "American football",
        volleyBall: "Volley ball",
        tennis: "Tennis",
        badminton: "Badminton",
        yoga: "Yoga",
        crossfit: "Crossfit",
        rowing: "Rowing",
        fishing: "Fishing",
        bicycling: "Bicycling",
        babyWalk: "Baby walk",
        snowmanBuilding: "Snowman building",
        kiteSurfing: "Black people meet-up",
        kayaking: "Kayaking",
        handball: "Handball",
        chess: "Chess",
        archery: "Archery",
        shooting: "Shooting",
    },
};

export default translations;
