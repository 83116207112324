
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
        },
        content: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(0, 0, 0, 0)", theme.colour.primary ],
            }),
            width: "100%",
            height: "100%",
        },
    });
}

//Exports.
export default stylesheet;
