
//Framework.
import React from "react";
import { ScrollView, View, Pressable, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import SessionContext from "@contexts/session";
import MediaContext from "@contexts/media";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import SocialNavButton, { getHeight as getSocialNavButtonHeight } from "@components/buttons/socialNav";
import DefButton, { getHeight as getDefButtonHeight } from "@components/buttons/def";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const localisation = React.useContext(LocalisationContext);
    const theme = React.useContext(ThemeContext);
    const session = React.useContext(SessionContext);
    const media = React.useContext(MediaContext);

    crash();

    //Methods.
    const onPress = React.useCallback((path) => {
        switch(path) {
            case("profile"): return navigation.push("profile");
            case("friends"): return navigation.push("friends");
            case("teams"): return navigation.push("teams");
            case("settings"): return navigation.push("settings");
        }
    }, []);

    //Calculate heights.
    const heights = React.useMemo(() => ({
        defButton: getDefButtonHeight(theme),
        socialNavButton: getSocialNavButtonHeight(theme, media),
    }), [ theme, media.break ]);

    //Return.
    const styles = stylesheet(theme, media, heights);
    return (
        <ScrollView
            contentContainerStyle={{ flexGrow: 1, }}
        >
            <ScreenWrapper>
                <View style={ styles.container }>

                    {/* Profile. */}
                    <View style={ styles.itemContainer }>
                        <SocialNavButton
                            icon={{
                                source: "FontAwesome5",
                                name: "user",
                            }}
                            label={ localisation.t("screens.home.profile.title") }
                            onPress={ () => onPress("profile") }
                            initialParams={{ id:session.user.id }}
                        />
                    </View>

                    {/* Friends. */}
                    <View style={ styles.itemContainer }>
                        <SocialNavButton
                            icon={{
                                source: "FontAwesome5",
                                name: "user-plus",
                            }}
                            label={ localisation.t("screens.home.friends.title") }
                            onPress={ () => onPress("friends") }
                            count={ session.user.receivedFriendRequests.length }
                        />
                    </View>

                    {/* Teams. */}
                    <View style={ styles.itemContainer }>
                        <SocialNavButton
                            icon={{
                                source: "FontAwesome5",
                                name: "users",
                            }}
                            label={ localisation.t("screens.home.teams.title") }
                            onPress={ () => onPress("teams") }
                        />
                    </View>

                    {/* Settings. */}
                    <View style={ styles.settingsContainer }>
                        <DefButton
                            icon={{
                                source: "FontAwesome5",
                                name: "cog",
                            }}
                            label={ localisation.t("screens.home.settings.title") }
                            onPress={ () => onPress("settings") }
                        />
                    </View>

                </View>
            </ScreenWrapper>
        </ScrollView>
    );
};

//Exports.
export default Component;
