
//Framework.
import React from "react";
import { View } from "react-native";

//Constants.
import USER_MODEL from "@constants/models/user";

//Helpers.
import validate from "@helpers/validate";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationText from "@contexts/localisation";

//Components.
import CardDialogue from "@components/containers/cards/def/dialogue";
import TextBody from "@components/text/body";
import WrapperBody from "@components/containers/wrappers/body";
import TextInput from "@components/inputs/text/def";

//Styling.
import stylesheet from "./stylesheets/verifyPhoneNumberModal";

const Component = ({ initialValue:initialValueIn = "", failed:failedIn = false, onSubmit:onSubmitIn, onClose:onCloseIn, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationText);

    //Validation.
    const check = React.useCallback((digits) => {
        const validations = {
            //Digits.
            digits: validate(digits, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.activationCode.validation"),
                }),
            }, {
                type: "exactLength",
                value: USER_MODEL.activationCode.maxLength,
                message: localisation.t("validation.exactLength", {
                    field: localisation.t("models.user.activationCode.validation"),
                    value: USER_MODEL.activationCode.maxLength,
                }),
            }]),
        };
        //Return.
        return {
            isValid:
                validations.digits.isValid,
            errors: {
                digits: validations.digits.isValid ? null : validations.digits.errors[0],
            },
        };
    }, []);

    //State.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        let newState = { ...oldState }, result, isValid, errors;
        switch(action.type) {
            case("onChange"):
                //Update.
                newState.digits.value = action.value;
                //Validate.
                if (oldState.isSubmitted) {
                    result = check(newState.digits.value);
                    isValid = result.isValid;
                    errors = result.errors;
                    newState.digits.error = errors.digits;
                }
                //Break.
                break;
            case("submit"):
                //Update.
                newState.isSubmitted = true;
                //Validate.
                result = check(newState.digits.value);
                isValid = result.isValid;
                errors = result.errors;
                newState.digits.error = errors.digits;
                if (isValid) {
                    newState.toBeSubmitted = true;
                }
                //Break.
                break;
        }
        //Return.
        return newState;
    }, {
        submitted: false,
        toBeSubmitted: false,
        digits: {
            value: "",
            error: "",
        },
    });
    React.useEffect(() => {
        if (state.toBeSubmitted) {
            console.log({ where:"submit", state });
        }
    }, [ state.toBeSubmitted ]);

    //Methods.
    const onClosePress = React.useCallback(onCloseIn, [ onCloseIn ]);
    const onAcceptPress = React.useCallback(() => onSubmitIn(state.digits.value), [ onSubmitIn, state.digits.value ]);
    const onDigitsChange = React.useCallback((value) => dispatch({ type:"onChange", value:value }), []);

    //Render.
    const styles = stylesheet(theme);
    return (
        <CardDialogue
            title={ localisation.t("screens.unauthenticated.signUpVerifyPhoneNumberTitle") }
            status="primary"
            buttons={[{
                onPress: onClosePress,
                icon: {
                    source: "FontAwesome5",
                    name: "times",
                    colour: theme.colour.danger,
                },
            }, {
                onPress: onAcceptPress,
                icon: {
                    source: "FontAwesome5",
                    name: "check",
                    colour: theme.colour.success,
                },
            }]}
        >
            <WrapperBody>

                {/* Text. */}
                <View style={ styles.itemContainer }>
                    <TextBody align="center">
                        { localisation.t("screens.unauthenticated.signUpVerifyPhoneNumberText") }
                    </TextBody>
                </View>

                {/* Input. */}
                <View style={ styles.itemContainer }>
                    <TextInput
                        value={ state.digits.value }
                        onChange={ onDigitsChange }
                        placeholder={ localisation.t("models.user.activationCode.label") }
                        maxLength={ USER_MODEL.activationCode.exactLength }
                    />
                </View>

            </WrapperBody>
        </CardDialogue>
    );
}

//Exports.
export default Component;
