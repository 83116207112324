
//Framework.
import React from "react";
import { View } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationText from "@contexts/localisation";

//Components.
import CardDialogue from "@components/containers/cards/def/dialogue";
import TextBody from "@components/text/body";
import TextError from "@components/text/error";
import WrapperBody from "@components/containers/wrappers/body";

//Styling.
import stylesheet from "./stylesheets/userError";

/*
    title: (string),
    text: (string),
    errors = [
        (string)
    ],
    onClose: (method),
*/

const Component = ({ title, text, errors, onClose, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationText);

    //Render.
    const styles = stylesheet(theme);
    return (
        <CardDialogue
            title={ title }
            status="danger"
            buttons={[{
                onPress: onClose,
                icon: {
                    source: "FontAwesome5",
                    name: "times",
                    colour: theme.colour.danger,
                },
            }]}
        >
            <WrapperBody>
                <View style={ styles.textContainer }>
                    <TextBody align="center">
                        { text }
                    </TextBody>
                </View>
                { errors.length > 0 && (
                    <View
                        style={ styles.errorsContainer }
                    >
                        { errors.map((error, index) => (
                            <View
                                key={ index }
                                style={ styles.errorContainer }
                            >
                                <TextError align="center">
                                    { error }
                                </TextError>
                            </View>
                        )) }
                    </View>
                )}
            </WrapperBody>
        </CardDialogue>
    );
}

//Exports.
export default Component;
