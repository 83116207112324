
import { StyleSheet } from "react-native";

const stylesheet = (theme, createButtonSize, tabIconSize) => {
    return StyleSheet.create({
        container: {
            flexDirection: "row",
            height: tabIconSize + 2 * theme.spacing.medium,

            //Shadow.
            shadowColor: theme.colour.shadow,
            shadowOffset: {
                width: 0,
                height: -2,
            },
            shadowOpacity: theme.opacity.shadow,
            shadowRadius: 5,
        },
        itemContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },

        itemBlocker: {
            backgroundColor: theme.colour.foreground,
            flexBasis: createButtonSize * 0.6,
        },
        createButton: {
            width: createButtonSize,
            height: createButtonSize,
            borderRadius: createButtonSize / 2,
            position: "absolute",
            left: "50%",
            bottom: theme.spacing.medium,
            marginLeft: -createButtonSize / 2,
            overflow: "hidden",

            //Shadow.
            shadowColor: theme.colour.shadow,
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: theme.opacity.shadow,
            shadowRadius: 8,
        },
        createImage: {
            width: createButtonSize,
            height: createButtonSize,
            borderRadius: createButtonSize / 2,
        },
    });
}

//Exports.
export default stylesheet;
