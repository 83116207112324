
import { StyleSheet } from "react-native";

const stylesheet = (theme, heights) => {
    return StyleSheet.create({
        container: {
            maxHeight:"100%",
        },
        bodyContainer: {
            flexBasis: heights.searchInput + heights.optionsInput + heights.searchResults,
            flexShrink: 1,
            display: "flex",
            padding: theme.spacing.medium / 2,
            paddingLeft: 0,
            paddingRight: 0,
        },

        //Search.
        searchInputContainer: {
            flexBasis: heights.searchInput,
            padding: theme.spacing.medium / 2,
            paddingLeft: 0,
            paddingRight: 0,
        },
        searchResultsContainer: {
            flexBasis: heights.searchResults,
            padding: theme.spacing.medium / 2,
            paddingLeft: 0,
            paddingRight: 0,
        },

        //Options.
        optionsContainer: {
            height: heights.options,
            paddingLeft: 0,
            paddingRight: 0,
            flexShrink: 1,
        },
        optionsWrapper: {
            padding: theme.spacing.medium / 2,
            paddingLeft: 0,
            paddingRight: 0,
        },

        //Footer.
        footerContainer: {
            flexBasis: heights.footer,
        },
    });
}

//Exports.
export default stylesheet;
