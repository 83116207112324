
import { StyleSheet } from "react-native";

const stylesheet = (theme, sizeIn) => {
    
    //Return.
    return StyleSheet.create({
        container: {
            flexDirection: "row",
        },
        starContainer: {
            flexBasis: sizeIn,
        },
    });
}

//Exports.
export default stylesheet;
