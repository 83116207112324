
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const stylesheet = (theme, media) => {
    return StyleSheet.create({
        container: {
            justifyContent: "center",
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.large / 2,
            paddingBottom: getCreateButtonOverflow(theme, media) + theme.spacing.large / 2,
        },
        itemContainer: {
            padding: theme.spacing.large / 2,
        },
        label: {
            color: theme.colour.primary,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.large,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.large,
        },
    });
}

//Exports.
export default stylesheet;
