
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
        },

        //Indicators.
        indicatorsContainer: {
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing.medium / 2,
        },

        //Steps.
        stepsContainer: {
            display: "flex",
            flexDirection: "row",
            width: "300%",
            marginLeft: animations.stepNum.interpolate({
                inputRange: [ 0, 2 ],
                outputRange: [ "0%", "-200%" ],
            }),
        },
        stepContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
    });
}

//Exports.
export default stylesheet;
