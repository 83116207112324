
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        textContainer: {
            padding: theme.spacing.medium,
        },
        errorsContainer: {
            padding: theme.spacing.medium / 2,
        },
        errorContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
