
//Framework.
import React from "react";
import { Platform, Text, View, Pressable, Animated, Easing } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { debounce } from "lodash";

//Constants.
import { MAP_EVENTS_REFRESH_INTERVAL_MS, ANIMATION_DELAY_STEP_MS } from "@constants/timing";
import { MAX_AREA } from "@constants/map";

//Helpers.
import { send } from "@helpers/connection";
import { getTimezoneOffset } from "@helpers/time";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import LoadingScreenContext from "@contexts/loadingScreen";
import ModalContext from "@contexts/modal";
import SessionContext from "@contexts/session";

//Components.
import MapControls, { getSize as getControlsSize } from "@components/misc/map/controls/button";
import MapView from "@components/misc/map/main";

//Children.
import EventModal from "./eventModal/main";
import FilterModal from "./filterModal/main";

//Stylesheet.
import stylesheet from "./stylesheet";

//Constants.
const ANIMATION_CONTROLS_PUSH_DURATION_MS = 500;

const Component = ({ route, navigation }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const loadingScreen = React.useContext(LoadingScreenContext);
    const modal = React.useContext(ModalContext);
    const session = React.useContext(SessionContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ bbox, setBbox ] = React.useState({});
    const [ events, setEvents ] = React.useState([]);

    //Define abort controller.
    const abortController = React.useRef(new AbortController).current;
    React.useEffect(() => {
        return () => abortController.abort();
    }, []);

    //Methods.
    const onInitialised = React.useCallback(() => setIsInitialised(true), []);
    const onPress = React.useCallback((lon, lat, event) => {
        if (event) openEvent(event.id);
    }, []);
    const onMove = React.useCallback((lonMin, latMin, lonMax, latMax) => {
        setBbox({
            lonMin: lonMin,
            latMin: latMin,
            lonMax: lonMax,
            latMax: latMax,
        });
    }, []);
    const refreshEvents = React.useCallback(debounce((bbox, filters) => {
        if ((bbox.lonMax - bbox.lonMin) * (bbox.latMax - bbox.latMin) <= MAX_AREA) {
            send({
                url: `get-events-in-bbox/${ bbox.lonMin }/${ bbox.latMin }/${ bbox.lonMax }/${ bbox.latMax }/${ getTimezoneOffset() }/${ encodeURIComponent(JSON.stringify(filters)) }`,
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                localisation: localisation.locale.key,
                signal: abortController.signal,
                onSuccess: async (response) => {
                    const result = await response.json();
                    setEvents(result.events);
                },
            });
        }
    }, 800, {
        leading: false,
        trailing: true,
    }), []);
    const openEvent = React.useCallback((id) => {
        loadingScreen.queue((finish) => {
            send({
                url: `get-event-for-map/${ id }`,
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                localisation: localisation.locale.key,
                signal: abortController.signal,
                onSuccess: async (response) => {
                    const result = await response.json();
                    const close = () => modal.close();
                    const accept = () => {
                        modal.close();
                        navigation.push("event", { id:result.event.id });
                    };
                    modal.fire({
                        onRequestClose: close,
                        content: (
                            <EventModal
                                event={ result.event }
                                onAccept={ accept }
                                onClose={ close }
                            />
                        )
                    });
                },
                onFinish: finish,
            });
        });
    }, [ localisation.locale.key ]);
    const openFilters = React.useCallback(() => {
        console.log({ where:"openFilters", });
        const close = () => modal.close();
        const accept = (filters) => console.log({ where: "accepted", filters });
        modal.fire({
            onRequestClose: close,
            content: (
                <FilterModal
                    onAccept={ accept }
                    onClose={ close }
                />
            )
        });
    }, []);

    //When coords update, fetch new events.
    React.useEffect(() => {
        if (isInitialised) refreshEvents(bbox, []);
    }, [ isInitialised, bbox ]);

    //Fire interval that checks for events on the map.
    const staticUpdaterRef = React.useRef(null);
    useFocusEffect(React.useCallback(() => {
        if (isInitialised) {
            staticUpdaterRef.current = setInterval(() => refreshEvents(bbox, session.filters), MAP_EVENTS_REFRESH_INTERVAL_MS);
            return () => clearInterval(staticUpdaterRef.current);
        }
    }, [ bbox, session.filters ]));

    //Combine map and controls components.
    const mapRef = React.useRef();
    const zoomIn = React.useCallback(() => mapRef.current.zoomIn(), []);
    const zoomOut = React.useCallback(() => mapRef.current.zoomOut(), []);
    const zoomHome = React.useCallback(() => mapRef.current.zoomHome(), []);

    //Animations.
    const animations = {
        filterControlsPush: React.useRef(new Animated.Value(0)).current,
        zoomControlsPush: React.useRef(new Animated.Value(0)).current,
        homeControlsPush: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        Animated.timing(animations.filterControlsPush, {
            toValue: 1,
            duration: ANIMATION_CONTROLS_PUSH_DURATION_MS,
            delay: ANIMATION_DELAY_STEP_MS,
            easing: Easing.elastic(1.25),
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.zoomControlsPush, {
            toValue: 1,
            duration: ANIMATION_CONTROLS_PUSH_DURATION_MS,
            delay: 2 * ANIMATION_DELAY_STEP_MS,
            easing: Easing.elastic(1.25),
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.homeControlsPush, {
            toValue: 1,
            duration: ANIMATION_CONTROLS_PUSH_DURATION_MS,
            delay: 3 * ANIMATION_DELAY_STEP_MS,
            easing: Easing.elastic(1.25),
            useNativeDriver: true,
        }).start();
    }, []);
    
    //Return.
    let styles = stylesheet(theme, animations);
    return (
        <View style={ styles.container }>

            {/* Map. */}
            <MapView
                ref={ mapRef }
                onInitialised={ onInitialised }
                onPress={ onPress }
                onMove={ onMove }
                markers={ events.map(event => ({
                    type: "event",
                    id: event.id,
                    descId: event.descId,
                    longitude: event.longitude,
                    latitude: event.latitude,
                }))}
            />

            {/* Controls. */}
            <Animated.View style={ styles.filterControlsContainer }>
                <MapControls
                    buttons={[{
                        onPress: openFilters,
                        icon: {
                            source: "FontAwesome5",
                            name: "filter",
                        },
                    }]}
                />
            </Animated.View>
            <Animated.View style={ styles.zoomControlsContainer }>
                <MapControls
                    buttons={[{
                        onPress: zoomIn,
                        icon: {
                            source: "FontAwesome5",
                            name: "plus",
                        },
                    }, {
                        onPress: zoomOut,
                        icon: {
                            source: "FontAwesome5",
                            name: "minus",
                        },
                    }]}
                />
            </Animated.View>
            <Animated.View style={ styles.homeControlsContainer }>
                <MapControls
                    buttons={[{
                        onPress: zoomHome,
                        icon: {
                            source: "FontAwesome5",
                            name: "location-arrow",
                        },
                    }]}
                />
            </Animated.View>

        </View>
    );
}

/*
const filterEvents = (events, filters) => {
    console.log({ where:"filterEvents", events, filters });
    return events.filter(event => {
        //Check filters.
        for (let i = 0, l = filters.length; i < l; i++) {
            switch(filters[i].type) {
                case("eventTypes"):
                        if (!filters[i].value.includes(event.descId)) {
                            return false;
                        }
                    break;
                case("weekdays"):
                        //const executionDatetime = new Date(eve);
                    break;
                case("priceRange"):
                    break;
            }
        }
        //Pass if not problems have been found.
        return true;
    });
};
*/

//Exports.
export default Component;
