
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/error";

/*
    (Optional) align: (string, options are "left", "right", "center"),
*/

const Component = ({ children, align:alignIn = "left" }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme, alignIn);
    return (
        <View style={ styles.container }>
            <Text
                style={ styles.starText }
                selectable={ false }
            >
                *
            </Text>
            <Text
                style={ styles.text }
                selectable={ false }
            >
                { children }
            </Text>
        </View>
    );
}

//Exports.
export default Component;
