
//Framework.
import React from "react";
import { View, Text, Pressable, Animated, Easing } from "react-native";
import { debounce } from "lodash";

//Constants.
import { DEBOUNCE_DURATION_MS } from "@constants/timing";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/def";

const Component = ({ icon:iconIn, label:labelIn, disabled:disabledIn, onPress:onPressIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
        press: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.disable, {
                toValue: disabledIn ? 1 : 0,
                duration: theme.animations.disable.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ disabledIn ]);

    //Methods.
    const onPressDebounce = React.useCallback(
        debounce((method) => method(), DEBOUNCE_DURATION_MS, {
            leading: true,
            trailing: false,
        }),
    []);
    const onPress = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.press, {
            toValue: 1,
            duration: theme.animations.bounce.duration,
            easing: Easing.elastic(theme.animations.bounce.easing),
            useNativeDriver: true,
        }).start(() => animations.press.setValue(0));
        //Fire callback.
        onPressDebounce(() => onPressIn());
    }, [ onPressIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheet(theme, animations, iconIn !== undefined);
    return (
        <Animated.View style={ styles.animationWrapper }>
            <Pressable
                style={ styles.container }
                disabled={ disabledIn }
                onPress={ onPress }
            >
                { iconIn !== undefined && (
                    <View style={ styles.iconContainer }>
                        <Icon
                            source={ iconIn.source }
                            name={ iconIn.name }
                            colour={ theme.colour.primaryText }
                            size={ theme.fonts.text.lineHeights.medium }
                        />
                    </View>
                )}
                <Text
                    style={ styles.label }
                    selectable={ false }
                    numberOfLines={ 1 }
                >
                    { labelIn }
                </Text>
            </Pressable>
        </Animated.View>
    );
}

const getHeight = (theme) => theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small;

//Exports.
export default Component;
export { getHeight }
