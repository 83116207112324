
//Framework.
import React from "react";
import { Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Styling.
import stylesheet from "./stylesheets/searchResults";

/*
    found: (integer),
    total: (integer),
    (Optional) align: (string="center", options are "left", "right", "center"),
*/

const Component = ({ align:alignIn = "center", found:foundIn, total:totalIn, }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme, alignIn);
    return foundIn !== totalIn && (
        <Text
            style={ styles.text }
            selectable={ false }
            numberOfLines={ 1 }
        >
            { localisation.t("components.text.searchResults.label", { found:foundIn, total:totalIn }) }
        </Text>
    );
}

const getHeight = (theme) => {
    return theme.fonts.text.lineHeights.small;
};

//Exports.
export default Component;
export { getHeight };
