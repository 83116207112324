
/*
    value: (any),
    rules: [{
        type: (string),
        (optional) value: (depends on rule type),
        message: (string or method),
    }],
*/

const validate = (value, rules) => {
    //Search for rule failing validation.
    const failedRules = rules.filter((rule) => {
        if (!ruleTypes.hasOwnProperty(rule.type)) {
            console.log(`Error in src/helpers/validate: Rule type '${ rule.type }' is not supported.`);
            return true;
        }
        return !ruleTypes[rule.type](value, rule);
    });
    //If error found, return message.
    let isValid = failedRules.length === 0 ? true : false;
    let errors = failedRules.map(rule => {
        switch(typeof rule.message) {
            case("string"): return rule.message;
            case("function"): return rule.message(value);
        }
    });
    //Otherwise pass.
    return {
        isValid: isValid,
        errors: errors,
    };
};

//Required.
const checkRequired = (value, rule) => ![ "", null, undefined ].includes(value);

//Lengths.
const checkMinLength = (value, rule) => typeof(value) === "string" && value.length >= rule.value;
const checkMaxLength = (value, rule) => typeof(value) === "string" && value.length <= rule.value;
const checkExactLength = (value, rule) => typeof(value) === "string" && value.length === rule.value;

//Data types.
const checkIsInteger = (value, rule) => Number.isInteger(value);
const checkIsString = (value, rule) => typeof value === "string";
const checkIsNumber = (value, rule) => typeof value === "number";

//Numerical comparison.
const checkMin = (value, rule) => typeof(value) === "number" && value >= rule.value;
const checkMax = (value, rule) => typeof(value) === "number" && value <= rule.value;
const checkGreaterThan = (value, rule) => typeof(value) === "number" && value > rule.value;
const checkLessThan = (value, rule) => typeof(value) === "number" && value < rule.value;

//Formats.
const checkIsPhoneNumberPrefix = (value, rule) => typeof(value) === "string" && /^\+([0-9])\d+/g.test(value);
const checkIsPhoneNumber = (value, rule) => typeof(value) === "string" && /^[0-9 -]+$/g.test(value);
const checkIsEmail = (value, rule) => typeof(value) === "string" && /^\S+@\S+\.\S+$/g.test(value);
const checkIsDigits = (value, rule) => typeof(value) === "string" && /^\d+$/.test(value);

//Contains.
const checkBlacklist = (value, rule) => typeof value === "string" && rule.value.find(word => value.includes(word)) === undefined ? true : false;
const checkChoices = (value, rule) => rule.value.includes(value);
const checkReject = (value, rule) => !rule.value.includes(value);

//Match.
const checkMatch = (value, rule) => rule.value === value;

//Rule types object.
const ruleTypes = {
    required: checkRequired,
    minLength: checkMinLength,
    maxLength: checkMaxLength,
    exactLength: checkExactLength,
    isInteger: checkIsInteger,
    isString: checkIsString,
    isNumber: checkIsNumber,
    min: checkMin,
    max: checkMax,
    greaterThan: checkGreaterThan,
    lessThan: checkLessThan,

    isPhoneNumberPrefix: checkIsPhoneNumberPrefix,
    isPhoneNumber: checkIsPhoneNumber,
    isEmail: checkIsEmail,
    isDigits: checkIsDigits,
    blacklist: checkBlacklist,
    choices: checkChoices,
    reject: checkReject,
    match: checkMatch,
};

export default validate;
