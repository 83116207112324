
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Contexts.
import ThemeContext from "@contexts/theme";

//Components.
//import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/title";

/*
    text: (string),
    backgroundColour: (string),
    textColour: (string),
*/

const Component = ({ text, backgroundColour, textColour }) => {
    const theme = React.useContext(ThemeContext);
    
    //Render.
    const styles = stylesheet(theme, backgroundColour, textColour);
    return (
        <View style={ styles.container }>
            <Text
                style={ styles.text }
                adjustsFontSizeToFit={ true }
                selectable={ false }
            >
                { text }
            </Text>
        </View>
    );
};

//Exports.
export default Component;
