
import { StyleSheet } from "react-native";

const stylesheet = function(theme) {
    
    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
        },
        filterContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
