
const bounce = {
    duration: 400,
    easing: 5,
    scaleX: 0.95,
    scaleY: 0.95,
};

const disable = {
    duration: 300,
    opacity: 0.6,
};

const select = {
    duration: 300,
};

//Exports.
export default {
    bounce: bounce,
    disable: disable,
    select: select,
};
