
//import { enGb, daDk } from "@localisation/main";
import enGb from "@localisation/en-GB/main";
import daDk from "@localisation/da-DK/main";

const FALLBACK = "en-GB";

const LOCALES = [{
    key: "en-GB",
    labelKey: "locales.en-GB",
    flags: {
        large: require("@assets/flags/large/gb.png"),
        medium: require("@assets/flags/medium/gb.png"),
        small: require("@assets/flags/small/gb.png"),
    },
}, {
    key: "da-DK",
    labelKey: "locales.da-DK",
    flags: {
        large: require("@assets/flags/large/dk.png"),
        medium: require("@assets/flags/medium/dk.png"),
        small: require("@assets/flags/small/dk.png"),
    },
}];

const TRANSLATIONS = {
    "da-DK": daDk,
    "en-GB": enGb,
};

export {
    FALLBACK,
    LOCALES,
    TRANSLATIONS,
};
