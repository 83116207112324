
//Framework.
import React from "react";
import { View, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Toggle from "@components/inputs/switches/toggle";
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/icons";

/*
    options: {
        on | off: {
            value: (any),
            icon: {
                source: (string),
                name: (string),
            },
            colours: {
                track,
                thumb,
                selected: {
                    background: ("string"),
                    icon: ("string"),
                },
                unselected: {
                    background: ("string"),
                    icon: ("string"),
                },
            },
        },
    }
    value: (any),
    onChange: (method),
    (Optional) disabled: (bool),
*/

//Constants.
const ICON_SIZE = 26;
const ANIMATION_TOGGLE_DURATION_MS = 150;

const Component = ({ options:optionsIn, value:valueIn, onChange:onChangeIn, disabled:disabledIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animations.
    const animations = {
        toggle: React.useRef(new Animated.Value(valueIn === optionsIn.on.value ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (valueIn === optionsIn.on.value) {
                Animated.timing(animations.toggle, {
                    toValue: 1,
                    duration: ANIMATION_TOGGLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.toggle, {
                    toValue: 0,
                    duration: ANIMATION_TOGGLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ valueIn ]);

    //Mark as isInitialised.
    React.useEffect(() => setIsInitialised(true), []);
    
    //Return.
    const styles = stylesheet(theme, animations, optionsIn);
    return (
        <Toggle
            value={ valueIn }
            onChange={ onChangeIn }
            disabled={ disabledIn }
            options={{
                on: {
                    value: optionsIn.on.value,
                    colours: {
                        track: optionsIn.on.colours.track,
                        thumb: optionsIn.on.colours.thumb,
                        selected: optionsIn.on.colours.selected.background,
                        unselected: optionsIn.on.colours.unselected.background,
                    },
                    content: (
                        <View style={ styles.iconContainer }>
                            <Animated.Text style={ styles.onIconWrapper }>
                                <Icon
                                    source={ optionsIn.on.icon.source }
                                    name={ optionsIn.on.icon.name }
                                    size={ ICON_SIZE }
                                />
                            </Animated.Text>
                        </View>
                    ),
                },
                off: {
                    value: optionsIn.off.value,
                    colours: {
                        track: optionsIn.off.colours.track,
                        thumb: optionsIn.off.colours.thumb,
                        selected: optionsIn.off.colours.selected.background,
                        unselected: optionsIn.off.colours.unselected.background,
                    },
                    content: (
                        <View style={ styles.iconContainer }>
                            <Animated.Text style={ styles.offIconWrapper }>
                                <Icon
                                    style={ styles.icon }
                                    source={ optionsIn.off.icon.source }
                                    name={ optionsIn.off.icon.name }
                                    size={ ICON_SIZE }
                                />
                            </Animated.Text>
                        </View>
                    ),
                },
            }}
        />
    );
}

//Exports.
export default Component;
