
import { StyleSheet } from "react-native";

const stylesheet = (theme, typeHeight) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
        },
        inputContainer: {
            padding: theme.spacing.medium / 2,
        },
        repeatingContainer: {
            padding: theme.spacing.medium / 2,
        },
        repeatingItemContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
