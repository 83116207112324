
import { StyleSheet } from "react-native";

const stylesheet = function(theme, size, isLoading) {
    return StyleSheet.create({
        container: {
            width: size,
            height: size,
            borderRadius: size / 2,
            borderWidth: isLoading ? 0 : theme.border.width.medium,
            borderColor: theme.colour.primary,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        image: {
            display: isLoading ? "none" : null,
            width: "100%",
            height: "100%",
        },
        activityIndicator: {
        },
    });
}

//Exports.
export default stylesheet;
