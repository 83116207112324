
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations, numberOfLines) => {

    const height = numberOfLines * theme.fonts.text.lineHeights.medium + 4 * theme.border.width.medium;

    return StyleSheet.create({
        container: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.opacity.disabled ],
            }),
        },
        contentContainer: {
            backgroundColor: theme.colour.primary,
            padding: theme.border.width.medium,
            height: height,
            borderRadius: theme.border.radius.medium,
            display: "flex",
            flexDirection: "row",
        },

        //Input.
        inputContainer: {
            backgroundColor: theme.colour.foreground,
            flexBasis: 0,
            flexGrow: 1,
            borderRadius: theme.border.radius.medium - theme.border.width.medium,
            overflow: "hidden",
        },
        input: {
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.medium,
            paddingLeft: theme.spacing.large,
            paddingRight: theme.spacing.large,
            width: "100%",

            textAlign: "center",
            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },

        //Max length.
        maxLengthContainer: {
            backgroundColor: theme.colour.foreground,
            position: "absolute",
            bottom: 0,
            right: height / 2 + theme.spacing.medium,
            height: theme.border.width.medium,
            padding: theme.spacing.medium,
            paddingTop: 0,
            paddingBottom: 0,
        },
        maxLengthLabel: {
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.border.width.medium,
        },

        //Error.
        errorContainer: {
            padding: theme.spacing.small / 2,
        },
    });
}

//Exports.
export default stylesheet;
