
const validation = {
    required: "%{field} is required.",
    maxLength: "%{field} must not contain more than %{value} characters.",
    minLength: "%{field} must contain at least %{value} characters.",
    exactLength: "%{field} must contain exactly %{value} characters.",
    max: "%{field} must not be greater than %{value}.",
    min: "%{field} must not be less than %{value}.",
    invalid: "%{field} is invalid.",
    //invalidFormat: "%{field} format is not valid.",
    notMatching: "%{field} does not match %{target}.",
    containsForbiddenWord: "%{field} contains a forbidden word.",
    before: "%{field} must be before %{value}.",
    after: "%{field} must be after %{value}.",
};

export default validation;
