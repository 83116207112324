
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            flexBasis: 0,
            flexGrow: 1,
            alignItems: "center",
        },
        iconWrapper: {
            backgroundColor: animations.hover.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)" ],
            }),
            color: animations.hover.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgb(255, 255, 255)", "rgb(0, 0, 0)" ],
            }),
            padding: theme.spacing.medium,
            textAlign: "center",
            width: "100%",
            height: "100%",
        },
        overlay: {
            backgroundColor: animations.hover.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)" ],
            }),
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    });
}

//Exports.
export default stylesheet;
