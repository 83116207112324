
const PHONE_NUMBER_PREFIXES = [
    //United States.
    {
        value: "+1",
        label: {
            native: "United States",
            key: "countries.us",
        },
        flag: {
            small: require("@assets/flags/small/us.png"),
            medium: require("@assets/flags/medium/us.png"),
            large: require("@assets/flags/large/us.png"),
        },
    },
    //France.
    {
        value: "+33",
        label: {
            native: "France",
            key: "countries.france",
        },
        flag: {
            small: require("@assets/flags/small/fr.png"),
            medium: require("@assets/flags/medium/fr.png"),
            large: require("@assets/flags/large/fr.png"),
        },
    },
    //United Kingdom.
    {
        value: "+44",
        label: {
            native: "United Kingdom",
            key: "countries.uk",
        },
        flag: {
            small: require("@assets/flags/small/gb.png"),
            medium: require("@assets/flags/medium/gb.png"),
            large: require("@assets/flags/large/gb.png"),
        },
    },
    //Denmark.
    {
        value: "+45",
        label: {
            native: "Danmark",
            key: "countries.denmark",
        },
        flag: {
            small: require("@assets/flags/small/dk.png"),
            medium: require("@assets/flags/medium/dk.png"),
            large: require("@assets/flags/large/dk.png"),
        },
    },
    //Sweden.
    {
        value: "+46",
        label: {
            native: "Sverige",
            key: "countries.sweden",
        },
        flag: {
            small: require("@assets/flags/small/se.png"),
            medium: require("@assets/flags/medium/se.png"),
            large: require("@assets/flags/large/se.png"),
        },
    },
    //Norway.
    {
        value: "+47",
        label: {
            native: "Norge",
            key: "countries.norway",
        },
        flag: {
            small: require("@assets/flags/small/no.png"),
            medium: require("@assets/flags/medium/no.png"),
            large: require("@assets/flags/large/no.png"),
        },
    },
    //Poland.
    {
        value: "+48",
        label: {
            native: "Poland",
            key: "countries.poland",
        },
        flag: {
            small: require("@assets/flags/small/pl.png"),
            medium: require("@assets/flags/medium/pl.png"),
            large: require("@assets/flags/large/pl.png"),
        },
    },
    //Germany.
    {
        value: "+49",
        label: {
            native: "Germany",
            key: "countries.germany",
        },
        flag: {
            small: require("@assets/flags/small/de.png"),
            medium: require("@assets/flags/medium/de.png"),
            large: require("@assets/flags/large/de.png"),
        },
    },
    //Finland.
    {
        value: "+358",
        label: {
            native: "Finland",
            key: "countries.finland",
        },
        flag: {
            small: require("@assets/flags/small/fi.png"),
            medium: require("@assets/flags/medium/fi.png"),
            large: require("@assets/flags/large/fi.png"),
        },
    },
];

export default PHONE_NUMBER_PREFIXES;
