
//Framework.
import React from "react";

//Components.
import CompanyBackground from "@components/misc/companyBackground";

const Component = ({}) => {
    return (
        <CompanyBackground/>
    );
}

//Exports.
export default Component;
