
const translations = {
    title: "Create event",
    chooseTypeTitle: "Choose a type",
    cancelText: "Cancelling will make you lose all data associated with the event you're creating.",

    repeatFrequencyOption1: "Repeat every week",
    repeatFrequencyOption2: "Repeat every 2nd week",
    repeatFrequencyOption3: "Repeat every 3rd week",
    repeatEventsToBeCreatedText: "Creating %{amount} event(s).",
};

export default translations;
