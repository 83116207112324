
//Framework.
import React from "react";
import { View, Pressable, Text, ScrollView, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
//import WrapperModal from "@components/containers/wrappers/modal";

//Styling.
import stylesheetComponent from "./stylesheets/text/component";
import stylesheetOption from "./stylesheets/text/option";

/*
    options: [{
        value: (any),
        text: (string),
    }],
*/

const Component = ({ value:valueIn, options:optionsIn, disabled:disabledIn, onChange:onChangeIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onOptionPress = React.useCallback((value) => onChangeIn(value), [ onChangeIn ]);

    //Animations.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.disable, {
                toValue: disabledIn ? 1 : 0,
                duration: theme.animations.disable.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ disabledIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheetComponent(theme, animations);
    return (
        <Animated.View style={ styles.container }>
            { optionsIn.map((option, index) => (
                <Option
                    key={ index }
                    text={ option.text }
                    selected={ valueIn === option.value }
                    onPress={ () => onOptionPress(option.value) }
                    disabled={ disabledIn }
                />
            ))}
        </Animated.View>
    );
}

const Option = ({ text:textIn, selected:selectedIn, onPress:onPressIn, disabled:disabledIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animations.
    const animations = {
        select: React.useRef(new Animated.Value(selectedIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.select, {
                toValue: selectedIn ? 1 : 0,
                duration: theme.animations.select.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ selectedIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheetOption(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPressIn }
            disabled={ disabledIn }
        >
            <View style={ styles.wrapper }>
                <Text
                    style={ styles.label }
                    selectable={ false }
                >
                    { textIn }
                </Text>
                <Animated.View style={ styles.selectedOverlay }/>
            </View>
        </Pressable>
    );
}

//Exports.
export default Component;
