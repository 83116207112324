
const eventRole = {
    descIds: {
        administrator: 1,
        maintainer: 2,
        player: 3,
    },
};

export default eventRole;
