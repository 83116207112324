
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
//import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/def";

const Component = ({ text, status }) => {
    const theme = React.useContext(ThemeContext);

    //Get colours from status.
    const [ backgroundColour, textColour ] = React.useMemo(() => {
        switch(status) {
            case("primary"): return [ theme.colour.primary, theme.colour.primaryText ];
            case("secondary"): return [ theme.colour.secondary, theme.colour.secondaryText ];
            case("success"): return [ theme.colour.success, theme.colour.successText ];
            case("warning"): return [ theme.colour.warning, theme.colour.warningText ];
            case("danger"): return [ theme.colour.danger, theme.colour.dangerText ];
        }
    }, [ status ]);

    //Return.
    const styles = stylesheet(theme, backgroundColour, textColour);
    return (
        <View style={ styles.container }>
            <Text
                style={ styles.label }
                selectable={ false }
            >
                { text }
            </Text>
        </View>
    );
}

//Exports.
export default Component;
