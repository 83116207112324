
//Framework.
import React from "react";
import { View, Pressable, Animated } from "react-native";

//Constants.
import EVENT_MODEL from "@constants/models/event";
import { BLACKLISTED_WORDS } from "@constants/validation";

//Helpers.
import validate from "@helpers/validate";
import { unixToDatetime } from "@helpers/time";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import ModalContext from "@contexts/modal";

//Components.
import ModalWrapper from "@components/containers/wrappers/modal";
import CardDialogue from "@components/containers/cards/def/dialogue";

//Children.
import Step0 from "./step0";
import Step1 from "./step1";
import Step2 from "./step2";

//Styling.
import stylesheetComponent from "./stylesheets/main/component";
import stylesheetIndicator from "./stylesheets/main/indicator";

//Constants.
const ANIMATION_INDICATOR_SELECT_DURATION_MS = 300;
const INITIAL_STATE = {
    stepNum: 0,
    maxStepNum: 2,
    isSubmitted: false,
    toBeSubmitted: false,
    isChanged: false,

    //Step 0.
    type: { value:EVENT_MODEL.types.unlisted.id, error:null, },
    title: { value:"", error:null, },
    latitude: { value:null, error:null, },
    longitude: { value:null, error:null, },
    address: { value:"", error:null, },
    datetime: { value:null, error:null, },

    //Step 1.
    playerAmount: { value:"", error:null, },
    skillLevel: { value:0, error:null, },
    price: { value:0, error:null, },
    isPrivate: { value:0, error:null, },

    //Step 2.
    repeat: { value:0, error:null, },
    repeatRules: { value:[], error:null, },
    description: { value:"", error:null, },
};
const getInitialState = (state) => ({ //Necessary to avoid overwriting child objects, because JS is fucking retarded.
    ...INITIAL_STATE,
    type: { ...INITIAL_STATE.type },
    title: { ...INITIAL_STATE.title },
    latitude: { ...INITIAL_STATE.latitude },
    longitude: { ...INITIAL_STATE.longitude },
    address: { ...INITIAL_STATE.address },
    datetime: { ...INITIAL_STATE.datetime, value:getInitialTimestamp() },
    playerAmount: { ...INITIAL_STATE.playerAmount },
    skillLevel: { ...INITIAL_STATE.skillLevel },
    price: { ...INITIAL_STATE.price },
    isPrivate: { ...INITIAL_STATE.isPrivate },
    repeat: { ...INITIAL_STATE.repeat },
    repeatRules: { ...INITIAL_STATE.repeatRules },
    description: { ...INITIAL_STATE.description },
});

const Component = ({ onSubmit:onSubmitIn, onCancel:onCancelIn, onShow:onShowIn, onHide:onHideIn, state:stateIn = getInitialState(INITIAL_STATE) }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const modal = React.useContext(ModalContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ isAnimating, setIsAnimating ] = React.useState(false);

    //Validation.
    const checkStep0 = React.useCallback((title, type, latitude, longitude, address, datetime) => {
        const validations = {
            //Title.
            title: validate(title, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.title.validation"),
                }),
            }, {
                type: "maxLength",
                value: EVENT_MODEL.title.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.event.title.validation"),
                    value: EVENT_MODEL.title.maxLength,
                }),
            }, {
                type: "blacklist",
                value: BLACKLISTED_WORDS,
                message: localisation.t("validation.containsForbiddenWord", {
                    field: localisation.t("models.event.title.validation"),
                }),
            }]),
            //Type.
            type: validate(type, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.type.validation"),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.type.min,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.type.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.type.max,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.type.validation"),
                }),
            }]),
            //Address.
            address: validate(address, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.address.validation"),
                }),
            }, {
                type: "maxLength",
                value: EVENT_MODEL.address.maxLength,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.address.validation"),
                    value: EVENT_MODEL.address.maxLength,
                }),
            }]),
            //Longitude.
            longitude: validate(longitude, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.longitude.validation"),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.longitude.min,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.longitude.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.longitude.max,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.longitude.validation"),
                }),
            }]),
            //Latitude.
            latitude: validate(latitude, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.latitude.validation"),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.latitude.min,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.latitude.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.latitude.max,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.latitude.validation"),
                }),
            }]),
            //Datetime.
            datetime: validate(datetime, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.datetime.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.datetime.getLatest(),
                message: localisation.t("validation.before", {
                    field: localisation.t("models.event.datetime.validation"),
                    value: unixToDatetime(EVENT_MODEL.datetime.getLatest()),
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.datetime.getEarliest(),
                message: localisation.t("validation.after", {
                    field: localisation.t("models.event.datetime.validation"),
                    value: unixToDatetime(EVENT_MODEL.datetime.getEarliest()),
                }),
            }]),
        };
        //Return.
        return {
            isValid:
                validations.title.isValid &&
                validations.type.isValid &&
                validations.address.isValid &&
                validations.longitude.isValid &&
                validations.latitude.isValid &&
                validations.datetime.isValid,
            errors: {
                title: validations.title.isValid ? null : validations.title.errors[0],
                type: validations.type.isValid ? null : validations.type.errors[0],
                address: validations.address.isValid ? null : validations.address.errors[0],
                longitude: validations.longitude.isValid ? null : validations.longitude.errors[0],
                latitude: validations.latitude.isValid ? null : validations.latitude.errors[0],
                datetime: validations.datetime.isValid ? null : validations.datetime.errors[0],
            },
        };
    }, []);
    const checkStep1 = React.useCallback((playerAmount, skillLevel, price, isPrivate) => {
        const validations = {
            //Player amount.
            playerAmount: validate(playerAmount, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.playerAmount.validation"),
                }),
            }, {
                type: "isInteger",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.playerAmount.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.playerAmount.max,
                message: localisation.t("validation.max", {
                    field: localisation.t("models.event.playerAmount.validation"),
                    value: EVENT_MODEL.playerAmount.max,
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.playerAmount.min,
                message: localisation.t("validation.min", {
                    field: localisation.t("models.event.playerAmount.validation"),
                    value: EVENT_MODEL.playerAmount.min,
                }),
            }]),
            //Skill level.
            skillLevel: validate(skillLevel, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.skillLevel.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.skillLevel.max,
                message: localisation.t("validation.max", {
                    field: localisation.t("models.event.skillLevel.validation"),
                    value: EVENT_MODEL.skillLevel.max,
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.skillLevel.min,
                message: localisation.t("validation.min", {
                    field: localisation.t("models.event.skillLevel.validation"),
                    value: EVENT_MODEL.skillLevel.min,
                }),
            }]),
            //Price.
            price: validate(price, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.price.validation"),
                }),
            }, {
                type: "max",
                value: EVENT_MODEL.price.max,
                message: localisation.t("validation.max", {
                    field: localisation.t("models.event.price.validation"),
                    value: EVENT_MODEL.price.max,
                }),
            }, {
                type: "min",
                value: EVENT_MODEL.price.min,
                message: localisation.t("validation.min", {
                    field: localisation.t("models.event.price.validation"),
                    value: EVENT_MODEL.price.min,
                }),
            }]),
            //Is private.
            isPrivate: validate(isPrivate, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.isPrivate.validation"),
                }),
            }, {
                type: "choices",
                value: EVENT_MODEL.isPrivate.choices,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.isPrivate.validation"),
                }),
            }]),
        };
        //Return.
        return {
            isValid:
                validations.playerAmount.isValid &&
                validations.skillLevel.isValid &&
                validations.price.isValid &&
                validations.isPrivate.isValid,
            errors: {
                playerAmount: validations.playerAmount.isValid ? null : validations.playerAmount.errors[0],
                skillLevel: validations.skillLevel.isValid ? null : validations.skillLevel.errors[0],
                price: validations.price.isValid ? null : validations.price.errors[0],
                isPrivate: validations.isPrivate.isValid ? null : validations.isPrivate.errors[0],
            },
        };
    }, []);
    const checkStep2 = React.useCallback((repeat, repeatRules, description) => {
        const validations = {
            //Repeat.
            repeat: validate(repeat, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.title.validation"),
                }),
            }, {
                type: "choices",
                value: EVENT_MODEL.repeat.choices,
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.event.repeat.validation"),
                }),
            }]),
            //Repeat rules.
            repeatRules: validate(repeatRules, [/*{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.event.title.validation"),
                }),
            }, {
                type: "maxLength",
                value: EVENT_MODEL.title.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.event.title.validation"),
                    value: EVENT_MODEL.title.maxLength,
                }),
            }, {
                type: "blacklist",
                value: BLACKLISTED_WORDS,
                message: localisation.t("validation.containsForbiddenWord", {
                    field: localisation.t("models.event.title.validation"),
                }),
            }*/]),
            //Description.
            description: validate(description, [{
                type: "maxLength",
                value: EVENT_MODEL.description.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.event.description.validation"),
                    value: EVENT_MODEL.description.maxLength,
                }),
            }]),
        };
        //Return.
        return {
            isValid:
                validations.repeat.isValid &&
                validations.repeatRules.isValid &&
                validations.description.isValid,
            errors: {
                repeat: validations.repeat.isValid ? null : validations.repeat.errors[0],
                repeatRules: validations.repeatRules.isValid ? null : validations.repeatRules.errors[0],
                description: validations.description.isValid ? null : validations.description.errors[0],
            },
        };
    }, []);

    //Form state.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        let newState = { ...oldState }, isValid, errors, result;
        switch(action.type) {
            case("onChange"):
                //Update value.
                newState.isChanged = true;
                switch(action.input) {
                    case("type"):
                        newState.type.value = action.value;
                        break;
                    case("title"):
                        newState.title.value = action.value;
                        break;
                    case("location"):
                        newState.latitude.value = action.latitude;
                        newState.longitude.value = action.longitude;
                        newState.address.value = action.address;
                        break;
                    case("datetime"):
                        newState.datetime.value = action.value;
                        break;
                    case("playerAmount"):
                        newState.playerAmount.value = action.value;
                        break;
                    case("skillLevel"):
                        newState.skillLevel.value = action.value;
                        break;
                    case("price"):
                        newState.price.value = action.value;
                        break;
                    case("isPrivate"):
                        newState.isPrivate.value = action.value;
                        break;
                    case("repeat"):
                        newState.repeat.value = action.value;
                        break;
                    case("repeatRules"):
                        newState.repeatRules.value = action.value;
                        break;
                    case("description"):
                        newState.description.value = action.value;
                        break;
                }
                //Validate.
                if (oldState.isSubmitted) {
                    switch(oldState.stepNum) {
                        case(0):
                            result = checkStep0(
                                newState.title.value,
                                newState.type.value,
                                newState.longitude.value,
                                newState.latitude.value,
                                newState.address.value,
                                newState.datetime.value,
                            );
                            isValid = result.isValid;
                            errors = result.errors;
                            newState.title.error = errors.title;
                            newState.type.error = errors.type;
                            newState.longitude.error = errors.longitude;
                            newState.latitude.error = errors.latitude;
                            newState.address.error = errors.address;
                            newState.datetime.error = errors.datetime;
                            break;
                        case(1):
                            result = checkStep1(
                                newState.playerAmount.value,
                                newState.skillLevel.value,
                                newState.price.value,
                                newState.isPrivate.value,
                            );
                            isValid = result.isValid;
                            errors = result.errors;
                            newState.playerAmount.error = errors.playerAmount;
                            newState.skillLevel.error = errors.skillLevel;
                            newState.price.error = errors.price;
                            newState.isPrivate.error = errors.isPrivate;
                            break;
                        case(2):
                            result = checkStep2(
                                newState.repeat.value,
                                newState.repeatRules.value,
                                newState.description.value,
                            );
                            isValid = result.isValid;
                            errors = result.errors;
                            newState.repeat.error = errors.repeat;
                            newState.repeatRules.error = errors.repeatRules;
                            newState.description.error = errors.description;
                            break;
                    }
                }
                //Return.
                return newState;
            case("goPrev"): return { ...oldState, stepNum:oldState.stepNum - 1 };
            case("goNext"):
                //Validate.
                newState.isSubmitted = true;
                switch(oldState.stepNum) {
                    case(0):
                        result = checkStep0(
                            newState.title.value,
                            newState.type.value,
                            newState.longitude.value,
                            newState.latitude.value,
                            newState.address.value,
                            newState.datetime.value,
                        );
                        isValid = result.isValid;
                        errors = result.errors;
                        newState.title.error = errors.title;
                        newState.type.error = errors.type;
                        newState.longitude.error = errors.longitude;
                        newState.latitude.error = errors.latitude;
                        newState.address.error = errors.address;
                        newState.datetime.error = errors.datetime;
                        if (isValid) {
                            newState.stepNum = oldState.stepNum + 1;
                            newState.isSubmitted = false;
                        }
                        break;
                    case(1):
                        result = checkStep1(
                            newState.playerAmount.value,
                            newState.skillLevel.value,
                            newState.price.value,
                            newState.isPrivate.value,
                        );
                        isValid = result.isValid;
                        errors = result.errors;
                        newState.playerAmount.error = errors.playerAmount;
                        newState.skillLevel.error = errors.skillLevel;
                        newState.price.error = errors.price;
                        newState.isPrivate.error = errors.isPrivate;
                        if (isValid) {
                            newState.stepNum = oldState.stepNum + 1;
                            newState.isSubmitted = false;
                        }
                        break;
                    case(2):
                        result = checkStep2(
                            newState.repeat.value,
                            newState.repeatRules.value,
                            newState.description.value,
                        );
                        isValid = result.isValid;
                        errors = result.errors;
                        newState.repeat.error = errors.repeat;
                        newState.repeatRules.error = errors.repeatRules;
                        newState.description.error = errors.description;
                        if (isValid) {
                            newState.toBeSubmitted = true;
                        }
                        break;
                }
                //Return.
                return newState;
        }
    }, stateIn);
    React.useEffect(() => {
        if (state.toBeSubmitted) {
            onSubmitIn(
                show,
                state.title.value,
                state.type.value,
                state.latitude.value,
                state.longitude.value,
                state.address.value,
                state.datetime.value,
                state.playerAmount.value,
                state.skillLevel.value,
                state.price.value,
                state.isPrivate.value,
                state.repeat.value,
                state.repeatRules.value,
                state.description.value
            );
        }
    }, [ state.toBeSubmitted ]);

    //Methods.
    const show = React.useCallback(({
        type = state.type.value,
        title = state.title.value,
        latitude = state.latitude.value,
        longitude = state.longitude.value,
        address = state.address.value,
        datetime = state.datetime.value,
        playerAmount = state.playerAmount.value,
        skillLevel = state.skillLevel.value,
        price = state.price.value,
        isPrivate = state.isPrivate.value,
        repeat = state.repeat.value,
        repeatRules = state.repeatRules.value,
        description = state.description.value,
    } = {}) => {
        onShowIn(onSubmitIn, onCancelIn, onShowIn, onHideIn, {
            ...state,
            toBeSubmitted: false,
            isChanged: true,
            type: { value:type, error:null, },
            title: { value:title, error:null, },
            latitude: { value:latitude, error:null, },
            longitude: { value:longitude, error:null, },
            address: { value:address, error:null, },
            datetime: { value:datetime, error:null, },
            playerAmount: { value:playerAmount, error:null, },
            skillLevel: { value:skillLevel, error:null, },
            price: { value:price, error:null, },
            isPrivate: { value:isPrivate, error:null, },
            repeat: { value:repeat, error:null, },
            repeatRules: { value:repeatRules, error:null, },
            description: { value:description, error:null, },
        });
    }, [ state ]);
    const hide = React.useCallback(onHideIn, [ onHideIn ]);
    const onCancelPress = React.useCallback(() => onCancelIn(!state.isChanged, show), [ state, onCancelIn ]);
    const onPrevPress = React.useCallback(() => dispatch({ type:"goPrev" }), []);
    const onNextPress = React.useCallback(() => dispatch({ type:"goNext" }), []);

    //Animation.
    const animations = {
        stepNum: React.useRef(new Animated.Value(state.stepNum)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            setIsAnimating(true);
            Animated.timing(animations.stepNum, {
                toValue: state.stepNum,
                duration: ANIMATION_INDICATOR_SELECT_DURATION_MS,
                useNativeDriver: true,
            }).start(() => setIsAnimating(false));
        }
    }, [ state.stepNum ]);

    //Mark as initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetComponent(theme, animations);
    return (
        <ModalWrapper size="medium">
            <CardDialogue
                title={ localisation.t("screens.home.createEventModal.title") }
                status="primary"
                buttons={[{
                    icon: {
                        source: "FontAwesome5",
                        name: "arrow-left",
                        colour: theme.colour.danger,
                    },
                    onPress: onPrevPress,
                    disabled: isAnimating || state.stepNum === 0,
                }, {
                    icon: {
                        source: "FontAwesome5",
                        name: "times",
                        colour: theme.colour.danger,
                    },
                    onPress: onCancelPress,
                    disabled: isAnimating,
                }, {
                    icon: {
                        source: "FontAwesome5",
                        name: state.stepNum === state.maxStepNum ? "check" : "arrow-right",
                        colour: theme.colour.success,
                    },
                    onPress: onNextPress,
                    disabled: isAnimating,
                }]}
            >
                <View style={ styles.container }>

                    {/* Indicators. */}
                    <View style={ styles.indicatorsContainer }>
                        <Indicator
                            selected={ state.stepNum >= 0 }
                        />
                        <Indicator
                            selected={ state.stepNum >= 1 }
                        />
                        <Indicator
                            selected={ state.stepNum >= 2 }
                        />
                    </View>

                    {/* Step 0. */}
                    <Animated.View style={ styles.stepsContainer }>
                        <View style={ styles.stepContainer }>
                            <Step0
                                disabled={ isAnimating || (state.stepNum !== 0) }
                                state={ state }
                                dispatch={ dispatch }
                                onShow={ show }
                                onHide={ hide }
                                show={ show }
                                hide={ hide }
                            />
                        </View>

                        {/* Step 1. */}
                        <View style={ styles.stepContainer }>
                            <Step1
                                disabled={ isAnimating || (state.stepNum !== 1) }
                                state={ state }
                                dispatch={ dispatch }
                                onShow={ show }
                                onHide={ hide }
                            />
                        </View>

                        {/* Step 2. */}
                        <View style={ styles.stepContainer }>
                            <Step2
                                disabled={ isAnimating || (state.stepNum !== 2) }
                                state={ state }
                                dispatch={ dispatch }
                                onShow={ show }
                                onHide={ hide }
                            />
                        </View>
                    </Animated.View>

                </View>
            </CardDialogue>
        </ModalWrapper>
    );
}

const Indicator = ({ selected }) => {
    const theme = React.useContext(ThemeContext);

    //Animation.
    const animations = {
        select: React.useRef(new Animated.Value(selected ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (selected) {
            Animated.timing(animations.select, {
                toValue: 1,
                duration: ANIMATION_INDICATOR_SELECT_DURATION_MS,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(animations.select, {
                toValue: 0,
                duration: ANIMATION_INDICATOR_SELECT_DURATION_MS,
                useNativeDriver: true,
            }).start();
        }
    }, [ selected ]);

    //Return.
    const styles = stylesheetIndicator(theme, animations);
    return (
        <View style={ styles.container }>
            <Animated.View style={ styles.indicator }/>
        </View>
    );
}

const getInitialTimestamp = () => {
    const timestamp = new Date();
    timestamp.setMinutes(0);
    timestamp.setSeconds(0);
    timestamp.setMilliseconds(0);
    timestamp.setHours(timestamp.getHours() + 2);
    return timestamp.getTime();
};

//Exports.
export default Component;
