
//Framework.
import React from "react";
import { View, Text, TextInput } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import TextError from "@components/text/error";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

/*
    label: (string),
    value: (string),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) maxLength: (integer),
    (Optional) placeholder: (string),
    (Optional) inputMode: (string: text),
    (Optional) keyboardType: (string: "default"),
    (Optional) textContentType: (string),
    (Optional) returnKeyType: (string: "done"),
    (Optional) sensitive: (bool: false),
    (Optional) onChange: (method),
    (Optional) onBlur: (method),
    (Optional) left: (content),
    (Optional) pad: {
        (Optional) left: (integer),
        (Optional) right: (integer),
    },
    (Optional) error: (string),
*/

const Component = ({
    label: labelIn,
    value: valueIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    maxLength: maxLengthIn,
    placeholder: placeholderIn,
    inputMode: inputModeIn = "text",
    keyboardType: keyboardTypeIn = "default",
    textContentType: textContentTypeIn,
    returnKeyType: returnKeyTypeIn = "done",
    sensitive: sensitiveIn = false,
    onChange: onChangeIn,
    onBlur: onBlurIn,
    left: leftIn,
    pad: padIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);

    //Methods.
    const onChange = React.useCallback((e) => {
        onChangeIn(e.nativeEvent.text, e.nativeEvent.data);
    }, [ onChangeIn ]);
    
    //Render.
    const styles = stylesheet(theme, padIn);
    return (
        <View style={ styles.container }>
            <View style={ styles.contentContainer }>
                {/* Label. */}
                <Text
                    selectable={ false }
                    style={ styles.label }
                >
                    { labelIn }
                </Text>
                {/* Input. */}
                <View style={ styles.body }>
                    { leftIn !== undefined && (
                        <View style={ styles.leftContainer }>
                            { leftIn }
                        </View>
                    )}
                    <View style={ styles.inputContainer }>
                        <TextInput
                            style={ styles.input }
                            value={ valueIn }
                            editable={ !disabledIn && editableIn }
                            maxLength={ maxLengthIn }
                            placeholder={ placeholderIn }
                            inputMode={ inputModeIn }
                            keyboardType={ keyboardTypeIn }
                            textContentType={ textContentTypeIn }
                            returnKeyType={ returnKeyTypeIn }
                            secureTextEntry={ sensitiveIn }
                            onChange={ onChangeIn ? onChange : null }
                            onBlur={ onBlurIn }
                        />
                    </View>
                </View>
            </View>
            { !errorIn ? null : (
                <View style={ styles.errorContainer }>
                    <TextError>
                        { errorIn }
                    </TextError>
                </View>
            )}
        </View>
    );
}

//Exports.
export default Component;
