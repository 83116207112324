
import { StyleSheet } from "react-native";

const stylesheet = (theme, transitionSize) => {
    return StyleSheet.create({
        container: {
            maxHeight: "100%",
        },
        topGradient: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: transitionSize,
        },
        bottomGradient: {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: transitionSize,
        },
    });
}

//Exports.
export default stylesheet;
