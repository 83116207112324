
//Framework.
import React from "react";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import EventMinified from "@components/misc/events/minified";

//Styling.
//import stylesheet from "./stylesheets/joinModal";

const EventModal = ({ event:eventIn, onClose:onCloseIn, onAccept:onAcceptIn }) => {

    //Render.
    return (
        <WrapperModal size="small">
            <EventMinified
                event={ eventIn }
                buttons={[{
                    icon: {
                        source: "FontAwesome5",
                        name: "times",
                        colour: theme.colour.danger,
                    },
                    onPress: onCloseIn,
                }, {
                    icon: {
                        source: "FontAwesome5",
                        name: "check",
                        colour: theme.colour.success,
                    },
                    onPress: onAcceptIn,
                }]}
            />
        </WrapperModal>
    );
}

//Exports.
export default EventModal;
