
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations, hidden) => {

    const size = theme.fonts.text.lineHeights.small + 2 * theme.spacing.tiny;

    return StyleSheet.create({
        container: {
            backgroundColor: animations.invert.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.primary, theme.colour.primaryText ],
            }),
            transform: [
                {
                    scaleX: animations.shake.interpolate({
                        inputRange: [ 0, .05, 1 ],
                        outputRange: [ 1, 1.25, 1 ],
                    }),
                }, {
                    scaleY: animations.shake.interpolate({
                        inputRange: [ 0, .05, 1 ],
                        outputRange: [ 1, 1.1, 1 ],
                    }),
                }
            ],
            opacity: animations.visible,
            display: hidden && "none",
            width: size,
            height: size,
            borderRadius: size / 2,
            overflow: "hidden",

            //Shadow.
            shadowColor: theme.colour.shadow,
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0.6,//theme.opacity.shadow,
            shadowRadius: 10,
        },
        label: {
            color: animations.invert.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.primaryText, theme.colour.primary ],
            }),
            padding: theme.spacing.tiny,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.small,
        },
    });
}

//Exports.
export default stylesheet;
