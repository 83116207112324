
//Framework.
import React from "react";
import { Text, Pressable, Animated } from "react-native";
import { debounce } from "lodash";

//Constants.
import { DEBOUNCE_DURATION_MS } from "@constants/timing";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/unauthenticated";

//Constants.
const HEIGHT = 50;
const ANIMATION_DISABLE_DURATION_MS = 300;
const ANIMATION_HOVER_DURATION_MS = 200;
const ANIMATION_PRESS_DURATION_MS = 300;

const Unauthenticated = ({ label, disabled, onPress:onPressIn, backgroundColour, textColour }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(disabled ? 1 : 0)).current,
        hover: React.useRef(new Animated.Value(0)).current,
        press: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.disable, {
                toValue: disabled ? 1 : 0,
                duration: theme.animations.disable.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ disabled ]);

    //Methods.
    const onPressDebounce = React.useCallback(
        debounce((method) => method(), DEBOUNCE_DURATION_MS, {
            leading: true,
            trailing: false,
        }),
    []);
    const onPress = React.useCallback(() => {
        //Animate.
        animations.press.setValue(0);
        Animated.timing(animations.press, {
            toValue: 1,
            duration: ANIMATION_PRESS_DURATION_MS,
            useNativeDriver: true,
        }).start();
        //Fire callback.
        onPressDebounce(() => onPressIn());
    }, [ onPressIn ]);
    const onHoverIn = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.hover, {
            toValue: 1,
            duration: ANIMATION_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    }, []);
    const onHoverOut = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.hover, {
            toValue: 0,
            duration: ANIMATION_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    }, []);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheet(theme, animations, backgroundColour, textColour);
    return (
        <Animated.View style={ styles.disableWrapper }>
            <Animated.View style={ styles.blinkWrapper }>
                <Pressable
                    style={ styles.container }
                    disabled={ disabled }
                    onPress={ onPress }
                    onHoverIn={ onHoverIn }
                    onHoverOut={ onHoverOut }
                >
                    <Text
                        style={ styles.label }
                        selectable={ false }
                    >
                        { label }
                    </Text>
                </Pressable>
            </Animated.View>
        </Animated.View>
    );
}

//Exports.
export default Unauthenticated;
