
import { StyleSheet } from "react-native";

const stylesheet = (theme, iconSize) => {

    const buttonSize = iconSize + 2 * theme.spacing.small;

    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.primary,
            borderWidth: theme.border.width.medium,
            borderColor: theme.colour.primaryText,
            borderRadius: (theme.border.width.medium + buttonSize) / 2,
            overflow: "hidden",
        },
        button: {
            width: buttonSize,
            height: buttonSize,
            padding: theme.spacing.small,
        },
    });
}

//Exports.
export default stylesheet;
