
import { StyleSheet } from "react-native";

const stylesheet = (theme, align) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start",
        },
        starText: {
            color: theme.colour.danger,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,
            paddingRight: theme.spacing.small,
            lineHeight: theme.fonts.text.lineHeights.small,
        },
        text: {
            color: theme.colour.danger,
            textAlign: align,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.small,
        },
    });
}

//Exports.
export default stylesheet;
