
import map from "./map";
import events from "./events";
import social from "./social";
import notifications from "./notifications";

import profile from "./profile";
import friends from "./friends";
import teams from "./teams";
import settings from "./settings";
import personalSettings from "./personalSettings";
import notificationsSettings from "./notificationsSettings";
import paymentSettings from "./paymentSettings";
import aboutUs from "./aboutUs";

import createEventModal from "./createEventModal";

const translations = {
    title: "Home",

    createEventFailedTitle: "Failure",
    createEventFailedText: "Your event could not be created.",
    createEventSuccessText: "Event created!",

    map: map,
    events: events,
    social: social,
    notifications: notifications,

    profile: profile,
    friends: friends,
    teams: teams,
    settings: settings,
    personalSettings: personalSettings,
    notificationsSettings: notificationsSettings,
    paymentSettings: paymentSettings,
    aboutUs: aboutUs,

    createEventModal: createEventModal,
};

export default translations;
