
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.animations.disable.opacity ],
            }),
            borderWidth: theme.border.width.medium,
            borderRadius: theme.border.radius.medium,
            borderColor: theme.colour.primary,
            padding: theme.spacing.medium / 2,
            paddingLeft: theme.spacing.large / 2,
            paddingRight: theme.spacing.large / 2,
        },
    });
}

//Exports.
export default stylesheet;
