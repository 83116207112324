
//General.
const primary = "rgb(206, 0, 0)";
const primaryText = "rgb(255, 255, 255)";
const secondary = "rgb(255, 255, 255)";
const secondaryText = "rgb(10, 10, 10)";

//Status.
const success = "rgb(103, 223, 84)";
const successText = "rgb(255, 255, 255)";
const info = "rgb(73, 144, 226)";
const infoText = "rgb(255, 255, 255)";
const warning = "rgb(238, 152, 7)";
const warningText = "rgb(255, 255, 255)";
const danger = "rgb(208, 1, 27)";
const dangerText = "rgb(255, 255, 255)";

//Misc.
const background = "rgb(229,229,229)";
const foreground = "rgb(255,255,255)";
const gold = "rgb(255,215,0)";
const text = "rgb(69, 69, 69)";
const textDiscrete = "rgba(69, 69, 69, 0.6)";
const backdrop = "rgba(0, 0, 0, 0.6)";
const foregroundSplitter = "rgba(0, 0, 0, 0.1)";
const shadow = "rgb(0, 0, 0)";

//Genders.
const male = "rgb(52, 125, 193)";
const female = "rgb(204, 101, 148)";

//Specific.
const unauthenticatedCard = "rgba(0, 0, 0, 0.9)";
const unauthenticatedCardText = "rgb(255, 255, 255)";
const switchTrack = "rgb(190,190,190)";
const switchThumb = "rgb(240,240,240)";

//Exports.
export default {
    primary,
    primaryText,
    secondary,
    secondaryText,
    success,
    successText,
    info,
    infoText,
    warning,
    warningText,
    danger,
    dangerText,
    background,
    foreground,
    gold,
    text,
    textDiscrete,
    backdrop,
    foregroundSplitter,
    shadow,
    male,
    female,
    unauthenticatedCard,
    unauthenticatedCardText,
    switchTrack,
    switchThumb,
};
