
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations, sizeIn) => {
    
    //Return.
    return StyleSheet.create({
        container: {
            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        backgroundContainer: {
        },
        foregroundContainer: {
            opacity: animations.opacity,
            width: animations.width,
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden",
        },
    });
}

//Exports.
export default stylesheet;
