
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations, optionHeight) => {

    const flagSize = optionHeight - theme.spacing.medium;

    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium / 2,
            height: optionHeight,

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        selectWrapper: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "transparent", theme.colour.primary ],
            }),
            borderRadius: optionHeight / 2,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
        },
        flagContainer: {
            padding: 2,
            flexBasis: flagSize,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        flagWrapper: {
            backgroundColor: theme.colour.foreground,
            padding: theme.spacing.small,
            borderRadius: flagSize / 2,
            width: "100%",
            height: "100%",
        },
        flag: {
            width: "100%",
            height: "100%",
        },
        labelContainer: {
            flexBasis: 0,
            flexShrink: 1,
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
        },
        label: {
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.text, theme.colour.primaryText ],
            }),
            padding: theme.spacing.medium / 2,
            paddingRight: theme.spacing.medium / 2 + flagSize,
            width: "100%",
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
        },
    });
}

//Exports.
export default stylesheet;
