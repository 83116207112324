
import { StyleSheet } from "react-native";

const stylesheet = (theme, colour, align) => {
    return StyleSheet.create({
        text: {
            color: colour,
            textAlign: align,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
    });
}

//Exports.
export default stylesheet;
