
//General.
const tiny = 4;
const small = 6;
const medium = 10;
const large = 20;

//Exports.
export default {
    tiny: tiny,
    small: small,
    medium: medium,
    large: large,
};
