
//Framework.
import React from "react";

//Icons.
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import Feather from "react-native-vector-icons/Feather";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Fontisto from "react-native-vector-icons/Fontisto";
import Foundation from "react-native-vector-icons/Foundation";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Octicons from "react-native-vector-icons/Octicons";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";

//Styling.
import stylesheet from "./stylesheets/icon";

/*
    source: (string),
    name: (string),
    colour: (string),
    size: (integer),
*/

const Component = ({ source, name, colour, size }) => {

    //Render.
    const styles = stylesheet(size);
    switch(source) {
        case("AntDesign"): return (<AntDesign name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Entypo"): return (<Entypo name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("EvilIcons"): return (<EvilIcons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Feather"): return (<Feather name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("FontAwesome"): return (<FontAwesome name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("FontAwesome5"): return (<FontAwesome5 name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Fontisto"): return (<Fontisto name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Foundation"): return (<Foundation name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Ionicons"): return (<Ionicons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("MaterialCommunityIcons"): return (<MaterialCommunityIcons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("MaterialIcons"): return (<MaterialIcons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("Octicons"): return (<Octicons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
        case("SimpleLineIcons"): return (<SimpleLineIcons name={ name } size={ size } color={ colour } style={ styles.icon }/>);
    }
}

//Exports.
export default Component;
