
import { StyleSheet, Animated } from "react-native";

const stylesheet = (theme, animations, hasIcon) => {

    const height = theme.fonts.text.lineHeights.medium + 2 * theme.spacing.small;

    return StyleSheet.create({
        animationWrapper: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.animations.disable.opacity ],
            }),
            transform: [
                {
                    scaleX: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleX, 1 ],
                    }),
                },{
                    scaleY: animations.press.interpolate({
                        inputRange: [ 0, 0.5, 1 ],
                        outputRange: [ 1, theme.animations.bounce.scaleY, 1 ],
                    }),
                }
            ],
            height: height,
            borderRadius: height / 2,
        },
        container: {
            backgroundColor: theme.colour.primary,
            height: height,
            borderRadius: height / 2,
            padding: theme.spacing.small / 2,
            flexDirection: "row",
        },
        iconContainer: {
            padding: theme.spacing.small / 2,
            flexBasis: theme.fonts.text.lineHeights.medium + theme.spacing.small,
        },
        label: {
            padding: theme.spacing.small / 2,
            paddingRight: theme.spacing.small / 2 + (hasIcon && (theme.fonts.text.lineHeights.medium + theme.spacing.small)),
            color: theme.colour.primaryText,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeights: theme.fonts.text.lineHeights.medium,
            textAlign: "center",
            flexBasis: 0,
            flexGrow: 1,
        },
    });
}

//Exports.
export default stylesheet;
