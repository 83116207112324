
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        body: {
            display: "flex",
            flexDirection: "row",
        },

        //Years.
        yearsContainer: {
            backgroundColor: theme.colour.primary,
            flexBasis: 100,
        },
        yearsScroller: {
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.medium / 2,
            paddingLeft: theme.spacing.large / 2,
            paddingRight: theme.spacing.large / 2,
        },
        yearsContent: {
        },

        //Dates.
        datesContainer: {
            flexBasis: 0,
            flexGrow: 1,
            height: 350,
        },

        //Dates body.
        datesBodyContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        weekdaysContainer: {
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing.small / 2,
            borderBottomWidth: theme.border.width.small,
            borderColor: theme.colour.foregroundSplitter,
        },
        weekday: {
            flexBasis: 0,
            flexGrow: 1,
            textAlign: "center",
            padding: theme.spacing.small / 2,
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
        weeksContainer: {
            flexGrow: 1,
            display: "flex",

        },
        weekRow: {
            display: "flex",
            flexDirection: "row",
            flexBasis: 0,
            flexGrow: 1,
        },
        dateContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
    });
}

//Exports.
export default stylesheet;
