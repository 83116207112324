
//Framework.
import React from "react";
import { ScrollView, View, Pressable, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import DefHeader from "@components/misc/headers/def";
import DefButton, { getHeight as getDefButtonHeight } from "@components/buttons/def";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Methods.
    const onPress = React.useCallback((path) => {
        switch(path) {
            case("personal"): return navigation.push("personalSettings");
            case("notifications"): return navigation.push("notificationsSettings");
            case("payment"): return navigation.push("paymentSettings");
            case("aboutUs"): return navigation.push("aboutUs");
        }
    }, []);

    //Return.
    const styles = stylesheet(theme, getDefButtonHeight(theme));
    return (
        <ScrollView
            contentContainerStyle={{ flexGrow: 1, }}
        >
            <ScreenWrapper>
                <View style={ styles.container }>

                    {/* Personal. */}
                    <View style={ styles.itemContainer }>
                        <DefButton
                            icon={{
                                source: "FontAwesome5",
                                name: "user",
                            }}
                            label={ localisation.t("screens.home.personalSettings.title") }
                            onPress={ () => onPress("personal") }
                        />
                    </View>

                    {/* Notifications. */}
                    <View style={ styles.itemContainer }>
                        <DefButton
                            icon={{
                                source: "FontAwesome5",
                                name: "bell",
                            }}
                            label={ localisation.t("screens.home.notificationsSettings.title") }
                            onPress={ () => onPress("notifications") }
                        />
                    </View>

                    {/* Payment. */}
                    <View style={ styles.itemContainer }>
                        <DefButton
                            icon={{
                                source: "FontAwesome5",
                                name: "credit-card",
                            }}
                            label={ localisation.t("screens.home.paymentSettings.title") }
                            onPress={ () => onPress("payment") }
                        />
                    </View>

                    {/* Splitter. */}
                    <View style={ styles.splitter }/>

                    {/* About us. */}
                    <View style={ styles.itemContainer }>
                        <DefButton
                            icon={{
                                source: "FontAwesome5",
                                name: "question-circle",
                            }}
                            label={ localisation.t("screens.home.aboutUs.title") }
                            onPress={ () => onPress("aboutUs") }
                        />
                    </View>

                </View>
            </ScreenWrapper>
        </ScrollView>
    );
};

//Exports.
export default Component;
