
//Framework.
import React from "react";
import { View, Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import MediaContext from "@contexts/media";

//Components.
import ScreenWrapper from "@components/containers/wrappers/screen";
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const media = React.useContext(MediaContext);

    //Return.
    const styles = stylesheet(theme, media);
    return (
        <ScreenWrapper>
            <View style={ styles.container }>
                <View style={ styles.itemContainer }>
                    <Text
                        style={ styles.label }
                        selectable={ false }
                    >
                        { localisation.t("screens.home.teams.label0") }
                    </Text>
                </View>
                <View style={ styles.itemContainer }>
                    <Icon
                        source="MaterialCommunityIcons"
                        name="popcorn"
                        colour={ theme.colour.primary }
                        size={ 40 }
                    />
                </View>
                <View style={ styles.itemContainer }>
                    <Text
                        style={ styles.label }
                        selectable={ false }
                    >
                        { localisation.t("screens.home.teams.label1") }
                    </Text>
                </View>
            </View>
        </ScreenWrapper>
    );
}

//Exports.
export default Component;
