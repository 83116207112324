
//Framework.
import React from "react";
import { View, Pressable, ScrollView, Text, Animated } from "react-native";

//Helpers.
import { stringToUnix, unixToDate } from "@helpers/time";

//Constants.
import EVENT_MODEL from "@constants/models/event";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
//import ModalContext from "@contexts/modal";

//Components.
import BodyWrapper from "@components/containers/wrappers/body";
import ToggleExpandable from "@components/containers/expandables/toggle";
import WeekdaysInput from "@components/inputs/weekdays/def";
import TextListInput from "@components/inputs/select/lists/text";
//import DatetimeModal from "@components/inputs/datetime/modal";
import DescText from "@components/text/desc";
import TextAreaInput from "@components/inputs/textArea/def";

//Styling.
import stylesheet from "./stylesheets/step2";

const Component = ({ disabled:disabledIn, state:stateIn, dispatch:dispatchIn, onHide:onHideIn, onShow:onShowIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    //const modal = React.useContext(ModalContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);
    const [ weekdays, setWeekdays ] = React.useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [ frequencyType, setFrequencyType ] = React.useState(1);
    const [ endDatetime, setEndDatetime ] = React.useState(getInitialEndDatetime(stateIn.datetime.value));

    //Methods.
    const onRepeatChange = React.useCallback((value) => dispatchIn({ type:"onChange", input:"repeat", value:value ? 1 : 0, }), []);
    const onWeekdaysChange = React.useCallback((value) => setWeekdays({ ...value, ...getForcedWeekdayObject(stateIn.datetime.value) }), []);
    const onRepeatFrequencyChange = React.useCallback((value) => console.log(value), []);
    const onRepeatStartDateChange = React.useCallback((value) => console.log(value), []);
    const onRepeatEndDateChange = React.useCallback((value) => console.log(value), []);
    const onDescriptionChange = React.useCallback((value) => dispatchIn({ type:"onChange", input:"description", value:value, }), []);

    //Update repeat rules when event execution time changes.
    React.useEffect(() => {
        setWeekdays({ ...weekdays, ...getForcedWeekdayObject(stateIn.datetime.value) });
        setEndDatetime(getInitialEndDatetime(stateIn.datetime.value));
    }, [ stateIn.datetime.value ]);

    //Update state repeat rules when repeat states change.
    React.useEffect(() => {
        const rules = exportRepeatRules(weekdays, frequencyType, stateIn.datetime.value, endDatetime);
        dispatchIn({ type:"onChange", input:"repeatRules", value:rules, });
    }, [ weekdays, frequencyType, stateIn.datetime.value, endDatetime ]);

    //Import repeat rules on initialisation.
    /*
    React.useEffect(() => {
        if (isInitialised) {
            const importedRules = importRepeatRules(stateIn.repeatRules.value);
            console.log({ importedRules });
            setWeekdays(importedRules.weekdays);
            setFrequencyType(importedRules.frequency);
            setEndDatetime(importedRules.endDatetime);
        }
    }, [ isInitialised ]);
    */

    //Calculate amount of events that will be created with repeat rules.
    const eventsToBeCreatedNum = React.useMemo(() => {
        //Loop through dates.
        const unixDate = new Date(stateIn.datetime.value);
        const unixEndDate = new Date(endDatetime);
        let count = 0;
        while(unixDate <= unixEndDate) {
            //Check if counting day.
            switch(unixDate.getDay()) {
                case(0):
                    if (weekdays.sunday) count++;
                    break;
                case(1):
                    if (weekdays.monday) count++;
                    break;
                case(2):
                    if (weekdays.tuesday) count++;
                    break;
                case(3):
                    if (weekdays.wednesday) count++;
                    break;
                case(4):
                    if (weekdays.thursday) count++;
                    break;
                case(5):
                    if (weekdays.friday) count++;
                    break;
                case(6):
                    if (weekdays.saturday) count++;
                    break;
            }
            //Increment date.
            unixDate.setDate(unixDate.getDate() + 1);
            //Check if skipping week.
            if (frequencyType && unixDate.getDay() === 1) {
                let weeksToSkip = 0;
                switch(frequencyType) {
                    case(2):
                        weeksToSkip = 1;
                        break;
                    case(3):
                        weeksToSkip = 2;
                        break;
                }
                unixDate.setDate(unixDate.getDate() + 7 * weeksToSkip);
            }
        }
        //Return.
        return count;
    }, [ weekdays, frequencyType, endDatetime ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheet(theme);
    return (
        <BodyWrapper>
            <View style={ styles.container }>

                {/* Repeating. */}
                <View style={ styles.inputContainer }>
                    <ToggleExpandable
                        title={ localisation.t("models.event.repeatRules.label") }
                        icon={{
                            source: "FontAwesome",
                            name: "repeat",
                        }}
                        open={ stateIn.repeat.value ? true : false }
                        onChange={ onRepeatChange }
                        disabled={ disabledIn }
                    >
                        <View style={ styles.repeatingContainer }>
                            {/* Weekdays. */}
                            <View style={ styles.repeatingItemContainer }>
                                <WeekdaysInput
                                    value={ weekdays }
                                    onChange={ onWeekdaysChange }
                                    disabled={ disabledIn }
                                />
                            </View>
                            {/* Frequency. */}
                            <View style={ styles.repeatingItemContainer }>
                                <TextListInput
                                    value={ frequencyType }
                                    onChange={ setFrequencyType }
                                    disabled={ disabledIn }
                                    options={[{
                                        text: localisation.t("screens.home.createEventModal.repeatFrequencyOption1"),
                                        value: 1,
                                    }, {
                                        text: localisation.t("screens.home.createEventModal.repeatFrequencyOption2"),
                                        value: 2,
                                    }, {
                                        text: localisation.t("screens.home.createEventModal.repeatFrequencyOption3"),
                                        value: 3,
                                    }]}
                                />
                            </View>
                            {/* End date. */}
                            {/*
                            <DatetimeModal
                            />
                            */}
                            {/* Events created amount. */}
                            <View style={ styles.repeatingItemContainer }>
                                <DescText align="center">
                                    { localisation.t("screens.home.createEventModal.repeatEventsToBeCreatedText", { amount:eventsToBeCreatedNum }) }
                                </DescText>
                            </View>
                        </View>
                    </ToggleExpandable>
                </View>

                {/* Description. */}
                <View style={ styles.inputContainer }>
                    <TextAreaInput
                        value={ stateIn.description.value }
                        onChange={ onDescriptionChange }
                        disabled={ disabledIn }
                        maxLength={ EVENT_MODEL.description.maxLength }
                        numberOfLines={ 4 }
                        placeholder={ localisation.t("models.event.description.label") }
                    />
                </View>

            </View>
        </BodyWrapper>
    );
};

const getForcedWeekdayObject = (timestamp) => {
    const datetime = new Date(timestamp);
    switch(datetime.getDay()) {
        case(0): return { sunday: true };
        case(1): return { monday: true };
        case(2): return { tuesday: true };
        case(3): return { wednesday: true };
        case(4): return { thursday: true };
        case(5): return { saturday: true };
        case(6): return { sunday: true };
    }
};

const getInitialEndDatetime = (startTimestamp) => {
    const datetime = new Date(startTimestamp);
    datetime.setDate(datetime.getDate() + 14);
    datetime.setHours(23);
    datetime.setMinutes(59);
    datetime.setSeconds(59);
    datetime.setMilliseconds(999);
    return datetime.getTime();
};

const exportRepeatRules = (weekdays, frequency, startDatetime, endDatetime) => {
    /*
        Converts weekdays, frequency and end datetime to format:
        [{
            timeframe: "weekly",
            weekdays: {
                monday: (bool),
                tuesday: (bool),
                wednesday: (bool),
                thursday: (bool),
                friday: (bool),
                saturday: (bool),
                sunday: (bool),
            },
            direction: "date" || "first" || "last",
            frequency: (integer, between 1 and 3),
            startDate: "YYYY-mm-dd",
            endDate: "YYYY-mm-dd",
        }]
    */
    const timeframe = "weekly";
    const weekdaysExport = {
        monday: weekdays.monday ? 1 : 0,
        tuesday: weekdays.tuesday ? 1 : 0,
        wednesday: weekdays.wednesday ? 1 : 0,
        thursday: weekdays.thursday ? 1 : 0,
        friday: weekdays.friday ? 1 : 0,
        saturday: weekdays.saturday ? 1 : 0,
        sunday: weekdays.sunday ? 1 : 0,
    };
    const direction = "first";
    const frequencyExport = frequency;
    const startDate = unixToDate(startDatetime);
    const endDate = unixToDate(endDatetime);
    //Return.
    return [{
        timeframe: timeframe,
        weekdays: weekdaysExport,
        direction: direction,
        frequency: frequencyExport,
        startDate: startDate,
        endDate: endDate,
    }];
};
const importRepeatRules = (object) => {
    /*
        Extracts weekdays, frequency and end datetime to format:
        [{
            timeframe: "weekly",
            weekdays: {
                monday: (bool),
                tuesday: (bool),
                wednesday: (bool),
                thursday: (bool),
                friday: (bool),
                saturday: (bool),
                sunday: (bool),
            },
            direction: "date" || "first" || "last",
            frequency: (integer, between 1 and 3),
            startDate: "YYYY-mm-dd",
            endDate: "YYYY-mm-dd",
        }]
    */
    const weekdays = {
        monday: object[0].weekdays.monday ? true: false,
        tuesday: object[0].weekdays.tuesday ? true: false,
        wednesday: object[0].weekdays.wednesday ? true: false,
        thursday: object[0].weekdays.thursday ? true: false,
        friday: object[0].weekdays.friday ? true: false,
        saturday: object[0].weekdays.saturday ? true: false,
        sunday: object[0].weekdays.sunday ? true: false,
    };
    const frequency = object[0].frequency;
    const endDatetime = stringToUnix(object[0].endDate);
    //Return.
    return {
        weekdays: weekdays,
        frequency: frequency,
        endDatetime: endDatetime,
    };
};

//Exports.
export default Component;
