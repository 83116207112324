
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            backgroundColor: theme.colour.unauthenticatedCard,
            padding: theme.spacing.medium / 2,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: 40,
            shadowColor: "rgb(0, 0, 0)",
            shadowOffset: {
                width: 4,
                height: 4,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
        },
        title: {
            color: theme.colour.unauthenticatedCardText,
            fontSize: theme.fonts.title.sizes.small,
            fontFamily: theme.fonts.title.families.bold,
            padding: theme.spacing.medium / 2,
            textAlign: "center",
            textTransform: "uppercase",
        },
        contentContainer: {
            padding: theme.spacing.medium / 2,
        },
    });
}

//Exports.
export default stylesheet;
