
//Framework.
import React from "react";
import { View, Text, TextInput, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import TextError from "@components/text/error";
import WrapperFade from "@components/containers/wrappers/fade";

//Styling.
import stylesheet from "./stylesheets/def";

/*
    value: (string),
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) maxLength: (integer),
    (Optional) placeholder: (string),
    (Optional) inputMode: (string: text),
    (Optional) keyboardType: (string: "default"),
    (Optional) textContentType: (string),
    (Optional) returnKeyType: (string: "done"),
    (Optional) onChange: (method),
    (Optional) error: (string),
    (Optional) numberOfLines: (integer),
*/

const Component = ({
    value: valueIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    maxLength: maxLengthIn,
    placeholder: placeholderIn,
    inputMode: inputModeIn = "text",
    keyboardType: keyboardTypeIn = "default",
    textContentType: textContentTypeIn,
    returnKeyType: returnKeyTypeIn = "done",
    onChange: onChangeIn,
    error: errorIn,
    numberOfLines: numberOfLinesIn = 2,
}) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onChange = React.useCallback((e) => {
        onChangeIn(e.nativeEvent.text, e.nativeEvent.data);
    }, [ onChangeIn ]);

    //Animations.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: theme.animations.disable.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);
    
    //Render.
    const styles = stylesheet(theme, animations, numberOfLinesIn);
    return (
        <Animated.View style={ styles.container }>
            <View style={ styles.contentContainer }>

                {/* Input. */}
                <View style={ styles.inputContainer }>
                    <WrapperFade transitionSize={ theme.spacing.medium }>
                        <TextInput
                            style={ styles.input }
                            value={ valueIn }
                            editable={ !disabledIn && editableIn }
                            maxLength={ maxLengthIn }
                            placeholder={ placeholderIn }
                            inputMode={ inputModeIn }
                            keyboardType={ keyboardTypeIn }
                            textContentType={ textContentTypeIn }
                            returnKeyType={ returnKeyTypeIn }
                            onChange={ onChange }
                            multiline={ true }
                            numberOfLines={ numberOfLinesIn }
                        />
                    </WrapperFade>
                </View>

            </View>

            {/* Max length counter. */}
            { maxLengthIn && (
                <View style={ styles.maxLengthContainer }>
                    <Text
                        style={ styles.maxLengthLabel }
                    >
                        { `${ valueIn.length } / ${ maxLengthIn }` }
                    </Text>
                </View>
            )}

            {/* Error. */}
            { !errorIn ? null : (
                <View style={ styles.errorContainer }>
                    <TextError>
                        { errorIn }
                    </TextError>
                </View>
            )}
        </Animated.View>
    );
}

//Exports.
export default Component;
