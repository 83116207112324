
//Framework.
import React from "react";
import { Text } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/body";

/*
    (Optional) align: (string, options are "left", "right", "center"),
    (Optional) colour: (string),
*/

const Component = ({ children, align:alignIn = "left", colour:colourIn }) => {
    const theme = React.useContext(ThemeContext);

    //Get colour.
    const colour = colourIn === undefined ? theme.colour.text : colourIn;

    //Render.
    const styles = stylesheet(theme, colour, alignIn);
    return (
        <Text
            style={ styles.text }
            selectable={ false }
        >
            { children }
        </Text>
    );
}

//Exports.
export default Component;
