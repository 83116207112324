
import { StyleSheet, Platform } from "react-native";

const stylesheet = function(theme) {
    return StyleSheet.create({
        container: {
            flexDirection: "row",
            justifyContent: "space-evenly",
        },
    });
}

//Exports.
export default stylesheet;
