
//User info.
const formatFullName = (firstName, lastName) => {
    return `${ firstName } ${ lastName }`;
};

//Event prices.
const formatEventPrice = (value) => parseFloat(value / 100).toFixed(2);

export {
    formatFullName,
    formatEventPrice,
}
