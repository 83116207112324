
const translations = {
    january: {
        full: "January",
        short: "Jan",
    },
    february: {
        full: "February",
        short: "Feb",
    },
    march: {
        full: "March",
        short: "Mar",
    },
    april: {
        full: "April",
        short: "Apr",
    },
    may: {
        full: "May",
        short: "May",
    },
    june: {
        full: "June",
        short: "Jun",
    },
    july: {
        full: "July",
        short: "Jul",
    },
    august: {
        full: "August",
        short: "Aug",
    },
    september: {
        full: "September",
        short: "Sep",
    },
    october: {
        full: "October",
        short: "Oct",
    },
    november: {
        full: "November",
        short: "Nov",
    },
    december: {
        full: "December",
        short: "Dec",
    },
};

export default translations;
