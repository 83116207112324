
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.small,
        },
        animationWrapper: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(0, 0, 0, 0)", theme.colour.primary ],
            }),
            flexDirection: "row",
            padding: theme.spacing.medium / 2,
            borderRadius: (theme.fonts.text.lineHeights.medium + 2 * theme.spacing.medium) / 2,
        },

        //Icon.
        iconContainer: {
            flexBasis: theme.fonts.text.lineHeights.medium + theme.spacing.medium,
            padding: theme.spacing.medium / 2,
        },
        iconWrapper: {
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.text, theme.colour.primaryText ],
            }),
        },

        //Label.
        labelContainer: {
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.medium / 2,
            paddingRight: theme.spacing.medium / 2 + (theme.fonts.text.lineHeights.medium + theme.spacing.medium),
        },
        label: {
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.text, theme.colour.primaryText ],
            }),
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
    });
}

//Exports.
export default stylesheet;
