
import containers from "./containers/main";
import inputs from "./inputs/main";
import text from "./text/main";
import misc from "./misc/main";

const translations = {
    containers: containers,
    inputs: inputs,
    text: text,
    misc: misc,
};

export default translations;
