
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {

    const height = theme.fonts.text.lineHeights.medium + theme.spacing.medium;

    return StyleSheet.create({
        container: {
            height: height,
        },
        label: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.primary, theme.colour.primaryText ],
            }),
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.primaryText, theme.colour.primary ],
            }),
            borderRadius: height / 2,
            padding: theme.spacing.small / 2,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
    });
}

//Exports.
export default stylesheet;
