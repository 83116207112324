
import { StyleSheet } from "react-native";
import { getCreateButtonOverflow } from "@screens/home/main";

const stylesheet = (theme, media) => {
    return StyleSheet.create({
        container: {
            flexBasis: 0,
            flexGrow: 1,
            paddingBottom: getCreateButtonOverflow(theme, media),
        },
    });
}

//Exports.
export default stylesheet;
