
//Framework.
import React from "react";
import { View, TextInput, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";
import TextError from "@components/text/error";

//Styling.
import stylesheet from "./stylesheets/def";

/*
    value: (string),
    icon: {
        source: (string),
        name: (string),
    },
    (Optional) disabled: (bool: false),
    (Optional) editable: (bool: true),
    (Optional) maxLength: (integer),
    (Optional) placeholder: (string),
    (Optional) inputMode: (string: text),
    (Optional) keyboardType: (string: "default"),
    (Optional) textContentType: (string),
    (Optional) returnKeyType: (string: "done"),
    (Optional) sensitive: (bool: false),
    (Optional) onChange: (method),
    (Optional) onBlur: (method),
    (Optional) left: (content),
    (Optional) error: (string),
*/

//Contants.
const ANIMATION_DISABLE_DURATION_MS = 300;

const Component = ({
    value: valueIn,
    icon: iconIn,
    disabled: disabledIn = false,
    editable: editableIn = true,
    maxLength: maxLengthIn,
    placeholder: placeholderIn,
    inputMode: inputModeIn = "text",
    keyboardType: keyboardTypeIn = "default",
    textContentType: textContentTypeIn,
    returnKeyType: returnKeyTypeIn = "done",
    sensitive: sensitiveIn = false,
    onChange: onChangeIn,
    onBlur: onBlurIn,
    left: leftIn,
    error: errorIn,
}) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onChange = React.useCallback((e) => {
        onChangeIn(e.nativeEvent.text, e.nativeEvent.data);
    }, [ onChangeIn ]);

    //Animations.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);
    
    //Render.
    const styles = stylesheet(theme, animations, iconIn !== undefined);
    return (
        <Animated.View style={ styles.container }>
            <View style={ styles.contentContainer }>

                {/* Icon. */}
                { iconIn && (
                    <View style={ styles.iconContainer }>
                        <Icon
                            source={ iconIn.source }
                            name={ iconIn.name }
                            colour={ theme.colour.primaryText }
                            size={ theme.fonts.text.lineHeights.medium }
                        />
                    </View>
                )}

                {/* Input. */}
                <View style={ styles.inputContainer }>
                    <TextInput
                        style={ styles.input }
                        value={ valueIn }
                        editable={ !disabledIn && editableIn }
                        maxLength={ maxLengthIn }
                        placeholder={ placeholderIn }
                        inputMode={ inputModeIn }
                        keyboardType={ keyboardTypeIn }
                        textContentType={ textContentTypeIn }
                        returnKeyType={ returnKeyTypeIn }
                        secureTextEntry={ sensitiveIn }
                        onChange={ onChangeIn ? onChange : null }
                        onBlur={ onBlurIn }
                    />
                </View>

            </View>
            { !errorIn ? null : (
                <View style={ styles.errorContainer }>
                    <TextError>
                        { errorIn }
                    </TextError>
                </View>
            )}
        </Animated.View>
    );
}

//Exports.
export default Component;
