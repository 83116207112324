
const translations = {
    monday: {
        full: "Monday",
        short: "Mon",
        single: "M",
    },
    tuesday: {
        full: "Tuesday",
        short: "Tue",
        single: "T",
    },
    wednesday: {
        full: "Wednesday",
        short: "Wed",
        single: "W",
    },
    thursday: {
        full: "Thursday",
        short: "Thu",
        single: "T",
    },
    friday: {
        full: "Friday",
        short: "Fri",
        single: "F",
    },
    saturday: {
        full: "Saturday",
        short: "Sat",
        single: "S",
    },
    sunday: {
        full: "Sunday",
        short: "Sun",
        single: "S",
    },
};

export default translations;
