
//Framework.
import React from "react";
import { View, Pressable, Animated } from "react-native";
import { debounce } from "lodash";

//Constants.
import { DEBOUNCE_DURATION_MS } from "@constants/timing";

//Contexts.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheetComponent from "./stylesheets/icons/component";
import stylesheetButton from "./stylesheets/icons/button";

/*
    buttons: [{
        icon: {
            source: (string),
            name: (string),
            colour: (string),
        },
        onPress: (method),
        disabled: (boolean = false),
    }],
*/

//Constants.
const ICON_SIZE = 25;
const ANIMATION_DISABLE_DURATION_MS = 300;
const ANIMATION_HOVER_DURATION_MS = 200;
const ANIMATION_PRESS_DURATION_MS = 300;

const Component = ({ buttons }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheetComponent(theme);
    return (
        <View style={ styles.container }>
            { buttons.map((button, index) => (
                <React.Fragment key={ index }>
                    { index === 0 ? null : (
                        <View style={ styles.splitter }/>
                    )}
                    <Button
                        onPress={ button.onPress }
                        icon={ button.icon }
                        disabled={ button.disabled }
                    />
                </React.Fragment>
            )) }
        </View>
    );
}

const Button = ({ onPress:onPressIn, icon:iconIn, disabled:disabledIn = false }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
        hover: React.useRef(new Animated.Value(0)).current,
        press: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (disabledIn) {
                Animated.timing(animations.disable, {
                    toValue: 1,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.disable, {
                    toValue: 0,
                    duration: ANIMATION_DISABLE_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ disabledIn ]);

    //Methods.
    const onPressDebounce = React.useCallback(
        debounce((method) => method(), DEBOUNCE_DURATION_MS, {
            leading: true,
            trailing: false,
        }),
    []);
    const onPress = React.useCallback(() => {
        //Animate.
        animations.press.setValue(0);
        Animated.timing(animations.press, {
            toValue: 1,
            duration: ANIMATION_PRESS_DURATION_MS,
            useNativeDriver: true,
        }).start();
        //Fire callback.
        onPressDebounce(() => onPressIn());
    }, [ onPressIn ]);
    const onHoverIn = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.hover, {
            toValue: 1,
            duration: ANIMATION_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    }, []);
    const onHoverOut = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.hover, {
            toValue: 0,
            duration: ANIMATION_HOVER_DURATION_MS,
            useNativeDriver: true,
        }).start();
    }, []);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheetButton(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
            onHoverIn={ onHoverIn }
            onHoverOut={ onHoverOut }
            disabled={ disabledIn }
        >
            <Animated.View style={ styles.opacityWrapper }>
                <Animated.View style={ styles.darkenWrapper }>
                    <View style={ styles.iconContainer }>
                        <Icon
                            source={ iconIn.source }
                            name={ iconIn.name }
                            colour={ iconIn.colour }
                            size={ ICON_SIZE }
                        />
                    </View>
                </Animated.View>
            </Animated.View>
        </Pressable>
    );
};

const getHeight = (theme) => {
    return ICON_SIZE + 2 * theme.spacing.medium + theme.border.width.small;
};

//Exports.
export default Component;
export { getHeight };
