
//Framework.
import React from "react";
import { View, Modal, /*ScrollView,*/ Animated } from "react-native";

//Contexts.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/modal";

//Constants.
const ANIMATION_DURATION_MS = 500;
const ANIMATION_DELTA_MARGIN = 20;

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isBlocking, setIsBlocking ] = React.useState(false);
    const [ content, setContent ] = React.useState(null);
    const [ onRequestClose, setOnRequestClose ] = React.useState(null);

    //Animations.
    const animations = {
        opacity: React.useRef(new Animated.Value(0)).current,
        marginTop: React.useRef(new Animated.Value(-ANIMATION_DELTA_MARGIN)).current,
    };

    //Methods.
    const fire = React.useCallback(({ content, onRequestClose }) => {
        //Set states.
        setContent(content);
        setOnRequestClose(() => onRequestClose);
        //Show modal.
        setIsBlocking(true);
        Animated.timing(animations.marginTop, {
            toValue: 0,
            duration: ANIMATION_DURATION_MS,
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.opacity, {
            toValue: 1,
            duration: ANIMATION_DURATION_MS,
            useNativeDriver: true,
        }).start();
    }, []);
    const close = React.useCallback((onClosed) => {
        Animated.timing(animations.marginTop, {
            toValue: -ANIMATION_DELTA_MARGIN,
            duration: ANIMATION_DURATION_MS,
            useNativeDriver: true,
        }).start();
        Animated.timing(animations.opacity, {
            toValue: 0,
            duration: ANIMATION_DURATION_MS,
            useNativeDriver: true,
        }).start(() => {
            setIsBlocking(false);
            if (onClosed !== undefined) onClosed();
        });
    }, []);
    
    //Create value.
    const value = React.useMemo(() => ({
        fire: fire,
        close: close,
    }), []);
    global.modal = value;
    
    //Render.
    const styles = stylesheet(theme, animations, isBlocking);
    return (
        <Context.Provider value={ value }>
            { children }
            
            <Modal
                visible={ isBlocking }
                animationType="none"
                transparent={ true }
                onRequestClose={ onRequestClose }
            >
                <Animated.View
                    style={ styles.backdrop }
                >
                    {/*
                    <ScrollView contentContainerStyle={ styles.container }>
                        <Animated.View
                            style={ styles.contentContainer }
                        >
                            { content }
                        </Animated.View>
                    </ScrollView>
                    */}
                    <Animated.View
                        style={ styles.contentContainer }
                    >
                        { content }
                    </Animated.View>
                </Animated.View>
            </Modal>
        </Context.Provider>
    );
}

//Exports.
export default Context;
export { Provider };
