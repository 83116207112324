
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Animated, Image, ScrollView } from "react-native";

//Constants.
import EVENT_MODEL from "@constants/models/event";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import Template from "./template";
import Option, { getHeight as getOptionHeight } from "./options/icon";

/*
    value: (any),
*/

const Component = ({ value:valueIn, options:optionsIn, optionSize:optionSizeIn, size:sizeIn = "small", onAccept:onAcceptIn, onCancel:onCancelIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //States.
    const [ value, setValue ] = React.useState(valueIn);

    //Methods.
    const onAcceptPress = React.useCallback(() => onAcceptIn(value), [ value ]);
    const onCancelPress = React.useCallback(onCancelIn, []);
    const onOptionPress = React.useCallback((value) => setValue(value), []);

    //Get options.
    const options = React.useMemo(() => Object.values(EVENT_MODEL.types).map(type => {
        return {
            words: [localisation.t(type.labelKey)].concat(localisation.t(type.labelKey).split(" ")),
            content: (
                <Option
                    key={ type.id }
                    selected={ value === type.id }
                    label={ localisation.t(type.labelKey) }
                    icon={{
                        source: type.icon.source,
                        name: type.icon.name,
                    }}
                    onPress={ () => setValue(type.id) }
                />
            ),
        };
    }), [ value ]);

    //Render.
    return (
        <Template
            searchable={ true }
            options={ options }
            optionSize={ getOptionHeight(theme) }
            onAccept={ onAcceptPress }
            onCancel={ onCancelPress }
        />
    );
}

//Exports.
export default Component;
