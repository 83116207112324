
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme) => {

    const height = theme.fonts.text.lineHeights.small + 2 * theme.spacing.tiny + 2 * theme.border.width.medium;

    return StyleSheet.create({
        container: {
            width: "100%",
            height: height,
            borderRadius: height / 2,
            borderWidth: theme.border.width.medium,
            borderColor: theme.colour.text,
            opacity: theme.opacity.searchField,
            overflow: "hidden",
        },
        iconContainer: {
            width: height,
            height: height,
            padding: theme.spacing.small,
        },
        inputContainer: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        input: {
            width: "100%",
            height: "100%",
            textAlign: "center",
            padding: theme.spacing.tiny,
            paddingLeft: 2 * theme.spacing.tiny + theme.spacing.tiny + theme.fonts.text.sizes.small,
            paddingRight: 2 * theme.spacing.tiny + theme.spacing.tiny + theme.fonts.text.sizes.small,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.regular,

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
    });
}

//Exports.
export default stylesheet;
