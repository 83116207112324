
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        backdrop: {
            backgroundColor: theme.colour.backdrop,
            opacity: animations.opacity,
            position: "absolute",
            width:"100%",
            height:"100%",
        },
        container: {
            width: "100%",
            height: "100%",
        },
        contentContainer: {
            marginTop: animations.marginTop,
            width: "100%",
            height: "100%",
        },
    });
}

//Exports.
export default stylesheet;
