
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, pad = {}) => {
    return StyleSheet.create({
        container: {
        },
        contentContainer: {
            borderBottomWidth: 1,
            borderBottomColor: theme.colour.unauthenticatedCardText,
            width: "100%",
        },
        label: {
            color: theme.colour.unauthenticatedCardText,
            fontSize: theme.fonts.text.sizes.small,
            fontFamily: theme.fonts.text.families.bold,
            padding: theme.spacing.small,
            paddingLeft: theme.spacing.medium,
        },
        body: {
            display: "flex",
            flexDirection: "row",
        },
        leftContainer: {
        },
        rightContainer: {
        },
        inputContainer: {
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
        },
        input: {
            color: theme.colour.unauthenticatedCardText,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            padding: theme.spacing.small,
            paddingLeft: theme.spacing.small + (pad.left ? pad.left : 0),
            paddingRight: theme.spacing.small + (pad.right ? pad.right : 0),
            width: "100%",

            textAlign: "center",
            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        errorContainer: {
            padding: theme.spacing.small / 2,
        },
    });
}

//Exports.
export default stylesheet;
