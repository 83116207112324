
//Framework.
import React from "react";
import { View, Pressable, ScrollView, Text, Animated } from "react-native";

//Constants.
import EVENT_MODEL from "@constants/models/event";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import BodyWrapper from "@components/containers/wrappers/body";
import TextInput from "@components/inputs/text/def";
import SkillLevelInput from "@components/inputs/skillLevel/def";
import IconsSwitch from "@components/inputs/switches/icons";

//Styling.
import stylesheet from "./stylesheets/step1";

const Component = ({ disabled:disabledIn, state:stateIn, dispatch:dispatchIn, onHide:onHideIn, onShow:onShowIn }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Methods.
    const onPlayerAmountChange = React.useCallback((value) => dispatchIn({ type:"onChange", input:"playerAmount", value:isNaN(parseInt(value)) ? value : parseInt(value), }), []);
    const onSkillLevelChange = React.useCallback((value) => dispatchIn({ type:"onChange", input:"skillLevel", value:value, }), []);
    const onIsPrivateChange = React.useCallback((value) => dispatchIn({ type:"onChange", input:"isPrivate", value:value, }), []);

    //Return.
    const styles = stylesheet(theme);
    return (
            <View style={ styles.container }>

                {/* Player amount. */}
                <View style={ styles.inputContainer }>
                    <TextInput
                        value={ stateIn.playerAmount.value }
                        icon={{
                            source: "FontAwesome5",
                            name: "users",
                        }}
                        disabled={ disabledIn }
                        error={ stateIn.playerAmount.error }
                        inputMode="numeric"
                        placeholder={ localisation.t("models.event.playerAmount.label") }
                        onChange={ onPlayerAmountChange }
                    />
                </View>

                {/* Skill level. */}
                <View style={ styles.inputContainer }>
                    <SkillLevelInput
                        value={ stateIn.skillLevel.value }
                        onChange={ onSkillLevelChange }
                        disabled={ disabledIn }
                        error={ stateIn.skillLevel.error }
                    />
                </View>

                {/* Price. */}
                {/*
                <View style={ styles.inputContainer }>
                </View>
                */}

                {/* Is private. */}
                <View style={ styles.inputContainer }>
                    <IconsSwitch
                        value={ stateIn.isPrivate.value }
                        onChange={ onIsPrivateChange }
                        disabled={ disabledIn }
                        options={{
                            off: {
                                value: 0,
                                colours: {
                                    track: theme.colour.switchTrack,
                                    thumb: theme.colour.switchThumb,
                                    selected: {
                                        background: theme.colour.success,
                                        icon: theme.colour.successText,
                                    },
                                    unselected: {
                                        background: "rgba(0,0,0,0)",
                                        icon: theme.colour.text,
                                    },
                                },
                                icon: {
                                    source: "FontAwesome5",
                                    name: "globe",
                                },
                            },
                            on: {
                                value: 1,
                                colours: {
                                    track: theme.colour.switchTrack,
                                    thumb: theme.colour.switchThumb,
                                    selected: {
                                        background: theme.colour.info,
                                        icon: theme.colour.infoText,
                                    },
                                    unselected: {
                                        background: "rgba(0,0,0,0)",
                                        icon: theme.colour.text,
                                    },
                                },
                                icon: {
                                    source: "FontAwesome5",
                                    name: "lock",
                                },
                            },
                        }}
                    />
                </View>

            </View>
    );
};

//Exports.
export default Component;
