
//Framework.
import React from "react";
import { View, Pressable } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/button";

/*
    buttons: [{
        icon: {
            source: (string),
            name: (string),
        },
        onPress: (method),
    }],
*/

//Constants.
const ICON_SIZE = 20;

const Component = ({ buttons }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheet(theme, ICON_SIZE);
    return (
        <View style={ styles.container }>
            { buttons.map((button, index) => (
                <Pressable
                    key={ index }
                    style={ styles.button }
                    onPress={ button.onPress }
                >
                    <Icon
                        source={ button.icon.source }
                        name={ button.icon.name }
                        size={ ICON_SIZE }
                        colour={ theme.colour.primaryText }
                    />
                </Pressable>
            )) }
        </View>
    );
}

const getSize = (theme, amountOfButtons) => 2 * theme.border.width.medium + (ICON_SIZE + 2 * theme.spacing.small) * amountOfButtons;

//Exports.
export default Component;
export { getSize /*BUTTON_SIZE, BORDER_SIZE*/ };
