
import { StyleSheet } from "react-native";

const stylesheet = (size) => {
    return StyleSheet.create({
        icon: {
            textAlign: "center",
            lineHeight: size,
        },
    });
}

//Exports.
export default stylesheet;
