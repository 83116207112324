
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            padding: theme.spacing.medium,
            paddingLeft: theme.spacing.large,
            paddingRight: theme.spacing.large,
            flexDirection: "row",
            justifyContent: "center",
        },
        hoursInput: {
            borderWidth: theme.border.width.medium,
            borderRightWidth: 0,
            borderColor: theme.colour.primary,
            borderRadius: theme.border.radius.small,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            flexBasis: 40,
            flexShrink: 1,
            textAlign: "right",
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.medium,

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
        splitLabel: {
            borderWidth: theme.border.width.medium,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: theme.colour.primary,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.medium,
            paddingLeft: 0,
            paddingRight: 0,
        },
        minutesInput: {
            borderWidth: theme.border.width.medium,
            borderLeftWidth: 0,
            borderColor: theme.colour.primary,
            borderRadius: theme.border.radius.small,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            flexBasis: 40,
            flexShrink: 1,
            textAlign: "left",
            color: theme.colour.text,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.regular,
            lineHeight: theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.medium,

            ...Platform.select({
                web: {
                    outlineStyle: "none",
                },
            }),
        },
    });
}

//Exports.
export default stylesheet;
