
//Framework.
import React from "react";
import { View, Text, Pressable, Animated, Easing } from "react-native";
import { debounce } from "lodash";

//Constants.
import { DEBOUNCE_DURATION_MS } from "@constants/timing";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/outlined";

/*
    icon: {
        source: (string),
        name: (string),
        colour: (string),
    },
    label: (string),
    size: (string, options are "small", "medium", "large"),
    onPress: (method),
    (Optional) disabled: (bool),
*/

const Component = ({ icon:iconIn, label:labelIn, size:sizeIn = "medium", onPress:onPressIn, disabled:disabledIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        disable: React.useRef(new Animated.Value(disabledIn ? 1 : 0)).current,
        press: React.useRef(new Animated.Value(0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            Animated.timing(animations.disable, {
                toValue: disabledIn ? 1 : 0,
                duration: theme.animations.disable.duration,
                useNativeDriver: true,
            }).start();
        }
    }, [ disabledIn ]);

    //Methods.
    const onPressDebounce = React.useCallback(
        debounce((method) => method(), DEBOUNCE_DURATION_MS, {
            leading: true,
            trailing: false,
        }),
    []);
    const onPress = React.useCallback(() => {
        //Animate.
        Animated.timing(animations.press, {
            toValue: 1,
            duration: theme.animations.bounce.duration,
            easing: Easing.elastic(theme.animations.bounce.easing),
            useNativeDriver: true,
        }).start(() => animations.press.setValue(0));
        //Fire callback.
        onPressDebounce(() => onPressIn());
    }, [ onPressIn ]);

    //Calculate heights.
    const sizes = React.useMemo(() => {
        let lineHeight, font, padding;
        switch(sizeIn) {
            case("small"):
                lineHeight = theme.fonts.text.lineHeights.small;
                font = theme.fonts.text.sizes.small;
                padding = theme.spacing.small;
                break;
            case("medium"):
                lineHeight = theme.fonts.text.lineHeights.medium;
                font = theme.fonts.text.sizes.medium;
                padding = theme.spacing.medium;
                break;
            case("large"):
                lineHeight = theme.fonts.text.lineHeights.large;
                font = theme.fonts.text.sizes.large;
                padding = theme.spacing.medium;
                break;
        }
        return {
            lineHeight: lineHeight,
            font: font,
            padding: padding,
        };
    }, [ sizeIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Render.
    const styles = stylesheet(theme, animations, sizes, iconIn !== undefined);
    return (
        <Animated.View style={ styles.animationWrapper }>
            <Pressable
                style={ styles.container }
                disabled={ disabledIn }
                onPress={ onPress }
            >
                { iconIn !== undefined && (
                    <View style={ styles.iconContainer }>
                        <Icon
                            source={ iconIn.source }
                            name={ iconIn.name }
                            colour={ iconIn.colour }
                            size={ sizes.lineHeight }
                        />
                    </View>
                )}
                <Text
                    style={ styles.label }
                    selectable={ false }
                    numberOfLines={ 1 }
                >
                    { labelIn }
                </Text>
            </Pressable>
        </Animated.View>
    );
}

const getHeight = (theme, size = "medium") => {
    const lineHeight = size === "medium" ? theme.fonts.text.lineHeights.medium : size === "small" ? theme.fonts.text.lineHeights.small : theme.fonts.text.lineHeights.large;
    return lineHeight + 2 * theme.spacing.small;
};

//Exports.
export default Component;
export { getHeight }
