
//Framework.
import React from "react";
import { View, Text, Pressable, Animated, Image } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

//Helpers.
import { send } from "@helpers/connection";
import { unixToDatetime } from "@helpers/time";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import ModalContext from "@contexts/modal";
import MediaContext from "@contexts/media";
import SessionContext from "@contexts/session";
import LoadingScreenContext from "@contexts/loadingScreen";
import AlertContext from "@contexts/alert";

//Components.
import DefHeader from "@components/misc/headers/def";
import ModalWrapper from "@components/containers/wrappers/modal";
import CardDialogue from "@components/containers/cards/def/dialogue";
import BodyWrapper from "@components/containers/wrappers/body";
import BodyText from "@components/text/body";
import CounterBubble, { getMaxValue as getCounterBubbleMaxValue } from "@components/misc/counterBubble";
import WrapperModal from "@components/containers/wrappers/modal";
import CardServerError from "@components/containers/cards/def/serverError";
import CardConnectionError from "@components/containers/cards/def/connectionError";
import CardUserError from "@components/containers/cards/def/userError";
import DefAlert from "@components/misc/alerts/def";

//Children.
import CreateEventModal from "./createEventModal/main";
import MapScreen from "./map/main";
import EventsScreen from "./events/main";
import SocialScreen from "./social/main";
import NotificationsScreen from "./notifications/main";

import FriendsScreen from "./friends/main";
import ProfileScreen from "./profile/main";
import TeamsScreen from "./teams/main";
import SettingsScreen from "./settings/main";

import PersonalSettingsScreen from "./personalSettings/main";
import NotificationsSettingsScreen from "./notificationsSettings/main";
import PaymentSettingsScreen from "./paymentSettings/main";
import AboutUsScreen from "./aboutUs/main";

/*
import EventScreen from "./event/main";
import EventsScreen from "./events/main";
import EventInterestsScreen from "./eventInterests/main";
import EventInvitationsScreen from "./eventInvitations/main";

import CreateEventScreen from "@screens/home/createEvent/main";

import SocialScreen from "@screens/home/social/main";
import UserScreen from "@screens/home/user/main";
import FriendsScreen from "./friends/main";
import FriendRequestsScreen from "./friendRequests/main";
import TeamsScreen from "./teams/main";
import ChatsScreen from "./chats/main";

import SettingsScreen from "./settings/main";
import PersonalSettingsScreen from "./personalSettings/main";
import NotificationsSettingsScreen from "./notificationsSettings/main";
import PaymentSettingsScreen from "./paymentSettings/main";
import AboutUsScreen from "./aboutUs/main";

import NotificationsScreen from "@screens/home/notifications/main";
*/

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheetTabBar from "./stylesheets/main/tabBar";
import stylesheetTabButton from "./stylesheets/main/tabButton";

//Constanst.
const ICON_SIZE = 18;
const ANIMATION_TAB_SELECT_DURATION_MS = 300;

const TAB_ICON_SIZE_MOBILE = 15;
const TAB_ICON_SIZE_TABLET = 22;
const TAB_ICON_SIZE_DESKTOP = 28;

const CREATE_BUTTON_SIZE_MOBILE = 80;
const CREATE_BUTTON_SIZE_TABLET = 100;
const CREATE_BUTTON_SIZE_DESKTOP = 120;

//Navigation.
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const HomeScreen = ({ navigation, route }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const session = React.useContext(SessionContext);

    //Return.
    return (
        <Stack.Navigator
            initialRouteName="tabs"
        >

            {/* Tabs. */}
            <Stack.Screen
                name = "tabs"
                component = { TabsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.title") }),
                    headerShown: false,
                }}
            />

            {/* Profile. */}
            <Stack.Screen
                name = "profile"
                component = { ProfileScreen }
                initialParams={{ id:session.user.id }}
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.profile.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.profile.title") }
                        />
                    ),
                }}
            />

            {/* Friends. */}
            <Stack.Screen
                name = "friends"
                component = { FriendsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.friends.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.friends.title") }
                        />
                    ),
                }}
            />

            {/* Teams. */}
            <Stack.Screen
                name = "teams"
                component = { TeamsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.teams.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.teams.title") }
                        />
                    ),
                }}
            />

            {/* Settings. */}
            <Stack.Screen
                name = "settings"
                component = { SettingsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.settings.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.settings.title") }
                        />
                    ),
                }}
            />

            {/* Personal settings. */}
            <Stack.Screen
                name = "personalSettings"
                component = { PersonalSettingsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.personalSettings.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.personalSettings.title") }
                        />
                    ),
                }}
            />

            {/* Notifications settings. */}
            <Stack.Screen
                name = "notificationsSettings"
                component = { NotificationsSettingsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.notificationsSettings.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.notificationsSettings.title") }
                        />
                    ),
                }}
            />

            {/* Payment settings. */}
            <Stack.Screen
                name = "paymentSettings"
                component = { PaymentSettingsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.paymentSettings.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.paymentSettings.title") }
                        />
                    ),
                }}
            />

            {/* About us. */}
            <Stack.Screen
                name = "aboutUs"
                component = { AboutUsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.aboutUs.title") }),
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.aboutUs.title") }
                        />
                    ),
                }}
            />

        </Stack.Navigator>
    );
}

const TabsScreen = ({ navigation, route }) => {
    const localisation = React.useContext(LocalisationContext);

    //Return.
    return (
        <Tab.Navigator
            initialRouteName="map"
            tabBar={ (props) => <TabBar { ...props }/> }
        >
            {/* Map. */}
            <Tab.Screen
                name="map"
                component={ MapScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.map.title") }),
                    animation: "none",
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.map.title") }
                        />
                    ),
                }}
            />

            {/* Events. */}
            <Tab.Screen
                name="events"
                component={ EventsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.events.title") }),
                    animation: "none",
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.events.title") }
                        />
                    ),
                }}
            />

            {/* Social. */}
            <Tab.Screen
                name="social"
                component={ SocialScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.social.title") }),
                    animation: "none",
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.social.title") }
                        />
                    ),
                }}
            />

            {/* Notifications. */}
            <Tab.Screen
                name="notifications"
                component={ NotificationsScreen }
                options = {{
                    title: localisation.t("common.navTitle", { title:localisation.t("screens.home.notifications.title") }),
                    animation: "none",
                    header: ({ navigation, route, options, back }) => (
                        <DefHeader
                            navigation={ navigation }
                            route={ route }
                            options={ options }
                            back={ back }
                            title={ localisation.t("screens.home.notifications.title") }
                        />
                    ),
                }}
            />
        </Tab.Navigator>
    );
};

const TabBar = ({ state, descriptors, navigation }) => {
    const theme = React.useContext(ThemeContext);
    const modal = React.useContext(ModalContext);
    const media = React.useContext(MediaContext);
    const localisation = React.useContext(LocalisationContext);
    const session = React.useContext(SessionContext);
    const loadingScreen = React.useContext(LoadingScreenContext);
    const alert = React.useContext(AlertContext);

    //Methods.
    const onCreatePress = React.useCallback(() => {
        const onHide = (method) => modal.close(method);
        const onShow = (onSubmit, onCancel, onShow, onHide, state) => modal.fire({
            onRequestClose: close,
            content: (
                <CreateEventModal
                    onSubmit={ onSubmit }
                    onCancel={ onCancel }
                    onShow={ onShow }
                    onHide={ onHide }
                    state={ state }
                />
            )
        });
        const onCancel = (isEmpty, cancel) => {
            if (isEmpty) {
                modal.close();
            } else {
                modal.close(() => {
                    const reject = () => modal.close(cancel);
                    const accept = () => modal.close();
                    modal.fire({
                        onRequestClose: reject,
                        content: (
                            <ModalWrapper size="small">
                                <CardDialogue
                                    title={ localisation.t("common.areYouSure") }
                                    status="danger"
                                    buttons={[{
                                        icon: {
                                            source: "FontAwesome5",
                                            name: "times",
                                            colour: theme.colour.danger,
                                        },
                                        onPress: reject,
                                    }, {
                                        icon: {
                                            source: "FontAwesome5",
                                            name: "check",
                                            colour: theme.colour.success,
                                        },
                                        onPress: accept,
                                    }]}
                                >
                                    <BodyWrapper>
                                        <BodyText align="center">
                                            { localisation.t("screens.home.createEventModal.cancelText") }
                                        </BodyText>
                                    </BodyWrapper>
                                </CardDialogue>
                            </ModalWrapper>
                        )
                    });
                });
            }
        };
        const onSubmit = (show, title, type, latitude, longitude, address, datetime, playerAmount, skillLevel, price, isPrivate, repeat, repeatRules, description) => {
            const [ date, time ] = unixToDatetime(datetime).split(" ");
            modal.close(() => {
                loadingScreen.queue((finish) => {
                    send({
                        url: "create-event",
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: {
                            title: title,
                            descId: type,
                            latitude: latitude,
                            longitude: longitude,
                            address: address,
                            //datetime: datetime,
                            date: date,
                            time: time,
                            playerAmount: playerAmount,
                            skillLevel: skillLevel,
                            price: price,
                            isPrivate: isPrivate,
                            repeat: repeat,
                            repeatRules: repeatRules,
                            description: description || "-",
                        },
                        localisation: localisation.locale.key,
                        onSuccess: async (response) => {
                            //const result = await response.json();
                            alert.post({
                                content: (
                                    <DefAlert
                                        status="success"
                                        text={ localisation.t("screens.home.createEventSuccessText") }
                                    />
                                ),
                            });
                        },
                        onServerError: () => {
                            const close = () => modal.close(show);
                            modal.fire({
                                onRequestClose: close,
                                content: (
                                    <WrapperModal size="small">
                                        <CardServerError
                                            onClose={ close }
                                        />
                                    </WrapperModal>
                                ),
                            });
                        },
                        onUserError: async (response) => {
                            const result = await response.json();
                            const close = () => modal.close(show);
                            modal.fire({
                                onRequestClose: close,
                                content: (
                                    <WrapperModal size="small">
                                        <CardUserError
                                            title={ localisation.t("screens.home.createEventFailedTitle") }
                                            text={ localisation.t("screens.home.createEventFailedText") }
                                            errors={ result }
                                            onClose={ close }
                                        />
                                    </WrapperModal>
                                ),
                            });
                        },
                        onConnectionError: () => {
                            const close = () => modal.close(show);
                            modal.fire({
                                onRequestClose: close,
                                content: (
                                    <WrapperModal size="small">
                                        <CardServerError
                                            onClose={ close }
                                        />
                                    </WrapperModal>
                                ),
                            });
                        },
                        onFinish: () => finish(),
                    });
                });
            })
        };
        onShow(onSubmit, onCancel, onShow, onHide);
    }, []);

    //Return.
    const styles = stylesheetTabBar(theme, getCreateButtonSize(media), getTabIconSize(media));
    return (
        <View style={ styles.container }>
                {/* Map. */}
                <View style={ styles.itemContainer }>
                    <TabButton
                        icon={{
                            source: "FontAwesome5",
                            name: "map",
                        }}
                        onPress={ () => navigation.navigate("map") }
                        selected={ state.index === 0 }
                    />
                </View>

                {/* Events. */}
                <View style={ styles.itemContainer }>
                    <TabButton
                        icon={{
                            source: "FontAwesome5",
                            name: "calendar",
                        }}
                        onPress={ () => navigation.navigate("events") }
                        selected={ state.index === 1 }
                    />
                </View>

                {/* Make space for create button. */}
                <View style={ styles.itemBlocker }/>

                {/* Social. */}
                <View style={ styles.itemContainer }>
                    <TabButton
                        icon={{
                            source: "FontAwesome5",
                            name: "users",
                        }}
                        onPress={ () => navigation.navigate("social") }
                        selected={ state.index === 2 }
                    />
                </View>

                {/* Notifications. */}
                <View style={ styles.itemContainer }>
                    <TabButton
                        icon={{
                            source: "FontAwesome5",
                            name: "bell",
                        }}
                        onPress={ () => navigation.navigate("notifications") }
                        selected={ state.index === 3 }
                        count={ Math.min(session.user.notifications.filter(notification => !notification.seen).length, getCounterBubbleMaxValue()) }
                    />
                </View>

                {/* Create button. */}
                <Pressable
                    style={ styles.createButton }
                    onPress={ onCreatePress }
                >
                    <Image
                        style={ styles.createImage }
                        source={ require("@assets/images/logo/1200x1200.png") }
                    />
                </Pressable>
        </View>
    );
};

const TabButton = ({ icon, onPress, selected, count }) => {
    const theme = React.useContext(ThemeContext);
    const media = React.useContext(MediaContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Animation.
    const animations = {
        select: React.useRef(new Animated.Value(selected ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selected) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: ANIMATION_TAB_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: ANIMATION_TAB_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selected ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetTabButton(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
        >
            <Animated.Text style={ styles.text }>
                <Icon
                    source={ icon.source }
                    name={ icon.name }
                    size={ getTabIconSize(media) }
                />
            </Animated.Text>
            <View style={{ position:"absolute", bottom:"50%", left:"50%", }}>
                <CounterBubble
                    value={ count }
                    visible={ count > 0 }
                    invert={ selected }
                />
            </View>
        </Pressable>
    );
};

const getCreateButtonSize = (media) => {
    return media.break === media.breaks.mobile ? CREATE_BUTTON_SIZE_MOBILE : media.break === media.breaks.tablet ? CREATE_BUTTON_SIZE_TABLET : CREATE_BUTTON_SIZE_DESKTOP;
};
const getTabIconSize = (media) => {
    return media.break === media.breaks.mobile ? TAB_ICON_SIZE_MOBILE : media.break === media.breaks.tablet ? TAB_ICON_SIZE_TABLET : TAB_ICON_SIZE_DESKTOP;
};
const getCreateButtonOverflow = (theme, media) => {
    const buttonSize = getCreateButtonSize(media);
    const tabSize = getTabIconSize(media) + 2 * theme.spacing.medium;
    return buttonSize + theme.spacing.medium - tabSize;
};

//Exports.
export default HomeScreen;
export { getCreateButtonOverflow };
