
import { StyleSheet, Platform } from "react-native";

const stylesheet = function(theme, animations) {
    return StyleSheet.create({
        container: {
            opacity: animations.opacity,
            marginTop: animations.marginTop,
            marginLeft: theme.spacing.medium,
            marginRight: theme.spacing.medium,
            height: animations.height,
            overflow: "hidden",
        },
    });
}

//Exports.
export default stylesheet;
