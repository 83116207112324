
import { StyleSheet, Platform } from "react-native";

const stylesheet = (theme, animations, options) => {
    return StyleSheet.create({
        iconContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        offIconWrapper: {
            color: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ options.off.colours.selected.icon, options.off.colours.unselected.icon ],
            }),
        },
        onIconWrapper: {
            color: animations.toggle.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ options.on.colours.unselected.icon, options.on.colours.selected.icon ],
            }),
        },
    });
}

//Exports.
export default stylesheet;
