
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations, fade) => {

    const size = theme.fonts.text.lineHeights.medium + theme.spacing.medium;

    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing.small / 2,
        },
        label: {
            backgroundColor: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ "rgba(0, 0, 0, 0)", theme.colour.primary ],
            }),
            color: animations.select.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ theme.colour.text, theme.colour.primaryText ],
            }),
            width: size,
            borderRadius: size / 2,
            opacity: fade ? theme.opacity.disabled : 1,
            textAlign: "center",
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: size,
        },
    });
}

//Exports.
export default stylesheet;
