
import { fetchSecure, storeSecure, removeSecure } from "@helpers/device";
import netInfo from "@react-native-community/netinfo";

//Constants.
import { API_URL } from "@constants/server";

const send = async ({ url, method, headers = {}, body, authenticated = true, signal, localisation, onSuccess, onFinish, onConnectionError, onServerError, onUserError, }) => {
    //If authenticated, fetch user token.
    const token = authenticated ? await fetchSecure("accessToken") : null;
    //Check network status.
    const netStatus = await netInfo.fetch();
    if (/*!netStatus.isConnected*/false) {
        if (onConnectionError !== undefined) onConnectionError();
        if (onFinish !== undefined) onFinish();
    } else {
        //Handle request.
        try {
            //Fetch.
            const response = await fetch(`${API_URL}/${ url }`, {
                method: method,
                headers: {
                    TimezoneOffset: -(new Date()).getTimezoneOffset() / 60,
                    Localisation: localisation,
                    Authorization: token ? `Bearer ${ token }` : null,
                    ...headers,
                },
                body: body && JSON.stringify(body),
                signal: signal,
            });
            //Interpret result.
            if (response.ok) {
                if (onSuccess !== undefined) await onSuccess(response);
            } else if (response.status >= 500) {
                if (onServerError !== undefined) await onServerError(response);
            } else if (response.status >= 400) {
                if (onUserError !== undefined) await onUserError(response);
            } else {
                console.log(response);
            }
            if (onFinish !== undefined) onFinish();
        } catch(error) {
            if (__DEV__ && error.name !== "AbortError") console.error(error);
        }
    }
};

export { send }
