
//Framework.
import React from "react";

//Localisation.
import * as Localization from "expo-localization";
import i18n from "i18n-js";

//Constants.
import { FALLBACK, LOCALES, TRANSLATIONS } from "@constants/localisation";

//Create context.
const Context = React.createContext();

//Set localisation configuration.
if (__DEV__) {
    i18n.fallbacks = false;
} else {
    i18n.fallbacks = true;
}
i18n.defaultLocale = FALLBACK;
i18n.translations = TRANSLATIONS;
i18n.locale = TRANSLATIONS.hasOwnProperty(Localization.locale) ? Localization.locale : FALLBACK;

//Create provider.
const Provider = ({ children }) => {
    //States.
    const [ locale, setLocale ] = React.useState(LOCALES.find(localisation => localisation.key === FALLBACK));

    //Methods.
    const setLocaleCallback = React.useCallback((key) => {
        setLocale(LOCALES.find(localisation => localisation.key === key));
    }, []);
    const translate = React.useCallback((key, args = {}) => {
        return i18n.t(key, args);
    }, [ locale ]);
    
    //Create value.
    const value = React.useMemo(() => ({
        locale: locale,
        setLocale: setLocaleCallback,
        t: translate,
    }), [ locale ]);
    global.localisation = value;
    
    //Render.
    return (
        <Context.Provider value={value}>
            { children }
        </Context.Provider>
    );
};

//Exports.
export default Context;
export { Provider };
