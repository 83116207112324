
//Framework.
import React from "react";
import { View, Text, Pressable } from "react-native";


//Constants.
//import { EVENT_TYPES, DATABASE } from "@constants";
import EVENT_TYPES from "@constants/eventTypes";
import EVENT_MODEL from "@constants/models/event";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import SessionContext from "@contexts/session";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import CardDialogue from "@components/containers/cards/def/dialogue";

//Children.
//import EventTypesFilter from "./eventTypesFilter";
//import TimeFilter from "./timeFilter";
//import PriceFilter from "./priceFilter";

//Styling.
import stylesheet from "./stylesheets/main";

const Component = ({ onAccept, onClose }) => {
    crash();
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const session = React.useContext(SessionContext);

    //Get filters from session.
    const filters = React.useMemo(() => importFilters(session.filters), []);

    //Event types filter.
    /*
    const [ eventTypes, setEventTypes ] = React.useState(filters.eventTypes);
    const onEventTypesToggle = React.useCallback((descId) => {
        setEventTypes(eventTypes => eventTypes.includes(descId) ? eventTypes.filter(eventType => eventType !== descId) : eventTypes.concat(descId));
    }, [ eventTypes ]);

    //Weekdays filter.
    const [ weekdays, setWeekdays ] = React.useState(filters.weekdays);
    const onWeekdayToggle = React.useCallback((weekday) => {
        setWeekdays(weekdays => weekdays.includes(weekday) ? weekdays.filter(day => day !== weekday) : weekdays.concat(weekday));
    }, [ weekdays ]);

    //Date range filter.
    const [ minDate, setMinDate ] = React.useState(filters.minDate);
    const [ maxDate, setMaxDate ] = React.useState(filters.maxDate);

    //Time range filter.
    const [ minTime, setMinTime ] = React.useState(filters.minTime);
    const [ maxTime, setMaxTime ] = React.useState(filters.maxTime);
    const onMinTimeChange = React.useCallback((value) => {
        console.log({ where:"minTime change", value });
    }, []);
    const onMaxTimeChange = React.useCallback((value) => {
        console.log({ where:"maxTime change", value });
    }, []);

    //Price range filter.
    const [ minPrice, setMinPrice ] = React.useState(filters.minPrice);
    const [ maxPrice, setMaxPrice ] = React.useState(filters.maxPrice);
    const onChangeMinPrice = React.useCallback((value) => setMinPrice(value), []);
    const onChangeMaxPrice = React.useCallback((value) => setMaxPrice(value), []);

    //Methods.
    const onClosePress = React.useCallback(() => {
        const filters = exportFilters(eventTypes, weekdays, minDate, maxDate, minTime, maxTime, minPrice, maxPrice);
        onClose(filters);
    }, [ eventTypes, weekdays, minDate, maxDate, minTime, maxTime, minPrice, maxPrice ]);
    */

    //Render.
    const styles = stylesheet(theme);
    return (
        <WrapperModal size="medium">
            <CardDialogue
                title={ localisation.t("screens.home.map.filterModalTitle") }
                status="primary"
                buttons={[{
                    icon: {
                        source: "FontAwesome5",
                        name: "times",
                        colour: theme.colour.danger,
                    },
                    onPress: () => console.log("close"),
                }, {
                    icon: {
                        source: "FontAwesome5",
                        name: "check",
                        colour: theme.colour.success,
                    },
                    onPress: () => console.log("accept"),
                }]}
            >

                {/* Sport. */}
                {/*
                <View style={ styles.filterContainer }>
                    <EventTypesFilter
                        eventTypeIds={ eventTypes }
                        onToggle={ onEventTypesToggle }
                    />
                </View>
                */}

                {/* Time. */}
                {/*
                <View style={ styles.filterContainer }>
                    <TimeFilter
                        weekdays={ weekdays }
                        minDate={ minDate }
                        maxDate={ maxDate }
                        minTime={ minTime }
                        maxTime={ maxTime }
                        onWeekdayToggle={ onWeekdayToggle }
                        onMinTimeChange={ onMinTimeChange }
                        onMaxTimeChange={ onMaxTimeChange }
                    />
                </View>
                */}

                {/* Price. */}
                {/*
                <View style={ styles.filterContainer }>
                    <PriceFilter
                        minPrice={ minPrice }
                        maxPrice={ maxPrice }
                        onChangeMinPrice={ onChangeMinPrice }
                        onChangeMaxPrice={ onChangeMaxPrice }
                    />
                </View>
                */}
            </CardDialogue>
        </WrapperModal>
    );
}

const importFilters = (sessionFilters) => {
    //Set defaults.
    let eventTypes = [],
        weekdays = [],
        minDate = null,
        maxDate = null,
        minTime = null,
        maxTime = null,
        minPrice = EVENT_MODEL.price.min,
        maxPrice = EVENT_MODEL.price.max;
    //Loop through session filters.
    sessionFilters.forEach(filter => {
        switch(filter.type) {
            case("eventTypes"):
                eventTypes = filter.value;
                break;
            case("weekdays"):
                weekdays = filter.value;
                break;
            case("priceRange"):
                minPrice = filter.min;
                maxPrice = filter.max;
                break;
        }
    });
    //Return.
    return {
        eventTypes,
        weekdays,
        minDate,
        maxDate,
        minTime,
        maxTime,
        minPrice,
        maxPrice,
    };
}

const exportFilters = (eventTypes, weekdays, minDate, maxDate, minTime, maxTime, minPrice, maxPrice) => {
    let filters = [];

    //Process event types.
    if (eventTypes.length > 0) {
        filters.push({
            type: "eventTypes",
            value: eventTypes,
        });
    }
    //Process weekdays filter.
    if (weekdays.length > 0) {
        filters.push({
            type: "weekdays",
            value: weekdays,
        });
    }
    //Process price range.
    if (minPrice !== DATABASE.event.price.min || maxPrice !== DATABASE.event.price.max) {
        filters.push({
            type: "priceRange",
            min: minPrice,
            max: maxPrice,
        });
    }

    //Return.
    return filters;
}

//Exports.
export default Component;
