
//Framework.
import React from "react";
import { ScrollView, View, Text, Pressable } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import ModalWrapper from "@components/containers/wrappers/modal";
import BodyWrapper from "@components/containers/wrappers/body";
import DialogueCard from "@components/containers/cards/def/dialogue";

//Styling.
import stylesheet from "./stylesheets/sendFriendRequestModal";

const Component = ({ onSubmit, onCancel }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Methods.
    const onCancelPress = React.useCallback(onCancel, [ onCancel ]);
    const onSubmitPress = React.useCallback(() => {
        console.log("onSubmit");
    }, [ onSubmit ]);

    //Return.
    const styles = stylesheet(theme);
    return (
        <ModalWrapper size="small">
            <DialogueCard
                title="sendFriendRequest"
                status="primary"
                buttons={[{
                    icon: {
                        source: "FontAwesome5",
                        name: "times",
                        colour: theme.colour.danger,
                    },
                    onPress: onCancelPress,
                }, {
                    icon: {
                        source: "FontAwesome5",
                        name: "check",
                        colour: theme.colour.success,
                    },
                    onPress: onSubmitPress,
                }]}
            >
                <BodyWrapper>
                    <Text>
                        Body
                    </Text>
                </BodyWrapper>
            </DialogueCard>
        </ModalWrapper>
    );
}

//Exports.
export default Component;
