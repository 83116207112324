
//Framework.
import React from "react";
import { View, ScrollView } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/modal";

/*
    size: (string or number),
*/

//Constants.
const WIDTH_TINY = 300;
const WIDTH_SMALL = 450;
const WIDTH_MEDIUM = 650;
const WIDTH_LARGE = 1000;
const WIDTH_HUGE = 1600;

const Component = ({ children, size, scrollable = true, }) => {
    const theme = React.useContext(ThemeContext);

    const width = React.useMemo(() => {
        switch(size) {
            case("tiny"): return WIDTH_TINY;
            case("small"): return WIDTH_SMALL;
            case("medium"): return WIDTH_MEDIUM;
            case("large"): return WIDTH_LARGE;
            case("huge"): return WIDTH_HUGE;
            default: return size;
        }
    }, [ size ]);

    //Render.
    const styles = stylesheet(theme, width, scrollable);
    return (
        <ScrollView
            contentContainerStyle={ styles.scrollContainer }
        >
            <View style={ styles.contentContainer }>
                { children }
            </View>
        </ScrollView>
    );
}

//Exports.
export default Component;
