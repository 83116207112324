
//Framework.
import React from "react";
import { View, Pressable, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheetComponent from "./stylesheets/stars/component";
import stylesheetStar from "./stylesheets/stars/star";

//Constants.
const ANIMATION_SELECT_DURATION_MS = 300;

const Component = ({ rating:ratingIn, size:sizeIn, onPress:onPressIn, }) => {
    const theme = React.useContext(ThemeContext);

    //Render.
    const styles = stylesheetComponent(theme, sizeIn);
    return (
        <View style={ styles.container }>
            <View style={ styles.starContainer }>
                <Star
                    rating={ ratingIn }
                    size={ sizeIn }
                    value={ 1 }
                    onPress={ onPressIn }
                />
            </View>

            <View style={ styles.starContainer }>
                <Star
                    rating={ ratingIn }
                    size={ sizeIn }
                    value={ 2 }
                    onPress={ onPressIn }
                />
            </View>

            <View style={ styles.starContainer }>
                <Star
                    rating={ ratingIn }
                    size={ sizeIn }
                    value={ 3 }
                    onPress={ onPressIn }
                />
            </View>
        </View>
    );
}

const Star = ({ rating:ratingIn, size:sizeIn, value:valueIn, onPress:onPressIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialised ] = React.useState(false);

    //Methods.
    const onPress = React.useCallback(() => onPressIn(valueIn), [ valueIn, onPressIn ]);

    //Calculate fill.
    const fill = React.useMemo(() =>  ratingIn >= valueIn ? 1 : ratingIn <= valueIn - 1 ? 0 : ratingIn - valueIn + 1, [ ratingIn, valueIn ]);

    //Animation.
    const animations = {
        width: React.useRef(new Animated.Value(sizeIn * fill)).current,
        opacity: React.useRef(new Animated.Value(fill > 0 ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            //Set fill width.
            Animated.timing(animations.width, {
                toValue: sizeIn * fill,
                duration: ANIMATION_SELECT_DURATION_MS,
                useNativeDriver: true,
            }).start();
            //Set opacity.
            if (fill > 0) {
                Animated.timing(animations.opacity, {
                    toValue: 1,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.opacity, {
                    toValue: 0,
                    duration: ANIMATION_SELECT_DURATION_MS,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ ratingIn, valueIn ]);

    //Mark initilised.
    React.useEffect(() => setIsInitialised(true), []);

    //Return.
    const styles = stylesheetStar(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPress }
            disabled={ onPressIn === undefined }
        >
            <View style={ styles.backgroundContainer }>
                <Icon
                    source="AntDesign"
                    name="staro"
                    colour={ theme.colour.gold }
                    size={ sizeIn }
                />
            </View>
            <Animated.View style={ styles.foregroundContainer }>
                <Icon
                    source="AntDesign"
                    name="star"
                    colour={ theme.colour.gold }
                    size={ sizeIn }
                />
            </Animated.View>
        </Pressable>
    );
};

//Exports.
export default Component;
