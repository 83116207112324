
//Framework.
import React from "react";
import { View, Pressable, Text, Animated } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import Icon from "@components/misc/icon";

//Styling.
import stylesheet from "./stylesheets/icon";

/*
    value: (any),
    options: [{
        value: (any),
        label: (string),
        left: (component),
        words: [(string)],
    }],
*/

const Component = ({ selected:selectedIn, label:labelIn, icon:iconIn, onPress:onPressIn, }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ isInitialised, setIsInitialied ] = React.useState(false);

    //Animations.
    const animations = {
        select: React.useRef(new Animated.Value(selectedIn ? 1 : 0)).current,
    };
    React.useEffect(() => {
        if (isInitialised) {
            if (selectedIn) {
                Animated.timing(animations.select, {
                    toValue: 1,
                    duration: theme.animations.select.duration,
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(animations.select, {
                    toValue: 0,
                    duration: theme.animations.select.duration,
                    useNativeDriver: true,
                }).start();
            }
        }
    }, [ selectedIn ]);

    //Mark initialised.
    React.useEffect(() => setIsInitialied(true), []);

    //Render.
    const styles = stylesheet(theme, animations);
    return (
        <Pressable
            style={ styles.container }
            onPress={ onPressIn }
        >
            <Animated.View style={ styles.animationWrapper }>
                {/* Icon. */}
                <View style={ styles.iconContainer }>
                    <Animated.Text style={ styles.iconWrapper }>
                        <Icon
                            source={ iconIn.source }
                            name={ iconIn.name }
                            size={ theme.fonts.text.lineHeights.medium }
                        />
                    </Animated.Text>
                </View>
                {/* Label. */}
                <View style={ styles.labelContainer }>
                    <Animated.Text
                        style={ styles.label }
                        selectable={ false }
                    >
                        { labelIn }
                    </Animated.Text>
                </View>
            </Animated.View>
        </Pressable>
    );
}

const getHeight = (theme) => theme.fonts.text.lineHeights.medium + 2 * theme.spacing.medium + 2 * theme.spacing.small;

//Exports.
export default Component;
export { getHeight };
