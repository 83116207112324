
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations, isAnimating, isOpen, contentSize) => {

    const headerHeight = theme.fonts.text.lineHeights.medium + 2 * theme.spacing.medium;

    return StyleSheet.create({
        container: {
            opacity: animations.disable.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 1, theme.animations.disable.opacity ],
            }),
            borderRadius: headerHeight / 2,
            overflow: "hidden",
        },

        //Header.
        headerContainer: {
            backgroundColor: theme.colour.primary,
            height: headerHeight,
            padding: theme.spacing.medium / 2,
            flexDirection: "row",
        },
        iconContainer: {
            flexBasis: theme.spacing.medium + theme.fonts.text.lineHeights.medium,
            padding: theme.spacing.medium / 2,
        },
        titleContainer: {
            flexBasis: 0,
            flexGrow: 1,
            padding: theme.spacing.medium / 2,
        },
        title: {
            color: theme.colour.primaryText,
            fontSize: theme.fonts.text.sizes.medium,
            fontFamily: theme.fonts.text.families.bold,
            lineHeight: theme.fonts.text.lineHeights.medium,
        },
        switchContainer: {
            padding: theme.spacing.medium / 2,
        },

        //Content.
        contentContainer: {
            height: animations.open.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 0, contentSize ],
            }),
            opacity: animations.open.interpolate({
                inputRange: [ 0, 1 ],
                outputRange: [ 0, 1 ],
            }),
        },
        contentBorderWrapper: {
            backgroundColor: theme.colour.primary,
            padding: theme.border.width.medium,
            paddingTop: 0,
            borderRadius: headerHeight / 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        contentWrapper: {
            backgroundColor: theme.colour.foreground,
            borderRadius: (headerHeight - 2 * theme.border.width.medium) / 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            overflow: "hidden",
        },
    });
}

//Exports.
export default stylesheet;
