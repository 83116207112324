
//Framework.
import React from "react";
import { View } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationText from "@contexts/localisation";

//Components.
import CardDialogue from "@components/containers/cards/def/dialogue";
import TextBody from "@components/text/body";
import WrapperBody from "@components/containers/wrappers/body";

/*
    onClose: (method),
*/

const Component = ({ onClose, }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationText);

    //Render.
    return (
        <CardDialogue
            title={ localisation.t("components.containers.cards.def.connectionError.title") }
            status="danger"
            buttons={[{
                onPress: onClose,
                icon: {
                    source: "FontAwesome5",
                    name: "times",
                    colour: theme.colour.danger,
                },
            }]}
        >
            <WrapperBody>
                <TextBody align="center">
                    { localisation.t("components.containers.cards.def.connectionError.text") }
                </TextBody>
            </WrapperBody>
        </CardDialogue>
    );
}

//Exports.
export default Component;
