
//Framework.
import React from "react";
import { View, Text } from "react-native";
import Svg, { G, Circle } from "react-native-svg";

//Constants.
import EVENT_TYPES from "@constants/eventTypes";
import EVENT_MODEL from "@constants/models/event";
import EVENT_ROLE_MODEL from "@constants/models/eventRole";

//Helpers.
import { formatFullName, formatEventPrice } from "@helpers/formatting";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";

//Components.
import Icon from "@components/misc/icon";
import UserImage from "@components/images/user";
import CardFooter from "@components/containers/cards/def/footers/icons";
import Stars from "@components/misc/stars";

//Styling.
import stylesheet from "./stylesheets/minified";

//Constants.
const STAT_SIZE = 50;
const HOST_IMAGE_SIZE = 65;
const RATING_SIZE = 34;

const Component = ({ event:eventIn, buttons:buttonsIn = [] }) => {
    console.log({ eventIn, buttonsIn });
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);

    //Find event type.
    const eventType = React.useMemo(() => EVENT_TYPES.find(eventType => eventType.descId === eventIn.descId), [ eventIn ]);

    //Find host role.
    const hostRole = React.useMemo(() => eventIn.roles.find(role => role.descId === EVENT_ROLE_MODEL.descIds.administrator));

    //Calculate heights.
    const heights = React.useMemo(() => {
        //Return.
        return {
            header: theme.fonts.text.lineHeights.large + theme.fonts.text.lineHeights.small + theme.spacing.medium + theme.spacing.small,
            stat: STAT_SIZE,
            image: HOST_IMAGE_SIZE,
            rating: RATING_SIZE,
        };
    }, []);

    //Return.
    const styles = stylesheet(theme, eventIn, heights);
    return (
        <View style={ styles.container }>
            {/* Header. */}
            <View style={ styles.header }>
                <View style={ styles.iconContainer }>
                    <Icon
                        source={ eventType.icon.source }
                        name={ eventType.icon.name }
                        colour={ theme.colour.text }
                        size={ heights.header - (theme.spacing.medium + theme.spacing.small) }
                    />
                </View>
                <View style={ styles.textContainer }>
                    <Text
                        style={ styles.title }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { eventIn.title }
                    </Text>
                    <Text
                        style={ styles.timestamp }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { eventIn.executedAt }
                    </Text>
                </View>
            </View>

            {/* Host info. */}
            <View style={ styles.hostContainer }>
                <View style={ styles.hostImageContainer }>
                    <UserImage
                        user={ hostRole.user }
                        size={ HOST_IMAGE_SIZE }
                    />
                </View>
                <View style={ styles.hostTextContainer }>
                    <Text
                        style={ styles.hostName }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { hostRole.user.username }
                    </Text>
                </View>
                <View style={ styles.hostRatingContainer }>
                    <Stars
                        rating={ hostRole.user.rating }
                        size={ RATING_SIZE }
                    />
                </View>
                <Text
                    style={ styles.priceContainer }
                    selectable={ false }
                    numberOfLines={ 1 }
                >
                    { eventIn.price > 0 ? formatEventPrice(eventIn.price) : localisation.t("common.free") }
                </Text>
            </View>

            {/* Stats. */}
            <View style={ styles.statsContainer }>

                {/* Players. */}
                <View style={ styles.statContainer }>
                    <Text
                        style={ styles.statLabel }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { localisation.t("components.misc.events.minified.players") }
                    </Text>
                    <View style={ styles.playersStatContainer }>
                        <Text
                            style={ styles.statValue }
                            selectable={ false }
                            numberOfLines={ 1 }
                        >
                            { `${ eventIn.roles.length } / ${ eventIn.playerAmountMax }` }
                        </Text>
                    </View>
                </View>

                {/* Pending. */}
                <View style={ styles.statContainer }>
                    <Text
                        style={ styles.statLabel }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { localisation.t("components.misc.events.minified.pending") }
                    </Text>
                    <View style={ styles.pendingStatContainer }>
                        <Text
                            style={ styles.statValue }
                            selectable={ false }
                            numberOfLines={ 1 }
                        >
                            { eventIn.invitations.length }
                        </Text>
                    </View>
                </View>

                {/* Skill level. */}
                <View style={ styles.statContainer }>
                    <Text
                        style={ styles.statLabel }
                        selectable={ false }
                        numberOfLines={ 1 }
                    >
                        { localisation.t("components.misc.events.minified.skill") }
                    </Text>
                    <View style={ styles.skillLevelStatContainer }>
                        <Svg height={ STAT_SIZE } width={ STAT_SIZE } viewBox={ `0 0 ${ STAT_SIZE } ${ STAT_SIZE }` }>
                            <G rotation={ -90 } originX={ STAT_SIZE / 2 } originY={ STAT_SIZE / 2 }>
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={ (STAT_SIZE - theme.border.width.large) / 2 }
                                    strokeWidth={ theme.border.width.large }
                                    strokeOpacity={ 0.25 }
                                    stroke={ theme.colour.primary }
                                    fill="transparent"
                                />
                                <Circle
                                    cx="50%"
                                    cy="50%"
                                    r={ (STAT_SIZE - theme.border.width.large) / 2 }
                                    strokeWidth={ theme.border.width.large }
                                    stroke={ theme.colour.primary }
                                    fill="transparent"
                                    strokeDasharray={ 2 * Math.PI * ((STAT_SIZE - theme.border.width.large) / 2) }
                                    strokeDashoffset={ 2 * Math.PI * ((STAT_SIZE - theme.border.width.large) / 2) - (2 * Math.PI * ((STAT_SIZE - theme.border.width.large) / 2) * (eventIn.skillLevel / EVENT_MODEL.skillLevel.max)) }
                                    strokeLinecap="round"
                                />
                            </G>
                        </Svg>
                        <Text
                            style={ styles.statValue }
                            selectable={ false }
                            numberOfLines={ 1 }
                        >
                            { eventIn.skillLevel }
                        </Text>
                    </View>
                </View>

            </View>

            {/* Buttons. */}
            { buttonsIn.length > 0 && (
                <CardFooter
                    buttons={ buttonsIn }
                />
            )}
        </View>
    );
}

const getHeight = (theme) => {
};

//Exports.
export default Component;
export { getHeight };
