
//Framework.
import React from "react";

//Themes.
import themeDef from "@themes/def/main";

//Create context.
const Context = React.createContext();

//Create provider.
const Provider = ({ children }) => {
    //States.
    const [ theme, setTheme ] = React.useState(themeDef);

    //Create value.
    const value = React.useMemo(() => ({
        ...theme
    }), [ theme ]);
    global.theme = value;

    //Render.
    return (
        <Context.Provider value={ value }>
            { children }
        </Context.Provider>
    );
};

//Exports.
export default Context;
export { Provider };
