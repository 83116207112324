
import { StyleSheet } from "react-native";

const stylesheet = (theme, imageSize, buttonIconSize, submitting) => {
    return StyleSheet.create({
        container: {
            backgroundColor: "rgb(0, 0, 0)",
            flexBasis: 0,
            flexGrow: 1,
        },

        imageContainer: {
            flexBasis: 0,
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            opacity: submitting ? theme.opacity.disabled : 1,
        },
        imageWrapper: {
            width: imageSize.width,
            height: imageSize.height,
            maxWidth: "100%",
            maxHeight: "100%",
        },
        image: {
            width: "100%",
            height: "100%",
            resizeMode: "contain",
        },
        cropper: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },

        buttonsContainer: {
            flexBasis: buttonIconSize + 2 * theme.spacing.medium,
            flexDirection: "row",
            borderTopWidth: theme.border.width.medium,
            borderColor: "white",
            opacity: submitting ? theme.opacity.disabled : 1,
        },
        buttonContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        buttonSplitter: {
            backgroundColor: "white",
            flexBasis: theme.border.width.medium,
        },
    });
}

//Exports.
export default stylesheet;
