
const translations = {
    types: {
        friendRequestReceived: {
            title: "Friend request received",
            text: "%{username} has sent you a friend request.",
        },
        newFriend: {
            title: "New friend",
            text: "%{username} is now friends with you.",
        },
        eventInvitationReceived: {
            title: "Invitation received",
            text: "%{username} has invited you to join them for %{title}.",
        },
        userAcceptedYourEventInvitation: {
            title: "Event invitation accepted",
            text: "%{username} has accepted your invitation to join ${title}.",
        },
        userJoinedYourEvent: {
            title: "User joined your event",
            text: "%{username} has joined your event %{title}.",
        },
        joinedEventHasChanged: {
            title: "Event has been changed",
            text: "%{title} has been modified.",
        },
        kickedFromEvent: {
            title: "Kicked",
            text: "%{username} has kicked you from ${title}.",
        },
        joinedEventHasBeenCancelled: {
            title: "Event cancelled",
            text: "%{title} has been called off.",
        },
        chatMessageHasBeenPosted: {
            title: "New message",
            text: "%{username} has added a message to a chat.",
        },
    },
};

export default translations;
