
//Framework.
import React from "react";
import { View } from "react-native";

//Constants.
import USER_MODEL from "@constants/models/user";

//Helpers.
import validate from "@helpers/validate";

//Context.
import ThemeContext from "@contexts/theme";
import LocalisationContext from "@contexts/localisation";
import AuthenticationContext from "@contexts/authentication";

//Components.
import CardUnauthenticated from "@components/containers/cards/unauthenticated/def";
import ButtonUnauthenticated from "@components/buttons/unauthenticated";
import TextError from "@components/text/error";
import InputPhoneNumber from "@components/inputs/phoneNumber/unauthenticated";
import InputPassword from "@components/inputs/password/unauthenticated";
import WrapperModal from "@components/containers/wrappers/modal";
import CardServerError from "@components/containers/cards/def/serverError";
import CardConnectionError from "@components/containers/cards/def/connectionError";
import CardUserError from "@components/containers/cards/def/userError";

//Styling.
import stylesheet from "./stylesheet";

const Component = ({ enabled, onGoToSignUp }) => {
    const theme = React.useContext(ThemeContext);
    const localisation = React.useContext(LocalisationContext);
    const authentication = React.useContext(AuthenticationContext);

    //Validation.
    const check = React.useCallback((phoneNumberPrefix, phoneNumber, password) => {
        const validations = {
            //Prefix.
            phoneNumberPrefix: validate(phoneNumberPrefix, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.phoneNumberPrefix.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                    value: USER_MODEL.phoneNumberPrefix.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.phoneNumberPrefix.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                    value: USER_MODEL.phoneNumberPrefix.maxLength,
                }),
            }, {
                type: "isPhoneNumberPrefix",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.phoneNumberPrefix.validation"),
                }),
            }]),
            //Number.
            phoneNumber: validate(phoneNumber, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.phoneNumber.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                    value: USER_MODEL.phoneNumber.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.phoneNumber.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                    value: USER_MODEL.phoneNumber.maxLength,
                }),
            }, {
                type: "isPhoneNumber",
                message: localisation.t("validation.invalid", {
                    field: localisation.t("models.user.phoneNumber.validation"),
                }),
            }]),
            //Password.
            password: validate(password, [{
                type: "required",
                message: localisation.t("validation.required", {
                    field: localisation.t("models.user.password.validation"),
                }),
            }, {
                type: "minLength",
                value: USER_MODEL.password.minLength,
                message: localisation.t("validation.minLength", {
                    field: localisation.t("models.user.password.validation"),
                    value: USER_MODEL.password.minLength,
                }),
            }, {
                type: "maxLength",
                value: USER_MODEL.password.maxLength,
                message: localisation.t("validation.maxLength", {
                    field: localisation.t("models.user.password.validation"),
                    value: USER_MODEL.password.maxLength,
                }),
            }]),
        };
        //Return.
        return {
            isValid: validations.phoneNumberPrefix.isValid && validations.phoneNumber.isValid && validations.password.isValid,
            errors: {
                phoneNumberPrefix: validations.phoneNumberPrefix.isValid ? null : validations.phoneNumberPrefix.errors[0],
                phoneNumber: validations.phoneNumber.isValid ? null : validations.phoneNumber.errors[0],
                password: validations.password.isValid ? null : validations.password.errors[0],
            },
        };
    }, []);

    //Form.
    const [ state, dispatch ] = React.useReducer((oldState, action) => {
        let newState = { ...oldState };
        switch(action.type) {
            case("finishSubmit"): return { ...oldState, toBeSubmitted:false };
            case("submit"):
                newState.isSubmitted = true;
                //Validate.
                const { isValid, errors } = check(oldState.phoneNumberPrefix.value, oldState.phoneNumber.value, oldState.password.value);
                newState.phoneNumberPrefix.error = errors.phoneNumberPrefix;
                newState.phoneNumber.error = errors.phoneNumber;
                newState.password.error = errors.password;
                //Flag for submit.
                newState.toBeSubmitted = isValid;
                //Return.
                return newState;
            case("onChange"):
                //Assign new value.
                switch(action.input) {
                    case("phoneNumber"):
                        newState.phoneNumberPrefix.value = action.value.prefix;
                        newState.phoneNumber.value = action.value.number;
                        break;
                    case("password"):
                        newState.password.value = action.value;
                        break;
                }
                //Validate.
                if (oldState.isSubmitted) {
                    const { isValid, errors } = check(newState.phoneNumberPrefix.value, newState.phoneNumber.value, newState.password.value);
                    newState.phoneNumberPrefix.error = errors.phoneNumberPrefix;
                    newState.phoneNumber.error = errors.phoneNumber;
                    newState.password.error = errors.password;
                }
                //Return.
                return newState;
        }
    }, {
        phoneNumberPrefix: {
            value: "+45",
            error: null,
        },
        phoneNumber: {
            value: "",
            error: null,
        },
        password: {
            value: "",
            error: null,
        },
        isSubmitted: false,
        toBeSubmitted: false,
    });
    React.useEffect(() => {
        if (state.toBeSubmitted) {
            //Create error modals.
            const onClose = () => modal.close();
            const onUserError = async (response) => {
                const result = await response.json();
                modal.fire({
                    onRequestClose: onClose,
                    content: (
                        <WrapperModal size="small">
                            <CardUserError
                                title={ localisation.t("screens.unauthenticated.signInFailedTitle") }
                                text={ localisation.t("screens.unauthenticated.signInFailedText") }
                                errors={ result }
                                onClose={ onClose }
                            />
                        </WrapperModal>
                    ),
                });
            };
            const onServerError = async () => {
                modal.fire({
                    onRequestClose: onClose,
                    content: (
                        <WrapperModal size="small">
                            <CardServerError
                                onClose={ onClose }
                            />
                        </WrapperModal>
                    ),
                });
            };
            const onConnectionError = async () => {
                modal.fire({
                    onRequestClose: onClose,
                    content: (
                        <WrapperModal size="small">
                            <CardConnectionError
                                onClose={ onClose }
                            />
                        </WrapperModal>
                    ),
                });
            };
            //Attempt to sign in.
            authentication.signIn(
                state.phoneNumberPrefix.value,
                state.phoneNumber.value,
                state.password.value,
                onServerError,
                onUserError,
                onConnectionError
            );
            //Enable other submits.
            dispatch({ type:"finishSubmit" });
        }
    }, [ state.toBeSubmitted ]);

    //Methods.
    const onSubmitPress = React.useCallback(() => dispatch({ type:"submit" }), []);
    const onChangePhoneNumber = React.useCallback((value) => dispatch({ type:"onChange", input:"phoneNumber", value:{ prefix:value.prefix, number:value.number }}), []);
    const onChangePassword = React.useCallback((value) => dispatch({ type:"onChange", input:"password", value:value }), []);
    
    //Return.
    const styles = stylesheet(theme);
    return (
        <CardUnauthenticated
            title={ localisation.t("screens.unauthenticated.signInTitle") }
        >
            <View style={ styles.container }>

                {/* Inputs. */}
                <View style={ styles.padder }>
                    <InputPhoneNumber
                        label={ localisation.t("models.user.phoneNumber.label") }
                        value={{
                            prefix: state.phoneNumberPrefix.value,
                            number: state.phoneNumber.value,
                        }}
                        onChange={ onChangePhoneNumber }
                        disabled={ !enabled }
                        error={ state.phoneNumberPrefix.error || state.phoneNumber.error }
                    />
                </View>
                <View style={ styles.padder }>
                    <InputPassword
                        label={ localisation.t("models.user.password.label") }
                        value={ state.password.value }
                        onChange={ onChangePassword }
                        disabled={ !enabled }
                        error={ state.password.error }
                    />
                </View>

                {/* Buttons. */}
                <View style={ styles.padder }>
                    <ButtonUnauthenticated
                        label={ localisation.t("screens.unauthenticated.signInLabel") }
                        disabled={ !enabled }
                        backgroundColour={ theme.colour.primary }
                        textColour={ theme.colour.primaryText }
                        onPress={ onSubmitPress }
                    />
                </View>
                <View style={ styles.padder }>
                    <ButtonUnauthenticated
                        label={ localisation.t("screens.unauthenticated.signUpLabel") }
                        disabled={ !enabled }
                        backgroundColour={ theme.colour.secondary }
                        textColour={ theme.colour.secondaryText }
                        onPress={ onGoToSignUp }
                    />
                </View>
            </View>
        </CardUnauthenticated>
    );
}

//Exports.
export default Component;
