
//Framework.
import React from "react";
import { View, Image, ActivityIndicator } from "react-native";

//Constants.
import { API_URL } from "@constants/server";

//Context.
import ThemeContext from "@contexts/theme";

//Styling.
import stylesheet from "./stylesheets/user";

const SIZE_LARGE = 250;
const SIZE_SMALL = 75;

const User = ({ user:userIn, size:sizeIn, }) => {
    const theme = React.useContext(ThemeContext);

    //Check if using small or large image.
    const size = React.useMemo(() => sizeIn <= SIZE_SMALL ? "small" : "large", [ size ]);

    //States.
    const [ source, setSource ] = React.useState(`${API_URL}/get-user-image/${ userIn.id }/${ size }/${ userIn.imageUpdatedAt }`);
    const [ isLoading, setIsLoading ] = React.useState(true);

    //When user updates, fetch new image.
    React.useEffect(() => {
        setIsLoading(true);
        setSource(`${ API_URL }/get-user-image/${ userIn.id }/${ size }/${ userIn.imageUpdatedAt }`);
    }, [ userIn ])

    //Render.
    const styles = stylesheet(theme, sizeIn, isLoading);
    return (
        <View
            style={ styles.container }
        >
            <Image
                style={ styles.image }
                source={ source }
                onLoadEnd={ () => setIsLoading(false) }
            />
            { isLoading && (
                <ActivityIndicator
                    style={ styles.spinner }
                    size={ sizeIn }
                    color={ theme.colour.primary }
                />
            )}
        </View>
    );
}

//Exports.
export default User;
