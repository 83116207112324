
const event = {
    type: {
        min: 1,
        max: 30, //Max ID in the event types list below.
    },
    title: {
        maxLength: 100,
    },
    address: {
        maxLength: 100,
    },
    latitude: {
        max: 90,
        min: -90,
    },
    longitude: {
        max: 180,
        min: -180,
    },
    datetime: {
        getEarliest: () => Date.now(),
        getLatest: () => {
            const date = new Date();
            date.setDate(date.getDate() + 100);
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            date.setMilliseconds(999);
            return date.getTime();
        },
    },
    playerAmount: {
        max: 100,
        min: 1,
    },
    skillLevel: {
        max: 3,
        min: 1,
    },
    isPrivate: {
        choices: [ 0, 1 ],
    },
    price: {
        max: 1000,
        min: 0,
    },
    repeat: {
        choices: [ 0, 1 ],
    },
    repeatRules: {
    },
    description: {
        maxLength: 1000,
    },

    //Desc IDs.
    types: {
        unlisted: {
            id: 1,
            labelKey: "models.event.types.unlisted",
            icon: {
                source: "FontAwesome5",
                name: "circle",
            },
        },
        football: {
            id: 2,
            labelKey: "models.event.types.football",
            icon: {
                source: "FontAwesome5",//FontAwesome
                name: "futbol",//soccer-ball-o
            },
        },
        golf: {
            id: 3,
            labelKey: "models.event.types.golf",
            icon: {
                source: "FontAwesome5",
                name: "golf-ball",
            },
        },
        roundball: {
            id: 4,
            labelKey: "models.event.types.roundball",
            icon: {
                source: "FontAwesome5",//MaterialCommunityIcons
                name: "baseball-ball",//baseball-bat
            },
        },
        basketball: {
            id: 5,
            labelKey: "models.event.types.basketball",
            icon: {
                source: "FontAwesome5",
                name: "basketball-ball",
            },
        },
        hockey: {
            id: 6,
            labelKey: "models.event.types.hockey",
            icon: {
                source: "FontAwesome5",
                name: "hockey-puck",
            },
        },
        dogWalking: {
            id: 7,
            labelKey: "models.event.types.dogWalking",
            icon: {
                source: "FontAwesome5",
                name: "dog",
            },
        },
        walk: {
            id: 8,
            labelKey: "models.event.types.walk",
            icon: {
                source: "FontAwesome5",
                name: "hiking",//walking
            },
        },
        run: {
            id: 9,
            labelKey: "models.event.types.run",
            icon: {
                source: "FontAwesome5",
                name: "running",
            },
        },
        horseRiding: {
            id: 10,
            labelKey: "models.event.types.horseRiding",
            icon: {
                source: "FontAwesome5",//MaterialCommunityIcons
                name: "hiking",//horse-human
            },
        },
        rollerSkating: {
            id: 11,
            labelKey: "models.event.types.rollerSkating",
            icon: {
                source: "MaterialCommunityIcons",
                name: "rollerblade",
            },
        },
        swimming: {
            id: 12,
            labelKey: "models.event.types.swimming",
            icon: {
                source: "FontAwesome5",
                name: "swimmer",
            },
        },
        tableTennis: {
            id: 13,
            labelKey: "models.event.types.tableTennis",
            icon: {
                source: "FontAwesome5",
                name: "table-tennis",
            },
        },
        americanFootball: {
            id: 14,
            labelKey: "models.event.types.americanFootball",
            icon: {
                source: "FontAwesome5",
                name: "football-ball",
            },
        },
        volleyBall: {
            id: 15,
            labelKey: "models.event.types.volleyBall",
            icon: {
                source: "FontAwesome5",
                name: "volleyball-ball",
            },
        },
        tennis: {
            id: 16,
            labelKey: "models.event.types.tennis",
            icon: {
                source: "MaterialCommunityIcons",
                name: "tennis-ball",
            },
        },
        badminton: {
            id: 17,
            labelKey: "models.event.types.badminton",
            icon: {
                source: "MaterialCommunityIcons",
                name: "badminton",
            },
        },
        yoga: {
            id: 18,
            labelKey: "models.event.types.yoga",
            icon: {
                source: "MaterialCommunityIcons",
                name: "yoga",
            },
        },
        crossfit: {
            id: 19,
            labelKey: "models.event.types.crossfit",
            icon: {
                source: "MaterialCommunityIcons",
                name: "arm-flex",
            },
        },
        rowing: {
            id: 20,
            labelKey: "models.event.types.rowing",
            icon: {
                source: "MaterialIcons",
                name: "rowing",
            },
        },
        fishing: {
            id: 21,
            labelKey: "models.event.types.fishing",
            icon: {
                source: "MaterialCommunityIcons",
                name: "hook",
            },
        },
        bicycling: {
            id: 22,
            labelKey: "models.event.types.bicycling",
            icon: {
                source: "FontAwesome5",
                name: "bicycle",
            },
        },
        babyWalk: {
            id: 23,
            labelKey: "models.event.types.babyWalk",
            icon: {
                source: "MaterialIcons",
                name: "child-friendly",
            },
        },
        snowmanBuilding: {
            id: 24,
            labelKey: "models.event.types.snowmanBuilding",
            icon: {
                source: "FontAwesome5",
                name: "snowman",
            },
        },
        kiteSurfing: {
            id: 25,
            labelKey: "models.event.types.kiteSurfing",
            icon: {
                source: "MaterialCommunityIcons",
                name: "police-station",//kitesurfing
            },
        },
        kayaking: {
            id: 26,
            labelKey: "models.event.types.kayaking",
            icon: {
                source: "MaterialCommunityIcons",
                name: "kayaking",
            },
        },
        handball: {
            id: 27,
            labelKey: "models.event.types.handball",
            icon: {
                source: "MaterialCommunityIcons",
                name: "handball",
            },
        },
        chess: {
            id: 28,
            labelKey: "models.event.types.chess",
            icon: {
                source: "FontAwesome5",
                name: "chess-knight",
            },
        },
        archery: {
            id: 29,
            labelKey: "models.event.types.archery",
            icon: {
                source: "MaterialCommunityIcons",
                name: "bow-arrow",
            },
        },
        shooting: {
            id: 30,
            labelKey: "models.event.types.shooting",
            icon: {
                source: "MaterialCommunityIcons",
                name: "pistol",
            },
        },
    },
};

export default event;
