
const makeDoubleDigit = (value) => value < 10 ? `0${ value }` : value;
const unixHour = 60 * 60 * 1000;

const stringToUnix = (string) => {
    let [ date, time ] = string.split(" ");
    if (time === undefined) time = "00:00:00";
    const [ year, month, day ] = date.split("-").map(item => parseInt(item));
    const [ hour, minute, second ] = time.split(":").map(item => parseInt(item));
    return Date.UTC(year, month - 1, day, hour, minute, second === undefined ? 0 : second);
};

const getTimezoneOffset = () => {
    return -(new Date()).getTimezoneOffset() / 60;
};

const unixToDate = (unix, utc = false) => {
    const datetime = new Date(unix);
    return `${
        utc ? datetime.getUTCFullYear() : datetime.getFullYear()
    }-${
        makeDoubleDigit( (utc ? datetime.getUTCMonth() : datetime.getMonth()) + 1)
    }-${
        makeDoubleDigit( utc ? datetime.getUTCDate() : datetime.getDate())
    }`;
};

const unixToTime = (unix, utc = false) => {
    const datetime = new Date(unix);
    return `${
        makeDoubleDigit( utc ? datetime.getUTCHours() : datetime.getHours() )
    }:${
        makeDoubleDigit( utc ? datetime.getUTCMinutes() : datetime.getMinutes() )
    }`;
};

const unixToDatetime = (unix, utc = false) => {
    const datetime = new Date(unix);
    return `${
        utc ? datetime.getUTCFullYear() : datetime.getFullYear()
    }-${
        makeDoubleDigit( (utc ? datetime.getUTCMonth() : datetime.getMonth()) + 1)
    }-${
        makeDoubleDigit( utc ? datetime.getUTCDate() : datetime.getDate() )
    } ${
        makeDoubleDigit( utc ? datetime.getUTCHours() : datetime.getHours() )
    }:${
        makeDoubleDigit( utc ? datetime.getUTCMinutes() : datetime.getMinutes() )
    }`;
};

const getAge = (unix) => {
    const date = new Date(unix);
    const now = new Date();
    const yearDiff = now.getFullYear() - date.getFullYear();
    const monthDiff = now.getMonth() - date.getMonth();
    const dateDiff = now.getDate() - date.getDate();
    return yearDiff + (monthDiff < 0 ? 1 : monthDiff === 0 ? (dateDiff <= 0 ? 1 : 0) : 0);
};

export {
    stringToUnix,
    getTimezoneOffset,
    unixToDate,
    unixToTime,
    unixToDatetime,
    getAge,
}
