
import { StyleSheet } from "react-native";

const stylesheet = (theme, width, scrollable) => {
    return StyleSheet.create({
        scrollContainer: {
            width: "100%",
            minHeight: "100%",
            maxHeight: !scrollable && "100%",
            justifyContent: "center",
            alignItems: "center",
        },
        contentContainer: {
            width: width,
            maxHeight: !scrollable && "100%",
            padding: theme.spacing.large,
            maxWidth: "100%",
        },
    });
}

//Exports.
export default stylesheet;
