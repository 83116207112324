
//Framework.
import React from "react";
import { View, Pressable, Text, TextInput, FlatList, Animated, Image, ScrollView } from "react-native";

//Context.
import ThemeContext from "@contexts/theme";

//Components.
import WrapperModal from "@components/containers/wrappers/modal";
import WrapperBody from "@components/containers/wrappers/body";
import WrapperFade from "@components/containers/wrappers/fade";
import CardTemplate from "@components/containers/cards/def/template";
import CardFooterIcons, { getHeight as getFooterHeight } from "@components/containers/cards/def/footers/icons";
import SearchInput, { getHeight as getSearchInputHeight } from "@components/inputs/text/search";
import SearchResultsText, { getHeight as getSearchResultsHeight } from "@components/text/searchResults";

//Styling.
import stylesheet from "./stylesheets/template";

/*
    options: [{
    }],
*/

const Component = ({ options:optionsIn, optionSize:optionSizeIn, searchable:searchableIn = false, size:sizeIn = "small", onAccept:onAcceptIn, onCancel:onCancelIn }) => {
    const theme = React.useContext(ThemeContext);

    //States.
    const [ searchText, setSearchText ] = React.useState("");

    //Methods.
    const onSearchChange = React.useCallback((value) => setSearchText(value), []);

    //Filter options by search.
    const filteredOptions = React.useMemo(() => {
        if (!searchableIn || searchText === "") return optionsIn;
        const searchTextLower = searchText.toLowerCase();
        return optionsIn.filter(option => {
            for (let i = 0, l = option.words.length; i < l; i++) {
                if (option.words[i].toLowerCase().includes(searchTextLower)) return true;
            }
            return false;
        });
    }, [ searchableIn, searchText, optionsIn ]);

    //Calculate sizes.
    const heights = React.useMemo(() => {
        //Search input.
        const searchInput = searchableIn ? getSearchInputHeight(theme) + theme.spacing.medium : 0;
        //Options.
        const options =
            filteredOptions.length === 0 ? 0 : theme.spacing.medium + //For container.
            filteredOptions.length * optionSizeIn //For each option.
        ;
        //Search results.
        const searchResults = filteredOptions.length === optionsIn.length ? 0 : getSearchResultsHeight(theme) + theme.spacing.medium;
        //Footer.
        const footer = getFooterHeight(theme);
        //Return.
        return {
            searchInput,
            options,
            searchResults,
            footer,
            optionSize: optionSizeIn,
        };
    }, [ optionSizeIn, searchText, filteredOptions ]);

    //Render.
    const styles = stylesheet(theme, heights);
    return (
        <WrapperModal size={ sizeIn } scrollable={ false }>
            <CardTemplate>
                <View style={ styles.container }>

                    {/* Body. */}
                    <View style={ styles.bodyContainer }>
                        <WrapperBody>

                            {/* Search field. */}
                            { searchableIn && (
                                <View style={ styles.searchInputContainer }>
                                    <SearchInput
                                        value={ searchText }
                                        onChange={ onSearchChange }
                                    />
                                </View>
                            )}

                            {/* Options. */}
                            { filteredOptions.length > 0 && (
                                <View style={ styles.optionsContainer }>
                                    <WrapperFade transitionSize={ theme.spacing.medium / 2 }>
                                        <ScrollView
                                            contentContainerStyle={ styles.optionsWrapper }
                                            showsVerticalScrollIndicator={ false }
                                        >
                                            { filteredOptions.map(option => option.content) }
                                        </ScrollView>
                                    </WrapperFade>
                                </View>
                            )}

                            {/* Search results text. */}
                            { optionsIn.length !== filteredOptions.length && (
                                <View style={ styles.searchResultsContainer }>
                                    <SearchResultsText
                                        found={ filteredOptions.length }
                                        total={ optionsIn.length }
                                    />
                                </View>
                            )}

                        </WrapperBody>
                    </View>

                    {/* Footer. */}
                    <View style={ styles.footerContainer }>
                        <CardFooterIcons
                            buttons={[{
                                icon: {
                                    source: "FontAwesome5",
                                    name: "times",
                                    colour: theme.colour.danger,
                                },
                                onPress: onCancelIn,
                            }, {
                                icon: {
                                    source: "FontAwesome5",
                                    name: "check",
                                    colour: theme.colour.success,
                                },
                                onPress: onAcceptIn,
                            }]}
                        />
                    </View>

                </View>
            </CardTemplate>
        </WrapperModal>
    );
}

//Exports.
export default Component;
