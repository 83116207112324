
import { StyleSheet } from "react-native";

const stylesheet = (theme) => {
    return StyleSheet.create({
        container: {
            maxHeight: "100%",
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
            paddingLeft: theme.spacing.large,
            paddingRight: theme.spacing.large,
        },
    });
}

//Exports.
export default stylesheet;
