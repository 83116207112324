
import { StyleSheet } from "react-native";

const stylesheet = (theme, animations) => {
    return StyleSheet.create({
        container: {
            width: "100%",
            height: "100%",
        },
        backgroundContainer: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        cardsContainer: {
            left: animations.left,
            position: "absolute",
            width: "200%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
        },
        cardContainer: {
            flexBasis: 0,
            flexGrow: 1,
        },
        localisationContainer: {
            position: "absolute",
            top: 0,
            right: 0,
        },
    });
}

//Exports.
export default stylesheet;
